<div class="container">
  <div *ngIf="!firstSetup" class="mt-3" id="backArrow">
    <button routerLink=".." mat-icon-button>
      <mat-icon>keyboard_backspace</mat-icon>
    </button>
    Indietro
  </div>
  <div class="m-auto" style="max-width: 570px">
    <div class="mt-3 text-center w-100">
      <h2>
        <strong
          >Associa <br />
          Google Authenticator
        </strong>
      </h2>
    </div>

    <div class="pl-3 pr-3">
      <div class="text-left justify-content-center w-100">
        <p>1. Scarica l’app Google Authenticator sul tuo smartphone e aprila</p>
        <p>
          2. Inquadra il QR Code sottostante per associare il tuo smartphone
          oppure copia e incolla in Google Authenticator il codice sottostante.
          Poi torna su questa pagina.
        </p>
      </div>
    </div>

    <div class="qr-container mb-3">
      <img
        *ngIf="otpQRCode; else loading"
        [src]="otpQRCode"
        class="rounded mx-auto"
        alt="google-auth-qr"
      />
    </div>
    <ng-template #loading>
      <div class="d-flex w-100 p-5 align-content-center justify-content-center">
        <mat-spinner *ngIf="!otpLoadError"></mat-spinner>
        <div
          *ngIf="otpLoadError"
          class="d-flex alert alert-warning"
          role="alert"
        >
          <span class="material-icons mr-2"> error </span>
          <span style="line-height: 25px">Codice QR non trovato</span>
        </div>
        <!--  <p *ngIf="!otpLoadError">
          <span class="material-icons"> error </span>Codice QR non trovato
        </p> -->
      </div>
    </ng-template>

    <div class="d-flex w-100">
      <mat-form-field
        *ngIf="!this.mobileQuery.matches; else mobileOtpField"
        appearance="outline"
        class="mx-auto form-field-80 copy-code-form-field"
      >
        <mat-label>Codice da copiare</mat-label>
        <input matInput type="text" [value]="googleAuthCode" />
        <button
          mat-button
          matSuffix
          mat-raised-button
          color="primary"
          aria-label="Copy"
          (click)="copyCode()"
          [cdkCopyToClipboard]="googleAuthCode"
        >
          Copia
        </button>
      </mat-form-field>
      <ng-template #mobileOtpField>
        <div class="d-flex w-100 flex-column">
          <mat-form-field
            appearance="outline"
            class="mx-auto form-field-80 copy-code-form-field"
          >
            <mat-label>Codice da copiare</mat-label>
            <input matInput type="text" [value]="googleAuthCode" />
          </mat-form-field>
          <button
            mat-button
            matSuffix
            mat-raised-button
            color="primary"
            aria-label="Copy"
            class="mx-auto action-button-lg"
            (click)="copyCode()"
            [cdkCopyToClipboard]="googleAuthCode"
          >
            Copia
          </button>
        </div>
      </ng-template>
    </div>

    <div class="mt-3 mb-3 pb-2 pl-3 pr-3">
      <div class="text-left justify-content-center w-100">
        <p>
          3. Per completare la tua configurazione, scrivi qui sotto il codice di 6
          cifre generato da Google Authenticator
        </p>
      </div>
    </div>
    <form ngNativeValidate (submit)="checkGoogleAuthConfiguration()">
      <div class="d-flex w-100">
        <mat-form-field
          appearance="outline"
          class="mx-auto form-field-80 copy-code-form-field"
        >
          <mat-label>Codice generato</mat-label>
          <input
            matInput
            required
            type="text"
            name="generatedCode"
            mask="000 000"
            pattern="\d{3} \d{3}"
            placeholder="Inserisci il codice generato"
            [(ngModel)]="generatedCode"
          />
        </mat-form-field>
      </div>

      <div class="w-100 d-flex justify-content-center mb-4">
        <button
          [disabled]="!generatedCode || generatedCode === '' || testingOtp"
          type="submit"
          [class.spinner-btn]="this.testingOtp"
          class="action-button-lg"
          mat-raised-button
          color="primary"
        >
          Conferma
        </button>
      </div>
    </form>
  </div>
</div>
