import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Mongo, TENANT_NAME_PLACEHOLDER } from 'oa-lib';
import * as moment from 'moment';

const URL = `${environment.RH_BASE_URL}/${TENANT_NAME_PLACEHOLDER}`;

@Injectable({
  providedIn: 'root',
})
export class SynopticService {

  constructor(private httpClient: HttpClient) {

  }

  enableSpeech(oid: any, until: Mongo.Date, unlimited: boolean): Observable<HttpResponse<any>> {

    const endpoint = `${URL} + /speeches_televic/` + oid;

    const body = {
      status: "enabled",
      until: until,
      unlimited: unlimited,
      startedAt: new Mongo.Date(moment().valueOf())
    };

    return this.httpClient.patch(
      URL,
      body,
      {
        withCredentials: true,
        observe: 'response',
        responseType: 'json',
      }
    );

  }
  closeSpeech(oid: any): Observable<HttpResponse<any>> {

    const endpoint = `${URL} + /speeches_televic/` + oid;

    const body = {
      status: "done",
      closedAt: new Mongo.Date(moment().valueOf())
    };

    return this.httpClient.patch(
      URL,
      body,
      {
        withCredentials: true,
        observe: 'response',
        responseType: 'json',
      }
    );

  }

  public getSynoptic(): Observable<HttpResponse<any>> {
    const url = `${environment.RH_BASE_URL}/txns/meeting/synoptic`;
    return this.httpClient.get(url, {
      withCredentials: true,
      observe: 'response',
      responseType: 'json',
    });
  }
  public getSynopticLayout(): Observable<HttpResponse<any>> {
    const url = `${URL}/synoptic?filter={"_id":{"$eq":"layout"}}`;
    return this.httpClient.get(url, {
      withCredentials: true,
      observe: 'response',
      responseType: 'json',
    });
  }

  public updateSynopticLayout(layout: SynopticLayout): Observable<HttpResponse<any>> {
    const url = `${URL}/synoptic/layout`;


    return this.httpClient.patch(url, layout, {
      withCredentials: true,
      observe: 'response',
      responseType: 'json',
    });
  }
  public getLastHwUpdateSynoptic(): Observable<HttpResponse<any>> {
    const url = `${URL}/synoptic/last_hw_update`;
    return this.httpClient.get(url, {
      withCredentials: true,
      observe: 'response',
      responseType: 'json',
    });
  }

  public bookSpeechforUser(iduser: string): Observable<HttpResponse<any>> {
    const url = `${URL}/speeches/`;

    return this.httpClient.post(
      url,
      {
        iduser: iduser,
      },
      {
        withCredentials: true,
        observe: 'response',
        responseType: 'json',
      }
    );
  }
}

export interface SeatConfig {
  s_num: number;
  x: number;
  y: number;
}
export interface SynopticLayout {
  bg_url: string;
  type: string;
  seats_coord: SeatConfig[];

}
// eslint-disable-next-line no-shadow
export enum HW_MIC_STATE {
  OFF = "off",
  ON = "on",
  REQ = "req",
  UNKNOWN = "Unknown"
}
export interface SynopticUpdate {

  timestamp: string; // 2023/06/26 17:21:03
  delegates: SynopticDelegate[];
}
export interface SynopticDelegate {
  oa_username: string;
  seat_number: number;
  mic_state: HW_MIC_STATE;
}
