<div>
  <div class="text-center" matDialogTitle>Rinvia a seconda convocazione</div>
  <form ngNativeValidate (submit)="handleSubmit()">
    <mat-dialog-content class="mat-typography text-center">
      <mat-form-field appearance="outline" class="ml-3">
        <mat-label>Data e ora seduta</mat-label>
        <input
          matInput
          required
          type="text"
          [(ngModel)]="date"
          [dropSpecialCharacters]="false"
          [clearIfNotMatch]="true"
          [showMaskTyped]="true"
          mask="00/00/0000 - 00:00"
          name="date"
        />
      </mat-form-field>
      <mat-error class="mb-2" *ngIf="dateInputError"> {{ dateInputError }}</mat-error>
    </mat-dialog-content>

    <div class="row">
      <div class="col-12 col-sm-6 text-center">
        <button
          type="button"
          class="action-button-lg mx-auto"
          mat-raised-button
          color="primary"
          (click)="dialogRef.close()"
        >
          Annulla
        </button>
      </div>
      <div class="col-12 col-sm-6 mt-2 mt-sm-0 text-center">
        <button
          type="submit"
          [class.spinner-btn]="false"
          class="action-button-lg mx-auto"
          mat-raised-button
          color="primary"
        >
          Conferma
        </button>
      </div>
    </div>
  </form>
</div>
<!-- 
    <ng-template #loading>
      <div class="d-flex w-100 p-2 align-content-center justify-content-center">
        <mat-spinner diameter="40"></mat-spinner>
      </div>
      <mat-dialog-content class="w-100 m-0 text-center mat-typography">
        Chiusura spoglio in corso
      </mat-dialog-content>
    </ng-template> -->
