import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Mongo } from 'oa-lib';
import * as moment from 'moment';

@Component({
  selector: 'app-meeting-reschedule-dialog',
  templateUrl: './meeting-reschedule-dialog.component.html',
  styleUrls: ['./meeting-reschedule-dialog.component.scss'],
})
export class MeetingRescheduleDialogComponent implements OnInit {
  date: string;
  dateInputError: string | null = null;

  constructor(
    public dialogRef: MatDialogRef<MeetingRescheduleDialogComponent>
  ) {}

  ngOnInit(): void {}

  handleSubmit() {
    if (this.date) {
      if (this.isValidDate(this.date)) {
        const mongoDate = this.getMongoDate(this.date);
        this.dialogRef.close({ date: mongoDate });
      } else {
        this.dateInputError = 'La data inserita non è corretta';
      }
    }
  }

  isValidDate(dateString: string) {
    const DATE_FORMAT = 'DD/MM/YYYY - HH:mm';
    const date = moment(dateString, DATE_FORMAT);

    return date.isValid();
  }


  getMongoDate(dateString: string) {
    const DATE_FORMAT = 'DD/MM/YYYY - HH:mm';
    const date = moment(dateString, DATE_FORMAT);

    return new Mongo.Date(date.unix() * 1000);
  }
}
