<div class="text-center" matDialogTitle>Seleziona punto da avviare</div>
<form ngNativeValidate (submit)="handleSubmit()">
  <mat-dialog-content class="mat-typography text-center">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Scegli punto</mat-label>
      <mat-select (selectionChange)="onAgendaItemSelection($event)" defaultTabIndex=1>
        <mat-option [disabled]="forbiddenAgendaItem(item)" *ngFor="let item of meeting.agenda" [value]="item">
          Punto {{item.item_number}}: {{item.title}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="d-flex justify-content-center mb-4">
      <button type="button" mat-raised-button color="primary" (click)="openMeetingDetail()">LISTA PUNTI</button>
    </div>
  </mat-dialog-content>

  <div class="row">
    <div class="col-12 col-sm-6 text-center">
      <button type="button" class="action-button-lg mx-auto" mat-raised-button color="primary"
        (click)="dialogRef.close()">
        Annulla
      </button>
    </div>
    <div class="col-12 col-sm-6 mt-2 mt-sm-0 text-center">
      <button type="submit" [class.spinner-btn]="false" class="action-button-lg mx-auto" mat-raised-button
        color="primary" [disabled]="!this.selectedItem">
        Avvia Punto
      </button>
    </div>
  </div>
</form>