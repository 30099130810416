import { NgRedux } from '@angular-redux/store';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { ApplicativeErrorDialogComponent, AuthService, BallotService, ConfirmationMessageDialogComponent, IAppState, Meeting, MeetingService, Speech, SpeechStatusType, Tenant, TenantService, UserRoles } from 'oa-lib';
import { Observable, Subscription, timer } from 'rxjs';
import { HW_MIC_STATE, SynopticLayout, SynopticService, SynopticUpdate } from 'src/app/services/synoptic.service';
import BallotServiceResult = Meeting.Agenda.Ballot.BallotServiceResult;
@Component({
    selector: 'synoptic-view',
    templateUrl: './synoptic-view.component.html',
    styleUrls: ['./synoptic-view.component.scss'],
})
export class SynopticViewComponent implements OnInit, OnDestroy, OnChanges {


    @Input() mode_enabled: "both" | "debate" | "ballot" = "both";

    @Input() show_speeches = false;
    @Input() show_votes = false;

    current_mode: "debate" | "ballot" | null = null;
    synoptic_layout: SynopticLayout | null = null;

    full_meeting: Meeting | null = null;
    title = '';
    sottotitolo = '';
    fase = '';
    show = false;

    synoptic_last_update: SynopticUpdate | null = null;

    synoptic_html_data: SynopticHtmlData[] = [];
    timerIntervalSubscription: Subscription;
    meeting: Meeting.MeetingGlobalStatus | null = null;
    actual_speeches: Speech[] = [];
    interventions_running: Intervention[] = [];
    interventions_booked: Intervention[] = [];

    votes_fav: string[] = [];
    votes_con: string[] = [];
    votes_ast: string[] = [];
    actually_showing_votes = false;

    constructor(private meetingSvc: MeetingService,
        private tenantService: TenantService,
        private synopticService: SynopticService,
        private meetingService: MeetingService,
        private ballotSvc: BallotService,
    ) {

         }
    ngOnInit(): void {
        this.init();
        this.timerIntervalSubscription?.unsubscribe();
    //start after 1 second to let the layout load
    this.timerIntervalSubscription = timer(2000, 2000).subscribe(() => {

      this.refreshLoop();
     });
    // setTimeout(() => {
    //   this.updateSynoptic();
    // },1000);
    }
    ngOnDestroy(): void {
        this.timerIntervalSubscription?.unsubscribe();
    }
    ngOnChanges(changes: SimpleChanges): void {
        console.log('SynopticViewComponent ngOnChanges');
    }
    async init() {
        const res = await this.synopticService.getSynopticLayout().toPromise();
        this.synoptic_layout = res.body[0] || null;
        this.synoptic_html_data = [];
    }

    async refreshLoop() {
        try {
            const meeting_status = await this.meetingService.getMeetingStatus().toPromise();
            if (meeting_status == null || meeting_status.meeting == null) {
                console.warn('synoptic-view meeting_status is null');
                return;
            }
            console.log(meeting_status);
            this.full_meeting = await this.meetingService.retrieveMeeting(meeting_status.meeting._id.$oid).toPromise();

            if (this.full_meeting == null) {
                console.error('full_meeting is null');
            }
            this.title = meeting_status.meeting?.title;
            this.sottotitolo = meeting_status.agendaItem?.title;
            if (!this.sottotitolo) {
                this.sottotitolo = "Nessun ODG avviato";
                this.fase = "Nessun ODG avviato";
            }
            console.log(this.full_meeting);
            if (meeting_status.debate == true && (this.mode_enabled == 'debate' || this.mode_enabled == 'both')) {
                this.current_mode = 'debate';
                this.show=true;
                this.fase = "Dibattito";
                await this.updateDebateSynoptic(meeting_status);
                return;
            }
            if (meeting_status.ballot && meeting_status.debate == false && (this.mode_enabled == 'ballot' || this.mode_enabled == 'both')) {
                this.current_mode = 'ballot';
                this.fase = "Votazione";
                this.show=true;
                await this.updateBallotSynoptic(meeting_status);
                return;
            }
            this.show=false;
        }
        catch (err) {
            console.log(err);
        }

    }
    getFirstLetters(tokenize: string) {
        let firstLetters = tokenize
          .split(' ')
          .map(word => word.charAt(0))
          .join('');
        firstLetters=firstLetters+"  ";
        return firstLetters;
      }
    async updateDebateSynoptic(meeting_status: Meeting.MeetingGlobalStatus) {

        if (meeting_status == null) {
            console.error('SynopticViewComponent updateDebateSynoptic meeting_status is null');
            return;
        }

        //update synoptic last_update
        //update the speeches for the current agenda item
        //create the html data with who is speaking, who is requesting, who is seat and with empty seat
        this.synoptic_last_update = await this.getLastSynopticUpdateSituation();
        if (this.synoptic_last_update == null) {
            console.error('SynopticViewComponent updateDebateSynoptic synoptic_last_update is null');
            //this.synoptic_html_data = [];
            return;
        }
        if (this.synoptic_layout == undefined || this.synoptic_layout == null || this.synoptic_layout.seats_coord == null) {
            console.error('SynopticViewComponent updateDebateSynoptic synoptic_layout is null');
        }

        const res_speeches=await this.meetingSvc.getMeetingAgendaItemSpeeches(meeting_status.meeting._id.$oid,meeting_status.agendaItem.item_number).toPromise();
        this.actual_speeches = res_speeches.body as Speech[];
        const temp_html_data: SynopticHtmlData[] = [];
        for (const seat of this.synoptic_layout?.seats_coord || []) {

            const delegate = this.synoptic_last_update.delegates.find(d => d.seat_number == seat.s_num);
            const delegate_oa_username = delegate?.oa_username || null;
            if (delegate_oa_username == null) {
                temp_html_data.push({
                    x: seat.x,
                    y: seat.y,
                    label: ''+seat.s_num,
                    htmlclass: 'sv-empty-seat',
                    icon: 'event_seat'
                });
                continue;
            }
            temp_html_data.push({
                x: seat.x,
                y: seat.y,
                label: this.getFirstLetters(this.getNameFromUserId(delegate_oa_username)),
                htmlclass: this.getClassForDebateDelegate(delegate_oa_username),
                icon: 'person'

            });
        }
        this.synoptic_html_data = temp_html_data;

        //update list interventions for the viewver
        if (this.show_speeches) {
            this.interventions_running = [];
            this.interventions_booked=[];
            console.log(this.actual_speeches);
            for(const speech of this.actual_speeches){

                    const intervention: Intervention = {
                        name: this.getNameFromUserId(speech.user),
                        time: ''
                    };
                    if (this.isSpeechCompleted(speech)) {
                        console.log("Speech completed");
                        continue;
                    }
                    if (this.isSpeechRunning(speech)) {
                        //find the seconds elapsed on the speech and format it
                        const time_started = moment(speech.startedAt?.$date);
                        if (time_started.isValid()) {
                            const time_now = moment.now();
                            const seconds_elapsed = time_now - time_started.valueOf();
                            const duration = moment.duration(seconds_elapsed);
                            const hours = duration.hours();
                            const minutes = duration.minutes();
                            const seconds = duration.seconds();
                            intervention.time = hours + "h  :" + minutes + "m  :" + seconds+"s";

                        }

                        this.interventions_running.push(intervention);
                        continue;
                    }
                    //this should be a booked speech
                    console.log("Speech booked");
                    this.interventions_booked.push(intervention);


            }
            console.log("Done adjusting interventions");
            console.log(this.interventions_running);
            console.log(this.interventions_booked);

        }
    }
    async updateBallotSynoptic(meeting_status: Meeting.MeetingGlobalStatus) {

        if (meeting_status.ballot?.status == "CLOSED") {
            this.fase = "Votazione Conclusa";
        }
        if (meeting_status.ballot?.status == "NOTARIZED") {
            this.fase = "Votazione Convalidata";
        }
        this.synoptic_last_update = await this.getLastSynopticUpdateSituation();
        if (this.synoptic_last_update == null) {
            console.error('SynopticViewComponent updateDebateSynoptic synoptic_last_update is null');
            //this.synoptic_html_data = [];
            return;
        }
        if (this.synoptic_layout == undefined || this.synoptic_layout == null || this.synoptic_layout?.seats_coord == null) {
            console.error('SynopticViewComponent updateDebateSynoptic synoptic_layout is null');
        }
        const ballot_id = meeting_status.ballot?.id?.$oid || null;
        if (ballot_id == null) {
            console.error('SynopticViewComponent updateBallotSynoptic ballot_id is null');
            return;
        }

        const [a, b , c ] = await Promise.all([this.meetingSvc.getBallot(ballot_id).toPromise(), this.ballotSvc.getBallotResult(ballot_id).toPromise(), this.ballotSvc.getBallotResult(ballot_id, true).toPromise()]);

        //console.log('SynopticViewComponent updateBallotSynoptic a:', a)
        //console.log('SynopticViewComponent updateBallotSynoptic b:', b);
        //console.log('SynopticViewComponent updateBallotSynoptic c:', c);
        const ballot1 = b.body as BallotServiceResult;
        const ballot2 = c.body as BallotServiceResult;


        const temp_html_data: SynopticHtmlData[] = [];
        this.votes_ast = [];
        this.votes_con = [];
        this.votes_fav = [];
        for (const seat of this.synoptic_layout?.seats_coord || []) {

            const username = this.getUsernameBySeatNumber(seat.s_num);
            if (username == null) {
                temp_html_data.push({
                    x: seat.x,
                    y: seat.y,
                    label: ''+seat.s_num,
                    htmlclass: 'sv-empty-seat',
                    icon: 'event_seat'
                });
                continue;
            }
            const voted = this.hasUsernameVoted(username, ballot1) || this.hasUsernameVoted(username, ballot2);
            const inttype = ballot1?.ballot?.type || ballot2?.ballot?.type;
            const publicvote = inttype ? (inttype != 201) : true;
            let choice: "fav" | "con" | "ast" | null = null;
            if (publicvote) {
                 choice = this.getChoiceForUsername(username, ballot1) || this.getChoiceForUsername(username, ballot2);
            }
            let classNamePublicVote = '';
            if (choice == "fav") {
                classNamePublicVote = 'sv-public-vote-fav';
            }
            else if (choice == "con") {
                classNamePublicVote = 'sv-public-vote-con';
            }
            else if (choice == "ast") {
                classNamePublicVote = 'sv-public-vote-ast';
            }
            else {
                classNamePublicVote=voted ? 'sv-voted-seat' : 'sv-normal-seat';
            }
            console.log('SynopticViewComponent updateBallotSynoptic classNamePublicVote:', classNamePublicVote);
            temp_html_data.push({
                x: seat.x,
                y: seat.y,
                label: this.getFirstLetters(this.getNameFromUserId(username)),
                htmlclass: classNamePublicVote,
                icon: 'person'
            });

            if (this.show_votes) {
                //prepare the data for the votes
                if (!publicvote) {
                    this.actually_showing_votes = false;
                    continue;
                }

                if (choice == "fav") {
                    this.votes_fav.push(this.getNameFromUserId(username) || '');
                }
                else if (choice == "con") {
                    this.votes_con.push(this.getNameFromUserId(username) || '');
                }
                else if (choice == "ast") {
                    this.votes_ast.push(this.getNameFromUserId(username) || '');
                }
                else {
                    console.warn('SynopticViewComponent updateBallotSynoptic choice is null');
                }
                this.actually_showing_votes = true;
            }
        }
        this.synoptic_html_data = temp_html_data;

     }

    getChoiceForUsername(username: string, ballotresult: BallotServiceResult): "fav" | "con" | "ast"|null {
        if (ballotresult == null || ballotresult == null) {
            return null;
        }
        if (ballotresult.voters == null || ballotresult.voters.voted == null) {
            return null;
        }
        for (const voted of ballotresult.options?.true?.by || []) {
            if (voted._id == username) {
                return "fav";
            }
        }
        for (const voted of ballotresult.options?.false?.by || []) {
            if (voted._id == username) {
                return "con";
            }

        }
        for (const voted of ballotresult.options?.null?.by || []) {
            if (voted._id == username) {
                return "ast";
            }
        }

        return null;
    }
    hasUsernameVoted(username: string, ballotresult: BallotServiceResult) {
        if (ballotresult == null || ballotresult == null) {
            return false;
        }
        if (ballotresult.voters == null || ballotresult.voters.voted == null) {
            return false;
        }
        for (const voted of ballotresult.voters.voted) {
            if (voted._id == username) {
                return true;
            }
        }
        return false;
    }
    getUsernameBySeatNumber(seat_number: number) {

        if (seat_number == null) {
            return null;
        }
        if (this.synoptic_last_update == null) {
            return null;
        }
        const delegate = this.synoptic_last_update.delegates.find(d => d.seat_number == seat_number);

        return delegate?.oa_username || null;

    }
    async getLastSynopticUpdateSituation() {
        const res = await this.synopticService.getLastHwUpdateSynoptic().toPromise();
        return res.body || null;
    }


    getNameFromUserId(userId: string | undefined) {
        if (this.full_meeting && userId) {
          const name = this.full_meeting.attendees.filter(a => a._id === userId).map(a => a.name);
          return name?.length ? name[0] : '--';
        }
       return '--';
      }
    getClassForDebateDelegate(username: string | null) {

        if (username == null) {
            return "sv-empty-seat";
        }
        if (this.actual_speeches == null) {
            return "sv-empty-seat";
        }

        for (const speech of this.actual_speeches) {
            if (speech.user == username) {
                if (this.isSpeechCompleted(speech)) {
                    continue;
                }
                if (this.isSpeechRunning(speech)) {
                   return "sv-running-speech";
                }
                if (speech.status == SpeechStatusType.BOOKED) {
                    return "sv-booked-speech";
                }

            }

        }
        return "sv-normal-seat";
    }
    isSpeechRunning(speech: Speech) {
        return (
          speech.status === SpeechStatusType.ENABLED && this.untilInFuture(speech)
        );
      }
      untilInFuture(speech: Speech) {
        return speech.until?.$date
          ? moment.now() < moment(speech.until?.$date).valueOf()
          : false;
      }
      isSpeechCompleted(speech: Speech) {
        return speech.status === SpeechStatusType.ENABLED && this.untilInPast(speech);
      }
      untilInPast(speech: Speech) {
        return speech.until?.$date ?
          moment.now() > moment(speech.until?.$date).valueOf()
          : false;
      }
}

export interface SynopticHtmlData {

    x: number;
    y: number;
    label: string;
    htmlclass: string;
    icon: string;
}
export interface Intervention {
    name: string;
    time: string;
}
