import {
  UserRoles,
  AuthState,
  TenantState,
  TenantType,
  Meeting,
  IAppState,
  MeetingService
} from 'oa-lib';

import { NgRedux, select } from '@angular-redux/store';
import { Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import * as momentJS from 'moment';
const moment = (momentJS as any).default ? (momentJS as any).default : momentJS;

@Component({
  selector: 'app-meeting-calendar',
  templateUrl: './meeting-calendar.component.html',
  styleUrls: ['./meeting-calendar.component.scss'],
})
export class MeetingCalendarComponent implements OnInit {
  /** Based on the screen size, switch from standard to one column per row */
  @select('tenant') tenant: Observable<TenantState>;
  @select('auth') auth: Observable<AuthState>;
  meetings: Observable<Meeting[]>;
  currentMonthLabel = '';
  previousMonthLabel = '';
  nextMonthLabel = '';

  previuosYear = '';
  nextYear = '';

  currentDate: any;

  TenantType = TenantType;
  UserRoles = UserRoles;

  constructor(
    public meetingSvc: MeetingService,
    private router: Router,
    private redux: NgRedux<IAppState>
  ) { }

  ngOnInit() {
    const month = moment().format('M');
    const year = moment().format('YYYY');
    this.currentDate = moment(
      `01/${month.toString()}/${year.toString()} 00:00`,
      'D/M/YYYY H:mm'
    ).valueOf();
    this.setCalendar(this.currentDate);
  }

  getPreviousMontDate() {
    this.currentDate = moment(this.currentDate).subtract(1, 'M').valueOf();
    this.setCalendar(this.currentDate);
  }

  getNextMonthDate() {
    this.currentDate = moment(this.currentDate).add(1, 'M').valueOf();
    this.setCalendar(this.currentDate);
  }

  setCalendar(date: any) {
    this.tenant.subscribe((tenant) => {
      const startDate = this.currentDate;
      const endDate = moment(this.currentDate).add(1, 'M').valueOf();
      this.meetings = this.meetingSvc
        .getMeetingsInDateRange(startDate, endDate, tenant.type === 0)
        .pipe(map((res) => (res.body ? res.body : [])))
        .pipe(
          map((data) => {
            // eslint-disable-next-line max-len
            if (this.redux.getState().tenant.type === 0) {
              data.sort((a, b) =>
                (a.secondCall == null &&
                  b.secondCall == null &&
                  a.firstCall.startDate.$date > b.firstCall.startDate.$date) ||
                  (a.secondCall != null &&
                    b.secondCall == null &&
                    a.secondCall?.startDate.$date >
                    b.firstCall.startDate.$date) ||
                  (a.secondCall == null &&
                    b.secondCall != null &&
                    a.firstCall?.startDate.$date >
                    b.secondCall.startDate.$date) ||
                  (a.secondCall != null &&
                    b.secondCall != null &&
                    a.secondCall?.startDate.$date > b.secondCall.startDate.$date)
                  ? -1
                  : 1
              );
              return data;
            } else {
              data.sort((a, b) =>
                a.startDate != null &&
                  b.startDate != null &&
                  a.startDate.$date > b.startDate.$date
                  ? -1
                  : 1
              );
              return data;
            }
          })
        );

      this.meetings.pipe(take(1)).subscribe();

      this.currentMonthLabel = this.getCurrentMonthLabel(date);
      this.previousMonthLabel = this.getPreviuosMonthLabel(date);
      this.nextMonthLabel = this.getNextMonthLabel(date);
    });
  }

  getCurrentMonthLabel(currentDate: Date) {
    const date = new Date(currentDate);
    return date.toLocaleString('it-IT', { month: 'long' });
  }

  getPreviuosMonthLabel(currentDate: Date) {
    const date = new Date(currentDate);
    date.setMonth(date.getMonth() - 1);
    this.previuosYear = date.getFullYear().toString();
    return date.toLocaleString('it-IT', { month: 'long' });
  }

  getNextMonthLabel(currentDate: Date) {
    const date = new Date(currentDate);
    date.setMonth(date.getMonth() + 1);
    this.nextYear = date.getFullYear().toString();
    return date.toLocaleString('it-IT', { month: 'long' });
  }

  goToMeetingEditor() {
    this.router.navigate(['meeting', 'editor', 'new']);
  }
}
