<div class="text-center">
    <h3 style="margin: 0;"><strong>Riepilogo scelte effettuate</strong></h3>
    <h5>Cliccando CONFERMA, non potrai più modificare le tue scelte.</h5>
</div>

<div class="container">
    <div class="row">
        <div class="col-10">
            <div class="mb-3" *ngFor="let selection of selections">
                <app-ballot-selection-detail [selection]="selection"></app-ballot-selection-detail>
            </div>
        </div>
        <div class="col-2">
            <mat-icon class="how-to-vote-icon">how_to_vote</mat-icon>
        </div>
    </div>
</div>