<div class="d-flex flex-column container justify-content-center mt-3 w-100" style="max-width: 1140px">
<ng-container *ngIf="layout; else faillayout">

    <div class="synoptic-container">
      <img class="synoptic-bg-image" [src]="layout?.bg_url">
      <div *ngFor="let s of layout.seats_coord" class="synoptic-delegate" [ngStyle]="{'margin-left.px':s.x,'margin-top.px':s.y}">
        <span class="synoptic-delegate-label">{{s.s_num}}</span>
        <button class="button-delegate">
            <mat-icon>person</mat-icon>
           </button>
        </div>
    </div>

</ng-container>


<!-- <mat-selection-list (selectionChange)="checkSelection($event, spec, selectionList)"
                                #selectionList>
                                <mat-list-option #option [ngClass]="{'option-selected': option.selected}"
                                    class="mt-3 mb-3" [value]="candidate" checkboxPosition="before"
                                    *ngFor="let candidate of spec.choices">
                                    <div class="annuality">{{candidate?.from ? 'Iscritto dal ' + candidate.from : ''}}
                                    </div>
                                    <div [ngClass]="{'white-card': candidate == null}">{{candidate !== null ?
                                        candidate?.fullName : 'Scheda bianca'}}</div>
                                    <div class="desc">{{candidate?.desc ? candidate.desc: ''}}</div>
                                </mat-list-option>
                            </mat-selection-list> -->
<form *ngIf="layout" [formGroup]="form_layout">

  <mat-form-field>
    <mat-label>Seat to adjust</mat-label>
    <mat-select formControlName="seat_selected">
      <mat-option *ngFor="let s of layout.seats_coord" [value]="s.s_num">
        {{s.s_num}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-raised-button color="primary" (click)="change_coord_seat('x',-1)">
    X-1
  </button>
  <button mat-raised-button color="primary" (click)="change_coord_seat('x',-10)">
    X-10
  </button>
  <button mat-raised-button color="primary" (click)="change_coord_seat('x',-100)">
    X-100
  </button>
  <button mat-raised-button color="primary" (click)="change_coord_seat('x',1)">
    X+1
  </button>
  <button mat-raised-button color="primary" (click)="change_coord_seat('x',10)">
    X+10
  </button>
  <button mat-raised-button color="primary" (click)="change_coord_seat('x',100)">
    X+100
  </button>

  <button mat-raised-button color="primary" (click)="change_coord_seat('y',-1)">
    Y-1
  </button>
  <button mat-raised-button color="primary" (click)="change_coord_seat('y',-10)">
    Y-10
  </button>
  <button mat-raised-button color="primary" (click)="change_coord_seat('y',-100)">
    Y-100
  </button>
  <button mat-raised-button color="primary" (click)="change_coord_seat('y',1)">
    Y+1
  </button>
  <button mat-raised-button color="primary" (click)="change_coord_seat('y',10)">
    Y+10
  </button>
  <button mat-raised-button color="primary" (click)="change_coord_seat('y',100)">
    Y+100
  </button>
  <hr/>
  <button mat-raised-button color="primary" (click)="save_layout()">
    Update layout
  </button>
  <hr/>
  <mat-form-field appearance="outline" class="w-10">
    <mat-label>Seat number to add/remove</mat-label>
    <input matInput type="text" formControlName="seat_to_add">
</mat-form-field>
  <button mat-raised-button color="primary" (click)="addSeat()" mat-icon-button>
    <mat-icon>plus_one</mat-icon>
  </button>
  <button mat-raised-button color="primary" (click)="removeSeat()" mat-icon-button>
    <mat-icon>exposure_minus_1</mat-icon>
  </button>
<hr/>

</form>





<ng-template #faillayout>
    <div class="d-flex w-100 p-5 align-content-center justify-content-center">
      <h2>Could not load synoptic configuration. Is the synoptic configured yet?</h2>
    </div>
  </ng-template>

</div>