import { Meeting } from 'oa-lib';
import { MatDialog } from '@angular/material/dialog';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import Item = Meeting.Agenda.Item;
@Component({
  selector: 'app-agenda-item',
  templateUrl: './agenda-item.component.html',
  styleUrls: ['./agenda-item.component.scss']
})
export class AgendaItemComponent implements OnInit {
  @Input() item: Item;
  @Input() secondCall: boolean;
  @Output() editItem = new EventEmitter<Item>();
  @Output() deleteItem = new EventEmitter<Item>();
  ItemStatus = Meeting.Agenda.Item.STATUS;

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  getItemNumberLabel(): string {
    return this.item.item_number;
  }

  editMeetingAgendaItem() {
    this.editItem.emit(this.item);
  }

  deleteMeetingAgendaItem() {
    this.deleteItem.emit(this.item);
  }

  forbiddenAgendaItem(): boolean {
    if (!this.secondCall) {
      return this.item.status && this.item.status !== Meeting.Agenda.Item.STATUS.SCHEDULED;
    } else {
      return this.item.status && (
        this.item.status !== Meeting.Agenda.Item.STATUS.SCHEDULED &&
        this.item.status !== Meeting.Agenda.Item.STATUS.NO_QUORUM &&
        this.item.status !== Meeting.Agenda.Item.STATUS.NOT_VOTED
      );
    }
  }

}
