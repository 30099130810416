/* eslint-disable no-underscore-dangle */
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { select } from '@angular-redux/store';
import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  Injector,
} from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { AuthState, AuthActions, AuthenticatedUserDirective } from 'oa-lib';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent
  extends AuthenticatedUserDirective
  implements OnDestroy
{
  @select('rehydrated') rehydrated: Observable<boolean>;
  @select('auth') auth: Observable<AuthState>;

  mobileQuery: MediaQueryList;
  printQuery: MediaQueryList;

  currentRoute: string;

  private _mobileQueryListener: () => void;

  constructor(
    private media: MediaMatcher,
    protected inj: Injector,
    protected authActions: AuthActions,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(inj);
    this.mobileQuery = this.media.matchMedia('(max-width: 770px)');
    this.printQuery = this.media.matchMedia('print');

    this._mobileQueryListener = () => this.changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);

    this.router.events.subscribe((event) => {
      this.currentRoute = this.router.url;
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
  }

  logout(): void {
    this.authActions.signout();
    this.router.navigate(['signin']);
  }

  canAccessUserProfile(): boolean {
    return (
      this.router.url.indexOf('signin') === -1 &&
      this.router.url.indexOf('profile') === -1
    );
  }

  isVerifying(): boolean {
    return this.router.url.indexOf('verify') > -1;
  }

  isIntoUserProfile(): boolean {
    return this.router.url.indexOf('profile') > -1;
  }

  navigateToUserProfile() {
    this.router.navigate(['profile']);
  }

  navigateToCalendar() {
    this.router.navigate(['']);
  }

  navigateToCards() {
    console.log('navigateToCards');
    this.router.navigate(['card-manager']);
  }
  navigateToCurrentMeeting() {
    this.router.navigate(['meeting', 'current']);
  }
  navigateToSynopticManager() {
    this.router.navigate(['synoptic-manager']);
  }

  navigateToManagement() {
    this.router.navigate(['meeting', 'management']);
  }
  navigateToViewer() {
    this.router.navigate(['synoptic-viewer']);
  }
}
