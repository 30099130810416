import { Meeting } from 'oa-lib';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ballot-selection-detail',
  templateUrl: './ballot-selection-detail.component.html',
  styleUrls: ['./ballot-selection-detail.component.scss']
})
export class BallotSelectionDetailComponent implements OnInit {

  @Input() selection: {
    spec: Meeting.Agenda.Ballot.ElectionSpecification;
    selection: (number | null)[] | null;
  };

  constructor() { }

  getSelectionsFullnames(selection: {
    spec: Meeting.Agenda.Ballot.ElectionSpecification;
    selection: (number | null)[] | null;
  }): string[] {

    const fullNames: string[] = [];

    if (!selection.selection || selection.selection.length === 0) {
      fullNames.push('Non è stata espressa una preferenza.');
      return fullNames;
    }

    selection.selection.forEach(elem => {
      if (elem || elem === 0) {
        fullNames.push(selection.spec.choices[elem].fullName);
      } else {
        fullNames.push('Scheda bianca');
      }
    });

    return fullNames;
  }

  ngOnInit(): void {

  }

}
