import { UserRoles } from 'oa-lib';
import { AuthState } from 'oa-lib';
import { Observable } from 'rxjs';
import { TenantState, TenantType } from 'oa-lib';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState } from 'oa-lib';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  @select('tenant') tenant: Observable<TenantState>;
  @select('auth') auth: Observable<AuthState>;

  UserRoles = UserRoles;
  dataFormGroup: FormGroup;

  TenantType = TenantType;
  constructor(
    private redux: NgRedux<IAppState>,
    private router: Router,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {

    const userState = this.redux.getState().auth;

    if (!userState.authenticated || !userState.userid) {
      console.error('User is not authenticated');
      this.router.navigate(['signin']);
      return;
    }

    this.dataFormGroup = this.formBuilder.group(
      {
        tenantName: userState.tenant,
        email: userState.email,
        fullName: userState.name,
        title: userState.title,
        username: userState.userid,
        phone: userState.phone,
      }
    );

    this.dataFormGroup.disable();
  }

  navigateToGoogleAuthSetup(): void {
    this.router.navigate(['profile', 'google-auth']);
  }

}
