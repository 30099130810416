<div>
  <div *ngIf="tenant | async as _tenant" class="d-flex justify-content-center w-100 mt-3 mb-3">
    <span *ngIf="auth | async as _auth">
      <button *ngIf="(_tenant.type === TenantType.FULL || _tenant.type === TenantType.DCERNO ) && _auth.roles?.includes(UserRoles.MANAGER); else title"
        (click)="goToMeetingEditor()" class="mt-3 mb-3 pl-4 pr-4" color="primary" mat-raised-button>
        + NUOVO
      </button>
      <ng-template #title>
        <h2 class="m-2">Calendario</h2>
      </ng-template>
    </span>
  </div>
  <div class="container calendar">
    <div class="row">
      <div (click)="getPreviousMontDate()" class="col month">
        <mat-icon class="navigate">navigate_before</mat-icon>
        <span class="side">
          {{ previousMonthLabel }}
          {{ previuosYear }}</span>
      </div>
      <div class="col month central">{{ currentMonthLabel }}</div>
      <div (click)="getNextMonthDate()" class="col month">
        <span class="side">
          {{ nextMonthLabel }}
          {{ nextYear }}</span>
        <mat-icon class="navigate">navigate_next</mat-icon>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div *ngIf="meetings | async as _meetings; else loading" class="col-12">
        <app-meeting *ngFor="let meeting of _meetings" [meeting]="meeting"></app-meeting>
      </div>

      <div *ngIf="(meetings | async)?.length === 0"
        class="d-flex w-100 text-center p-5 align-content-center justify-content-center">
        Non sono presenti sedute in questo mese.
      </div>
    </div>

    <ng-template #loading>
      <div class="d-flex w-100 p-5 align-content-center justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
    </ng-template>
  </div>
</div>