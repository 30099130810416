<div class="mobile-menu-container menu">
  <mat-toolbar class="mb-0" color="primary">
    <div class="w-100" *ngIf="rehydrated | async as _rehydrated">
      <div *ngIf="auth | async as _auth">
        <div *ngIf="_auth.authenticated; else noAuthHeader" [ngClass]="{
            'justify-content-between': !isVerifying,
            'justify-content-end': isVerifying
          }" class="w-100 d-flex align-content-center">
          <div class="d-flex align-self-center">
            <span style="min-width: 40px">
              <button *ngIf="!isVerifying" mat-icon-button (click)="snav.toggle()">
                <mat-icon>menu</mat-icon>
              </button>
            </span>
          </div>
          <div class="p-1 mx-auto text-center">
            <img src="/assets/img/logo-openassembly-white.png" width="150px" alt="oa-logo" />
          </div>
          <button color="secondary" mat-flat-button (click)="this.logoutEvent.emit()">
            ESCI
          </button>
        </div>
        <ng-template #noAuthHeader>
          <div class="w-100 d-flex align-content-center">
            <div class="p-1 mx-auto text-center">
              <img src="/assets/img/logo-openassembly-white.png" width="150px" alt="oa-logo" />
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </mat-toolbar>

  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #snav mode="over" fixedTopGap="56" class="primary-bg">
      <mat-nav-list id="item-list" *ngIf="tenant | async as _tenant">
        <mat-list-item [class.current-navigation-item]="isCurrentMeetingRoute()"
          *ngIf="(isVoter||isGuest || isSecretary) && (_tenant.type === TenantType.FULL || _tenant.type === TenantType.DCERNO )"
          (click)="this.navigateToCurrentMeetingEvent.emit(); snav.close()">
          <span class="full-width label">Seduta in corso</span>
          <mat-icon mat-list-icon>refresh</mat-icon>
        </mat-list-item>

        <mat-list-item [class.current-navigation-item]="isManagementRoute()"
          *ngIf="isManager && ( _tenant.type === TenantType.FULL || _tenant.type === TenantType.DCERNO )"
          (click)="this.navigateToManagementEvent.emit(); snav.close()">
          <span class="full-width label">Gestisci seduta</span>
          <mat-icon mat-list-icon>tune</mat-icon>
        </mat-list-item>

        <mat-list-item [class.current-navigation-item]="isCalendarRoute()"
          (click)="this.navigateToCalendarEvent.emit(); snav.close()">
          <span class="full-width label">Calendario</span>
          <mat-icon mat-list-icon>event</mat-icon>
        </mat-list-item>

        <mat-list-item [class.current-navigation-item]="isProfileRoute()"
          (click)="this.navigateToUserProfileEvent.emit(); snav.close()">
          <span class="full-width label">Profilo</span>
          <mat-icon mat-list-icon>portrait</mat-icon>
        </mat-list-item>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <ng-content></ng-content>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>