/* eslint-disable no-underscore-dangle */
import { BallotService, MeetingActions, TenantService } from 'oa-lib';
import { Router } from '@angular/router';
import { Meeting } from 'oa-lib';
import {
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
} from '@angular/core';
import { NgRedux } from '@angular-redux/store';

import * as momentJS from 'moment';

const moment = (momentJS as any).default ? (momentJS as any).default : momentJS;

import BallotStatusType = Meeting.Agenda.Ballot.BallotStatusType;
import { IAppState } from 'oa-lib';
import { BadgesService } from 'src/app/services/badge.service';

@Component({
  selector: 'app-meeting',
  templateUrl: './meeting.component.html',
  styleUrls: ['./meeting.component.scss'],
})
export class MeetingComponent implements OnInit {
  @Input() meeting: Meeting;
  attendanceVerified = false;
  checked = false;
  tenantType: any;
  tenant: any = null;
  tenant_cocon=false;
  constructor(
    private router: Router,
    private meetingActions: MeetingActions,
    private ballotSvc: BallotService,
    private redux: NgRedux<IAppState>,
    private tenantService: TenantService,

  ) {}

  ngOnInit(): void {

    this.init();
    this.tenantType = this.redux.getState().tenant.type;
    if (this.redux.getState().tenant.type === 1) {
      if (this.meeting.agenda[0].ballot != null) {
        this.ballotSvc
          .checkAttendance(this.meeting.agenda[0].ballot)
          .subscribe((res) => {
            if (res.body && res.body[0]) {
              this.attendanceVerified = res.body[0].attendances > 0;
            }
            this.checked = true;
          });
      } else {
        throw Error(
          'Error checking ballot status: ' + JSON.stringify(this.meeting)
        );
      }
    } else {
      this.checked = true;
    }
  }

  async init() {
    const res = await this.tenantService.getTenant().toPromise();
    this.tenant = res.body;
    console.log('tenant: ', this.tenant);
    this.tenant_cocon=this.tenant?.cocon_enabled || false;

  }
  isMeetingOpen(meeting: Meeting): boolean {
    /* const start = meeting.startDate;
    const end = meeting.endDate;

    if (!(end && end.$date && start && start.$date)) {
      return false;
    }
    const now = new Date().getTime();
    return start.$date < now && end.$date > now; */

    if (this.redux.getState().tenant.type === 1) {
      if (
        meeting.agenda[0].ballot != null &&
        meeting.agenda[0].ballot.status != null
      ) {
        const ballot = meeting.agenda[0].ballot;
        return ballot.status === BallotStatusType.OPEN;
      } else {
        throw Error(
          'Error checking ballot status : ' + JSON.stringify(meeting.agenda[0])
        );
      }
    } else {
      return (
        meeting.status === Meeting.Status.OPEN ||
        meeting.status === Meeting.Status.RUNNING
      );
    }
  }

  isScheduled(meeting: Meeting): boolean {
    if (this.redux.getState().tenant.type === 1) {
      if (meeting.agenda[0].ballot != null) {
        const ballot = meeting.agenda[0].ballot;
        return (
          ballot.status === BallotStatusType.SCHEDULED && !this.submittedVote()
        );
      } else {
        throw Error(
          'Error checking ballot status :' + JSON.stringify(meeting.agenda[0])
        );
      }
    } else {
      return (
        meeting.status === Meeting.Status.SCHEDULED ||
        (meeting.status === Meeting.Status.CLOSED &&
          meeting.firstCall.startDate.$date.valueOf() > new Date().valueOf())
      );
    }
  }

  isSuspended(meeting: Meeting): boolean {
    return meeting?.status === Meeting.Status.SUSPENDED;
  }

  isClosed(meeting: Meeting): boolean {
    if (this.redux.getState().tenant.type === 1) {
      if (meeting.agenda[0].ballot != null) {
        const ballot = meeting.agenda[0].ballot;
        return (
          meeting != null &&
          meeting.endDate != null &&
          meeting.endDate.$date.valueOf() < new Date().valueOf() &&
          ballot.status !== BallotStatusType.OPEN
        );
      } else {
        throw Error(
          'Error checking ballot status :' + JSON.stringify(meeting.agenda[0])
        );
      }
    } else {
      return (
        meeting?.status === Meeting.Status.CLOSED ||
        meeting?.status === Meeting.Status.DONE
      );
    }
  }
  /*
  isPending(meeting: Meeting): boolean {
    if (this.redux.getState().tenant.type === 1) {
      if (meeting.agenda[0].ballot != null) {
        const ballot = meeting.agenda[0].ballot;
        return (
          ballot.status === BallotStatusType.SCHEDULED && !this.submittedVote()
        );
      } else {
        throw Error(
          'Error checking ballot status :' + JSON.stringify(meeting.agenda[0])
        );
      }
    } else {
      return (
        meeting.status === Meeting.Status.CLOSED ||
        meeting.firstCall.startDate.$date.valueOf() > new Date().valueOf()
      );
    }
  }
  */

  getMeetingIcon(meeting: Meeting) {
    if (this.redux.getState().tenant.type === 1) {
      if (this.submittedVote()) {
        return 'done_all';
      }
      if (
        meeting.agenda[0].ballot != null &&
        meeting.agenda[0].ballot.status != null
      ) {
        const status = meeting.agenda[0].ballot.status;
        switch (status) {
          case BallotStatusType.OPEN:
            return 'lock_open';
          case BallotStatusType.CLOSED:
            return 'lock';
          case BallotStatusType.NOTARIZED:
            return 'lock';
          case BallotStatusType.SCHEDULED:
            return 'lock';
          case BallotStatusType.SUSPENDED:
            return 'schedule';
          default:
            return 'lock';
        }
      } else {
        throw Error(
          'Error checking ballot status :' + JSON.stringify(meeting.agenda[0])
        );
      }
    } else {
      const status = meeting.status;
      switch (status) {
        case Meeting.Status.SCHEDULED:
          return 'schedule';
        case Meeting.Status.RUNNING: {
          return 'refresh';
        }
        case Meeting.Status.DONE: {
          return 'done_all';
        }
        case Meeting.Status.SUSPENDED:
          return 'pause';
        default:
          return 'help';
      }
    }
  }

  submittedVote(): boolean {
    return this.attendanceVerified;
  }

  goToMeetingDetail(meeting: Meeting) {
    this.meetingActions.setSelectedMeeting(meeting);
    this.router.navigate(['meeting', meeting._id?.$oid]);
  }
  gotoBadgeEvents(meeting: Meeting) {
    this.router.navigate(['meeting', meeting._id?.$oid, 'attendance']);
  }
  timestampToLongDate(period: string) {
    moment.locale('it');
    const timestamp = moment(period).unix();
    const date = moment(timestamp).format(`LLLL`);
    return date === 'Invalid date' ? null : date;
  }
}
