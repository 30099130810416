<div *ngIf="synoptic_layout && mode_enabled!='ballot'" class="w-100 top-header">

  <div class="w-100 p-1 align-content-center justify-content-center">
      <!-- <h2>Meeting : {{title}}</h2> -->

      <h2>Odg : {{sottotitolo}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Fase : {{fase}}</h2>
      <!-- <h3>Fase : {{fase}}</h3> -->
      </div>
</div>

<div *ngIf="synoptic_layout && show" >

      <div class="synoptic-container mt-0">
        <img class="synoptic-bg-image" [src]="synoptic_layout?.bg_url">
        <div *ngFor="let s of synoptic_html_data" class="synoptic-delegate-show" [ngStyle]="{'margin-left.px':s.x,'margin-top.px':s.y}">
          <!-- <span [class]="s.htmlclass" > {{s.label}}&nbsp; &nbsp; </span> -->
          <span [class]="s.htmlclass" >
              <!-- <mat-icon>{{s.icon}}</mat-icon> -->
          </span>
          </div>
      </div>

      <ng-container *ngIf="current_mode=='debate' && show_speeches">
        <div class="d-flex flex-row w-100 p-1">

          <div class="w-50 align-content-center justify-content-center text-center">

            <ul class="w-100 list-panel-people">
              <li style="font-size: 30px;margin-bottom:10px;"><strong>Interventi in corso</strong></li>

              <ng-container *ngIf="interventions_running?.length>0 else nointervents;">
                <li *ngFor="let it of interventions_running">
                  <mat-icon class="ml-2 mr-2" color='accent'>
                    record_voice_over
                  </mat-icon>
                  {{it.name}}&nbsp;&nbsp;&nbsp;&nbsp;{{it.time}}
                </li>

              </ng-container>
            </ul>
          </div>
          <div class="w-50 align-content-center justify-content-center text-center">
            <ul class="w-100 list-panel-people">
              <li style="font-size: 30px;margin-bottom:10px;"><strong>Interventi prenotati</strong></li>
              <ng-container *ngIf="interventions_booked?.length>0 else nointervents;">
                <li *ngFor="let it of interventions_booked">
                  <mat-icon class="ml-2 mr-2" color='primary'>
                    voice_over_off
                  </mat-icon>
                  {{it.name}}&nbsp;&nbsp;&nbsp;&nbsp;{{it.time}}


                </li>

              </ng-container>
            </ul>
          </div>

        <ng-template #nointervents>
          <h4 class="text-center">Nessun intervento.</h4>
        </ng-template>
        </div>


      </ng-container>

<!-- Show vote section-->
<ng-container *ngIf="current_mode=='ballot' && actually_showing_votes">
  <div class="d-flex flex-row w-100 p-1">

    <div class="w-33 align-content-center justify-content-center text-center">

      <ul class="w-100 list-panel-people">
        <li style="font-size: 30px;margin-bottom:10px;"><strong>Voti favorevoli</strong></li>

        <ng-container *ngIf="votes_fav?.length>0 else novotes;">
          <li *ngFor="let it of votes_fav">
           {{it}}
          </li>

        </ng-container>
      </ul>
    </div>
    <div class="w-33 align-content-center justify-content-center text-center">
      <ul class="w-100 list-panel-people">
        <li style="font-size: 30px;margin-bottom:10px;"><strong>Voti contrari</strong></li>
        <ng-container *ngIf="votes_con?.length>0 else novotes;">
          <li *ngFor="let it of votes_con">
            {{it}}
          </li>

        </ng-container>
      </ul>
    </div>
    <div class="w-33 align-content-center justify-content-center text-center">
      <ul class="w-100 list-panel-people">
        <li style="font-size: 30px;margin-bottom:10px;"><strong>Astenuti</strong></li>
        <ng-container *ngIf="votes_ast?.length>0 else novotes;">
          <li *ngFor="let it of votes_ast">
            {{it}}
          </li>

        </ng-container>
      </ul>
    </div>
  <ng-template #novotes>
    <h4 class="text-center">-</h4>
  </ng-template>
  </div>


</ng-container>



    </div>
    <!-- <ng-template #faillayout>
      <div class="d-flex w-100 p-5 align-content-center justify-content-center">
        <h2>Nessun ODG in corso.</h2>
      </div>
    </ng-template> -->