<div class="col-12 offset-md-2 col-md-8 mt-4">

    <h1>Assegna scheda NFC</h1>
    <h2>Lettore NFC: &nbsp;{{nfc_reader? "Correttamente presente.":"Impossibile rilevare lettore NFC"}}</h2>

    <div class="parent-flex">

        <div class="flex-section">
            <form class="w-100" [formGroup]="formcard">
                <div class="row">
                    <mat-form-field appearance="outline">
                        <mat-label>Utente del tenant</mat-label>

                        <mat-select (selectionChange)="selectChangeUser($event)">
                            <mat-option *ngFor="let user of users_tenant" [value]="user?._id">
                                {{user.name | titlecase}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                </form>
        </div>
        <div class="flex-section">
            <p>Informazioni autenticazione:</p>
            <div *ngIf="selectedUser">
                <p><b>_id:</b> {{selectedUser?._id}}</p>
                <p><b>Tipo autenticazione:</b> {{selectedUser?.pwdAuth?.type=='nfccard'?"NFC Card":"Altro tipo, ATTENZIONE."}}</p>
                <p><b>Nfc card number:</b>{{selectedUser?.pwdAuth?.card_id}}</p>
                <p><b>Assegnata il:</b>{{selectedUser?.pwdAuth?.card_set_timestamp}}</p>
            </div>
            </div>

    </div>

    <button mat-raised-button color="primary" [disabled]="!selectedUser"
    style="white-space: pre-wrap !important" (click)="prepareCard()">
    Prepara scheda per utente selezionato
  </button>
  <p *ngIf="card_status!='no_card'"><b>Status Scrittura</b></p>
  <p *ngIf="card_status=='payload_ready'"> <mat-spinner></mat-spinner>Payload pronto per scrittura, avvicitare la carta al lettore</p>
</div>
