<mat-card>
  <mat-card-content>
    <div class="d-flex align-items-center">
      <div class="w-100">
        <div style="color: grey">Punto {{ getItemNumberLabel() }}</div>
        <div style="font-size: large">{{ item.title }}</div>
      </div>
      <button
        [disabled]="forbiddenAgendaItem()"
        (click)="editMeetingAgendaItem()"
        mat-icon-button
      >
        <mat-icon
          [color]="forbiddenAgendaItem() ? null : 'primary'"
          matTooltip="Modifica"
        >
          edit</mat-icon
        >
      </button>
      <button
        [disabled]="forbiddenAgendaItem()"
        (click)="deleteMeetingAgendaItem()"
        mat-icon-button
      >
        <mat-icon
          [color]="forbiddenAgendaItem() ? null : 'primary'"
          matTooltip="Elimina"
        >
          delete</mat-icon
        >
      </button>
    </div>
  </mat-card-content>
</mat-card>
