<div class="mt-3 text-center w-100">
  <h3><strong>Profilo</strong></h3>
</div>

<div class="pl-3 pr-3">
  <form *ngIf="dataFormGroup" autocomplete="off" [formGroup]="dataFormGroup">
    <div class="row">
      <div class="offset-1 col-10 offset-md-3 col-md-6 offset-lg-4 col-lg-4">
        <mat-form-field appearance="outline" class="w-100 m-0">
          <mat-label>Ente</mat-label>
          <input matInput formControlName="tenantName" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100 m-0">
          <mat-label>Nome e Cognome</mat-label>
          <input matInput formControlName="fullName" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100 m-0 p-0">
          <mat-label>Titolo</mat-label>
          <input matInput formControlName="title" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100 m-0 p-0">
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100 m-0 p-0">
          <mat-label>Username</mat-label>
          <input matInput formControlName="username" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100 m-0 p-0">
          <mat-label>Telefono</mat-label>
          <input matInput formControlName="phone" />
        </mat-form-field>
      </div>
      <div *ngIf="tenant | async as _tenant" class="w-100 d-flex justify-content-center mb-3">
        <div *ngIf="auth | async as _auth" class="w-100 d-flex justify-content-center mb-3">
          <button *ngIf="_tenant.requiresGoogleAuthenticator && 
          _auth.roles?.includes(UserRoles.VOTER)" (click)=" navigateToGoogleAuthSetup()" mat-raised-button
            color="primary">
            ASSOCIA GOOGLE AUTHENTICATOR
          </button>
        </div>
      </div>
      <div class="text-center justify-content-center w-100">
        <p>
          Per richiedere una modifica dei dati contatta il numero +39 3291240166 oppure manda una mail a
          <a href="mailto:assistenza@openassembly.eu">assistenza@openassembly.eu</a>
        </p>
      </div>
    </div>
  </form>
</div>