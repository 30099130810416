<div *ngIf="tenant | async as _tenant">
  <div class="text-center" matDialogTitle>Apri seduta</div>
  <form ngNativeValidate (submit)="handleSubmit()">
    <mat-dialog-content *ngIf="scheduledMeetings | async as meetings; else loading" class="mat-typography text-center">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Titolo seduta</mat-label>
        <mat-select (selectionChange)="onMeetingSelection($event)" defaultTabIndex=1>
          <mat-option [disabled]="forbiddenMeeting(_tenant, meeting)" *ngFor="let meeting of meetings" [value]="meeting">
            {{meeting.title}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-dialog-content>

    <div class="row">
      <div class="col-12 col-sm-6 text-center">
        <button type="button" class="action-button-lg mx-auto" mat-raised-button color="primary"
          (click)="dialogRef.close()">
          Annulla
        </button>
      </div>
      <div class="col-12 col-sm-6 mt-2 mt-sm-0 text-center">
        <button type="submit" [class.spinner-btn]="false" class="action-button-lg mx-auto" mat-raised-button
          color="primary" [disabled]="!this.selectedMeeting">
          Conferma
        </button>
      </div>
    </div>
  </form>
</div>

<ng-template #loading>
  <mat-dialog-content class="mat-typography text-center">
    <div class="d-flex w-100 p-5 align-content-center justify-content-center">
      <mat-spinner></mat-spinner>
    </div>
  </mat-dialog-content>
</ng-template>