<div *ngIf="ballot | async as _ballot">

    <app-timeslot-timer [timeslot]="getCurrentTimeslot(_ballot)"></app-timeslot-timer>
    <mat-horizontal-stepper labelPosition="bottom" #horizontalStepper>

        <mat-step *ngFor="let spec of _ballot.specs;" [editable]="true">

            <ng-template matStepLabel>{{spec.title}}</ng-template>

            <div>
                <div class="container">

                    <div class="text-center w-100 mb-3">
                        <h3><strong>Elezione candidati {{spec.title | uppercase}}</strong></h3>
                    </div>

                    <div *ngIf="descriptionCollapsed; else fullDescription">
                        {{spec.description | slice:0:DESCRIPTION_LENGTH}}{{spec.description && spec.description.length >
                        DESCRIPTION_LENGTH ? '...' : '' }}
                    </div>

                    <ng-template #fullDescription>
                        <div>
                            {{spec.description}}
                        </div>
                    </ng-template>

                    <div *ngIf="spec.description && spec.description.length > DESCRIPTION_LENGTH"
                        class="d-flex justify-content-center">
                        <button (click)="this.descriptionCollapsed = !this.descriptionCollapsed" mat-icon-button>
                            <mat-icon>{{this.descriptionCollapsed ? 'expand_more' : 'expand_less'}}</mat-icon>
                        </button>
                    </div>

                    <div class="row">

                        <div class="col-12 offset-md-2 col-md-8">
                            <mat-selection-list (selectionChange)="checkSelection($event, spec, selectionList)"
                                #selectionList>
                                <mat-list-option #option [ngClass]="{'option-selected': option.selected}"
                                    class="mt-3 mb-3" [value]="candidate" checkboxPosition="before"
                                    *ngFor="let candidate of spec.choices">
                                    <div class="annuality">{{candidate?.from ? 'Iscritto dal ' + candidate.from : ''}}
                                    </div>
                                    <div [ngClass]="{'white-card': candidate == null}">{{candidate !== null ?
                                        candidate?.fullName : 'Scheda bianca'}}</div>
                                    <div class="desc">{{candidate?.desc ? candidate.desc: ''}}</div>
                                </mat-list-option>
                            </mat-selection-list>
                        </div>

                        <div class="d-flex w-100 justify-content-center mt-3">
                            <div>
                                <button *ngIf="horizontalStepper.selectedIndex > 0" matStepperPrevious
                                    class="pl-3 pr-3 mr-1 action-button" mat-raised-button
                                    color="primary">INDIETRO</button>
                                <button mat-raised-button
                                    [disabled]="selectionList.selectedOptions.selected.length < spec.min || selectionList.selectedOptions.selected.length > spec.max"
                                    (click)="next(horizontalStepper.selectedIndex, selectionList.selectedOptions, spec)"
                                    class="pl-3 pr-3 ml-1 action-button" color="primary">AVANTI</button>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </mat-step>

        <mat-step>

            <ng-template matStepLabel>Riepilogo</ng-template>
            <app-ballot-summary *ngIf="horizontalStepper.selectedIndex == _ballot.specs.length"
                [selections]="selections"></app-ballot-summary>
            <div class="d-flex w-100 justify-content-center mt-5">
                <button *ngIf="horizontalStepper.selectedIndex > 0" matStepperPrevious
                    class="pl-3 pr-3 mr-1 action-button" mat-raised-button color="primary">INDIETRO</button>
                <button (click)="confirmData()" class="pl-3 pr-3 ml-1 action-button" mat-raised-button
                    color="primary">CONFERMA</button>
            </div>

        </mat-step>

        <mat-step>
            <ng-template matStepLabel>Invio voto</ng-template>
            <div *ngIf="auth | async as _auth">

                <div class="text-center">
                    <h3>Abbiamo inviato un codice via SMS al numero {{_auth.phone}}, Inseriscilo qui sotto per votare
                    </h3>
                </div>

                <div class="d-flex justify-content-center">
                    <mat-form-field appearance="outline" class="w-75 mt-2">
                        <mat-label>Codice SMS</mat-label>
                        <input matInput [(ngModel)]="otp" required>
                    </mat-form-field>
                </div>

                <div class="d-flex mb-3 justify-content-center">
                    <button [disabled]="otp.length < 6" (click)="submitVote()" class="pl-3 pr-3 ml-1 action-button"
                        mat-raised-button color="primary">VOTA</button>
                </div>

                <div>

                </div>

                <div class="text-center">
                    <h4>Non hai ricevuto l'otp?</h4>
                    <h4>
                        <div *ngIf="canRequestOtp(); else waitForOtp">
                            Clicca <u (click)="askForOtp()">qui</u> per ricevere un nuovo codice
                        </div>
                        <ng-template #waitForOtp>
                            <div [innerHtml]="counterText"></div>
                        </ng-template>
                    </h4>
                    <h5 class="m-0">Se continui a non ricevere l'OTP contatta l'assistenza chiamando il numero +39 3291240166
                        oppure inviando una mail ad</h5>
                    <div>
                        <a href="mailto:assistenza@openassembly.eu">assistenza@openassembly.eu</a>
                    </div>
                </div>

            </div>

        </mat-step>

    </mat-horizontal-stepper>

</div>