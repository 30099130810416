import { Meeting, MeetingService, TenantService } from 'oa-lib';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { BadgesService } from 'src/app/services/badge.service';

@Component({
  selector: 'meeting-attendance',
  templateUrl: './meeting-attendance.component.html',
  styleUrls: ['./meeting-attendance.component.scss'],
})
export class MeetingAttendanceComponent implements OnInit {
  full_meeting: any = null;
  meetingId: string | null = null;

  badgeEvents: BadgeEvent[] = [];

  showErrorInit = false;

  title = 'Meeting';

  moment_inizio = moment();
  label_inizio = 'Inizio';
  moment_fine = moment();
  label_fine = 'Fine';

  duration_incluso_sospensioni = moment.duration(0);
  label_duration_incluso_sospensioni = 'Durata totale';
  duration_effettiva = moment.duration(0);
  label_duration_effettiva = 'Durata effettiva';
  duration_sospensioni = moment.duration(0);
  label_duration_sospensioni = 'Durata sospensioni';

  tableData: any[] = [];
  constructor(
    private meetingSvc: MeetingService,
    private tenantService: TenantService,
    private meetingService: MeetingService,
    private route: ActivatedRoute,
    protected router: Router,
    private badgeService: BadgesService
  ) {}

  ngOnInit(): void {
    this.meetingId = this.route.snapshot.params.idMeeting;
    if (!this.meetingId) {
      this.router.navigate(['']);
    }
    this.init();
  }
  async init() {
    this.tableData = [];
    try {
      if (
        this.meetingId == null ||
        this.meetingId == undefined ||
        this.meetingId == ''
      ) {
        console.error('meeting_oid is null, cant refresh');
        return;
      }
      if (this.meetingId == 'test') {
        this.full_meeting = this.getMockedMeeting();
      } else {
        this.full_meeting = await this.meetingService
          .retrieveMeeting(this.meetingId)
          .toPromise();
      }
      if (this.full_meeting == null) {
        console.error('full_meeting is null');
        this.router.navigate(['']);
      }
      console.log('full_meeting', this.full_meeting);

      if (this.meetingId == 'test') {
        const mocked_response = this.getMockedBadgeEventsResponse();
        this.badgeEvents = mocked_response.badge_events;
      } else {
        const res = await this.badgeService
          .getAllBadgeEventsForMeetingId(this.meetingId)
          .toPromise();
        //console.log('res getAllBadgeEventsForMeetingId', res);
        this.badgeEvents = res.body?.badge_events || [];
      }

      console.log('badgeEvents', this.badgeEvents);

      //start aggregating data
      this.title = this.full_meeting?.title || 'Meeting';
      this.moment_inizio =
        this.getMomentFirstOccuranceOfMeetingStatus(UpdateStatus.RUNNING) ||
        moment();
      this.label_inizio = this.getStringFromMomentForUI(this.moment_inizio);
      const fine = this.getMomentLastOccuranceOfMeetingStatus(
        UpdateStatus.DONE
      );
      if (fine == null) {
        console.error(
          'fine is null, not possibile to find last occurance of DONE'
        );
        throw new Error(
          'fine is null, not possibile to find last occurance of DONE'
        );
      }
      this.moment_fine = fine;

      this.label_fine = this.getStringFromMomentForUI(this.moment_fine);
      this.duration_incluso_sospensioni = moment.duration(
        this.moment_fine.diff(this.moment_inizio)
      );
      this.label_duration_incluso_sospensioni =
        this.getStringRapresentationOfDuration(
          this.duration_incluso_sospensioni
        );

      this.duration_sospensioni = this.getTotalSuspendedDuration();
      this.label_duration_sospensioni = this.getStringRapresentationOfDuration(
        this.duration_sospensioni
      );
      this.duration_effettiva = moment
        .duration(this.duration_incluso_sospensioni)
        .subtract(this.duration_sospensioni);
      this.label_duration_effettiva = this.getStringRapresentationOfDuration(
        this.duration_effettiva
      );

      this.calculateTableData();
    } catch (e) {
      console.error('error in init', e);
      this.showErrorInit = true;
    }
  }

  calculateTableData() {
    //we need to cycle all attendees and foreach take the name, the ordered list of badges event, then
    //calculate the total duration of the partecipation to the meeting given the badge event and the duration_effettiva
    //both in duration and in percentage and populate an html data for easy display
    const attendees = this.full_meeting?.attendees;
    if (!attendees) {
      console.error('calculateTableData attendees is null');
      return;
    }
    if (this.badgeEvents == null || this.badgeEvents.length == 0) {
      console.error('calculateTableData badgeEvents is null or empty');
      return;
    }
    const start_of_meeing_string = this.getStringFromMoment(this.moment_inizio);
    const end_of_meeing_string = this.getStringFromMoment(this.moment_fine);
    const meeting_status_updates = this.full_meeting?.status_update;
    if (!meeting_status_updates) {
      console.error('calculateTableData meeting_status_updates is null');
      return;
    }
    const tableData = [];
    for (const attendee of attendees) {
      const data_this_attendee: any = {};
      data_this_attendee.name = attendee.name;
      data_this_attendee.id = attendee._id;

      const ordered_badge_events_for_user =
        this.getOrderedBadgeEventsForAttendeeCleaned(
          this.badgeEvents,
          attendee._id,
          start_of_meeing_string,
          end_of_meeing_string
        );
      const duration_partecipation = this.getEffectiveDurationPartecipationUserV2(
        ordered_badge_events_for_user,
        meeting_status_updates
      );
      data_this_attendee.labelduration = this.getStringRapresentationOfDuration(
        duration_partecipation
      );
      const seconds_total_effective_duration = this.duration_effettiva.asSeconds();
      const seconds_user_effective_duration = duration_partecipation.asSeconds();
      const partecipation_percentage =(seconds_user_effective_duration * 100) / seconds_total_effective_duration;
      data_this_attendee.labelpercentage = partecipation_percentage.toFixed(2) + '%';
      data_this_attendee.list_badge_events = ordered_badge_events_for_user;
    tableData.push(data_this_attendee);
    }

    //sort by name
    tableData.sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    });
    this.tableData = tableData;

  }

  gotoDelegate(data: any) {
    console.log('gotoDelegate', data);
    //meeting/attendance/delegate/:inputBadgeData
    const inputBadgeData = JSON.stringify(data);
    this.router.navigate(['meeting','attendance','delegate', inputBadgeData]);
  }
  getEffectiveDurationPartecipationUserV2(ordered_badge_events_for_user: BadgeEvent[],meeting_status_updates: any[]): moment.Duration {
    const timeline_of_event_for_delegate: EventOnMeetingForCadidate[] = [];
    const duration_partecipation = moment.duration(0);

    //rountine check
    if (
      ordered_badge_events_for_user == null ||
      ordered_badge_events_for_user.length == 0
    ) {
      console.log(
        'getEffectiveDurationPartecipationUser no ordered_badge_events_for_user'
      );
      return duration_partecipation;
    }



    //first add all badge events to the timeline
    for (let i = 0; i < ordered_badge_events_for_user.length; i++) {
      const this_badge_event = ordered_badge_events_for_user[i];
      const type_event = this_badge_event.inserted ? TypeEventCandidate.BADGE_ENTER : TypeEventCandidate.BADGE_LEAVE;
      const this_event_for_cadidate: EventOnMeetingForCadidate = {
        timestamp: this_badge_event.timestamp,
        type_event: type_event
      };
      if (this_event_for_cadidate.timestamp == null) {
        console.error('this_event_for_cadidate.timestamp is null. this_badge_event->', this_badge_event);
      }
      timeline_of_event_for_delegate.push(this_event_for_cadidate);
    }
    //now add all meeting status updates to the timeline
    for (let i = 0; i < meeting_status_updates.length; i++) {
      const this_meeting_status_update = meeting_status_updates[i];

      let type_event_to_use: TypeEventCandidate | null = null;
      let timestamp_to_use: string | null = null;
      if (this_meeting_status_update[UpdateStatus.RUNNING]) {
        type_event_to_use = TypeEventCandidate.MEETING_RUNNING;
        timestamp_to_use = this_meeting_status_update[UpdateStatus.RUNNING];

      } else if (this_meeting_status_update[UpdateStatus.SUSPENDED]) {
        type_event_to_use = TypeEventCandidate.MEETING_SUSPENDED;
        timestamp_to_use = this_meeting_status_update[UpdateStatus.SUSPENDED];
      }
      else if (this_meeting_status_update[UpdateStatus.DONE]) {
        type_event_to_use = TypeEventCandidate.MEETING_DONE;
        timestamp_to_use = this_meeting_status_update[UpdateStatus.DONE];
      }
      else {
        console.error('this_meeting_status_update is not valid', this_meeting_status_update);
        return duration_partecipation;
      }
      if (timestamp_to_use == null) {
        console.error('timestamp_to_use is null. this_meeting_status_update->', this_meeting_status_update);
        return duration_partecipation;
      }
      const this_event_for_cadidate: EventOnMeetingForCadidate = {
        timestamp: timestamp_to_use,
        type_event: type_event_to_use
      };
      if (this_event_for_cadidate.timestamp == null) {
        console.error('this_event_for_cadidate.timestamp is null. this_meeting_status_update->', this_meeting_status_update);
      }
      timeline_of_event_for_delegate.push(this_event_for_cadidate);
    }
    //sort the timeline
    timeline_of_event_for_delegate.sort((a, b) => {
      return a.timestamp > b.timestamp ? 1 : -1;
    });
    console.log('timeline_of_event_for_delegate', timeline_of_event_for_delegate);
    // now we have a timeline of events, we need to calculate the effective duration of the partecipation for the user
 // Calculate effective duration
    let meetingRunning = false;
    let delegateInMeeting = false;

    let start_date = null;
    let end_date = null;

 for (let i = 0; i < timeline_of_event_for_delegate.length - 1; i++) {
   const currentEvent = timeline_of_event_for_delegate[i];
   switch (currentEvent.type_event) {
      case TypeEventCandidate.MEETING_RUNNING:
       meetingRunning = true;
       if (delegateInMeeting) {
         start_date = this.getMomentFromString(currentEvent.timestamp);
       }
        break;
      case TypeEventCandidate.MEETING_SUSPENDED:
       meetingRunning = false;
       if (delegateInMeeting) {
         end_date = this.getMomentFromString(currentEvent.timestamp);
       }
        break;
      case TypeEventCandidate.MEETING_DONE:
       meetingRunning = false;
       if (delegateInMeeting) {
         end_date = this.getMomentFromString(currentEvent.timestamp);
       }
        break;
      case TypeEventCandidate.BADGE_ENTER:
       delegateInMeeting = true;
       if (meetingRunning) {
         start_date = this.getMomentFromString(currentEvent.timestamp);
       }

        break;
      case TypeEventCandidate.BADGE_LEAVE:
       delegateInMeeting = false;
       if (meetingRunning) {
         end_date = this.getMomentFromString(currentEvent.timestamp);
       }
       break;
     default:
        console.error('currentEvent.type_event is not valid', currentEvent.type_event);
       return duration_partecipation;

   }
    if (start_date != null && end_date != null) {
      const duration_partecipation_tmp = moment.duration(end_date.diff(start_date));
      duration_partecipation.add(duration_partecipation_tmp);
      start_date = null;
      end_date = null;
    }



 }

 return duration_partecipation;




    }

  // getGoodEndDateForCountingPresence(date_event_badge: moment.Moment) {
  //   const state_meerting = this.getMeetingStatusForDate(date_event_badge);
  //   if (state_meerting == UpdateStatus.RUNNING) {
  //     return date_event_badge;
  //   } else if (state_meerting == UpdateStatus.SUSPENDED) {
  //     const last_suspended_date = this.getLastSuspendedDate(date_event_badge);
  //     if (last_suspended_date == null) {
  //       console.error(
  //         'getGoodEndDateForCountingPresence SUSPENDEDlast_suspended_date is bad'
  //       );
  //       return null;
  //     }
  //     return last_suspended_date;
  //   } else if (state_meerting == UpdateStatus.DONE) {
  //     const last_running_date = this.getMomentLastOccuranceOfMeetingStatus(
  //       UpdateStatus.DONE
  //     );
  //     if (last_running_date == null) {
  //       console.error(
  //         'getGoodEndDateForCountingPresence DONE last_running_date is bad'
  //       );
  //       return null;
  //     }
  //     return last_running_date;
  //   }
  //   return null;
  // }

  // getGoodStartDateForCountingPresence(date_event_badge: moment.Moment) {
  //   const state_meerting = this.getMeetingStatusForDate(date_event_badge);
  //   if (state_meerting == UpdateStatus.RUNNING) {
  //     return date_event_badge;
  //   } else if (state_meerting == UpdateStatus.SUSPENDED) {
  //     const next_running_date =
  //       this.getNextRunningDateAfterDate(date_event_badge);
  //     if (next_running_date == null) {
  //       console.error(
  //         'getGoodStartDateForCountingPresence next_running_date is null'
  //       );
  //       return null;
  //     }
  //     return next_running_date;
  //   }
  //   return null;
  // }
  // getDurationPartecipation(ordered_badge_events_for_user: BadgeEvent[]) {
  //   const duration_partecipation = moment.duration(0);
  //   if (
  //     ordered_badge_events_for_user == null ||
  //     ordered_badge_events_for_user.length == 0
  //   ) {
  //     console.log('getDurationPartecipation no ordered_badge_events_for_user');
  //     return duration_partecipation;
  //   }
  //   for (let i = 0; i < ordered_badge_events_for_user.length; i++) {
  //     const badge_event = ordered_badge_events_for_user[i];
  //     if (badge_event.inserted == false) {
  //       continue;
  //     }
  //     const badge_event_start = this.getMomentFromString(badge_event.timestamp);
  //     if (badge_event_start == null) {
  //       console.error('badge_event_start is null');
  //       continue;
  //     }
  //     let badge_event_end = moment();
  //     if (i < ordered_badge_events_for_user.length - 1) {
  //       const next_badge_event = ordered_badge_events_for_user[i + 1];
  //       if (next_badge_event.inserted == true) {
  //         console.error(
  //           'getDurationPartecipation next_badge_event is inserted, this is not possible. The array of badge_events is not well formed'
  //         );
  //         console.error(
  //           'ordered_badge_events_for_user',
  //           ordered_badge_events_for_user
  //         );
  //         continue;
  //       }
  //       const next_valid_date = next_badge_event.timestamp;
  //       if (next_valid_date) {
  //         const pp = this.getMomentFromString(next_valid_date);
  //         if (pp == null) {
  //           console.error('pp is null');
  //           continue;
  //         }
  //         badge_event_end = pp;
  //         const duration_partecipation_tmp = moment.duration(
  //           badge_event_end.diff(badge_event_start)
  //         );
  //         duration_partecipation.add(duration_partecipation_tmp);
  //       }
  //     }
  //   }
  //   return duration_partecipation;
  // }

  getOrderedBadgeEventsForAttendeeCleaned(
    badges_events: BadgeEvent[],
    attendee_id: string,
    start_of_meeing_string: string,
    end_of_meeing_string: string
  ) {
    const badges_events_for_attendee = badges_events.filter((badge_event) => {
      return badge_event.user == attendee_id;
    });
    if (
      badges_events_for_attendee == null ||
      badges_events_for_attendee.length == 0
    ) {
      console.log('no badges_events_for_attendee for attendee_id', attendee_id);
      return [];
    }
    badges_events_for_attendee.sort((a, b) => {
      return a.timestamp > b.timestamp ? 1 : -1;
    });
    //if the first event is not inserted, we add with the start of the meeting
    if (badges_events_for_attendee[0].inserted == false) {
      const first_event = {
        inserted: true,
        meeting_id: badges_events_for_attendee[0].meeting_id,
        timestamp: start_of_meeing_string,
        user: attendee_id,
      };
      badges_events_for_attendee.unshift(first_event);
    }
    //if the last event is inserted, we add with the end of the meeting
    if (
      badges_events_for_attendee[badges_events_for_attendee.length - 1]
        .inserted == true
    ) {
      const last_event = {
        inserted: false,
        meeting_id:
          badges_events_for_attendee[badges_events_for_attendee.length - 1]
            .meeting_id,
        timestamp: end_of_meeing_string,
        user: attendee_id,
      };
      badges_events_for_attendee.push(last_event);
    }
    //if the badge_events have double sequential inserted, we remove the second
    let index_to_remove_list: number[] = [];
    for (let i = 0; i < badges_events_for_attendee.length; i++) {
      const badge_event = badges_events_for_attendee[i];
      if (badge_event.inserted == false) {
        continue;
      }
      if (i == badges_events_for_attendee.length - 1) {
        continue;
      }
      const next_badge_event = badges_events_for_attendee[i + 1];
      if (next_badge_event.inserted == false) {
        continue;
      }
      index_to_remove_list.push(i + 1);
    }
    if (index_to_remove_list.length > 0) {
      console.log(
        'We actually need to remove some double sequential inserted badge_events, index_to_remove_list',
        index_to_remove_list
      );
      index_to_remove_list = index_to_remove_list.reverse();
      for (const index_to_remove of index_to_remove_list) {
        badges_events_for_attendee.splice(index_to_remove, 1);
      }
    }
    //if badge_events have double sequential not inserted, we remove the first
    index_to_remove_list = [];
    for (let i = 0; i < badges_events_for_attendee.length; i++) {
      const badge_event = badges_events_for_attendee[i];
      if (badge_event.inserted == true) {
        continue;
      }
      if (i == badges_events_for_attendee.length - 1) {
        continue;
      }
      const next_badge_event = badges_events_for_attendee[i + 1];
      if (next_badge_event.inserted == true) {
        continue;
      }
      index_to_remove_list.push(i);
    }
    if (index_to_remove_list.length > 0) {
      console.log(
        'We actually need to remove some double sequential not inserted badge_events, index_to_remove_list',
        index_to_remove_list
      );
      index_to_remove_list = index_to_remove_list.reverse();
      for (const index_to_remove of index_to_remove_list) {
        badges_events_for_attendee.splice(index_to_remove, 1);
      }
    }

    return badges_events_for_attendee;
  }
  getStringFromMoment(date: moment.Moment) {
    if (!date.isValid()) {
      console.error('date is not valid', date);
      return '--';
    }
    //create a moment istance date from string with format: "2023-11-23 16:05:20"
    const ret = date.format('YYYY-MM-DD HH:mm:ss');
    return ret;
  }
  getStringFromMomentForUI(date: moment.Moment) {
    if (!date.isValid()) {
      console.error('date is not valid', date);
      return '--';
    }
    //create a moment istance date from string with format: "2023-11-23 16:05:20"
    const ret = date.format('HH:mm:ss DD-MM-YYYY');
    return ret;
  }
  getStringRapresentationOfDuration(duration: moment.Duration) {
    if (duration == null || duration.isValid() == false) {
      return '--';
    }
    return `${duration.days()}g ${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`;
  }

  getTotalSuspendedDuration() {
    const suspended_duration = moment.duration(0);
    if (
      this.full_meeting == null ||
      this.full_meeting?.status_update?.length == 0
    ) {
      console.error(
        'full_meeting is null or no status_update, cant calculate suspended_duration = 0'
      );
      return suspended_duration;
    }

    for (let i = 0; i < this.full_meeting.status_update.length; i++) {
      const status_update = this.full_meeting.status_update[i];
      if (status_update[UpdateStatus.SUSPENDED]) {
        const suspended_start = this.getMomentFromString(
          status_update[UpdateStatus.SUSPENDED]
        );
        if (suspended_start == null) {
          console.error('suspended_start is null');
          continue;
        }
        let suspended_end = moment();
        if (i < this.full_meeting.status_update.length - 1) {
          const next_status_update = this.full_meeting.status_update[i + 1];
          const next_valid_date =
            next_status_update[UpdateStatus.RUNNING] ||
            next_status_update[UpdateStatus.DONE];
          if (next_valid_date) {
            const pp = this.getMomentFromString(next_valid_date);
            if (pp == null) {
              console.error('pp is null');
              continue;
            }
            suspended_end = pp;
            const suspended_duration_tmp = moment.duration(
              suspended_end.diff(suspended_start)
            );
            suspended_duration.add(suspended_duration_tmp);
          }
        }
      }
    }

    return suspended_duration;
  }
  getMomentFirstOccuranceOfMeetingStatus(status: UpdateStatus) {
    /**
     * status_update=  [
    {
        "running": "2023-09-20 10:13:40"
    },
    {
        "suspended": "2023-09-25 13:22:23"
    },
    {
        "running": "2023-09-25 13:23:08"
    },
    {
        "suspended": "2023-09-25 13:40:45"
    },
    {
        "running": "2023-09-25 14:59:32"
    },
    {
        "done": "2023-09-25 15:01:30"
    }
]
     *
     */
    let ret: moment.Moment | null = null;
    const hacked_meeting = this.full_meeting as any;
    if (
      this.full_meeting == null ||
      hacked_meeting?.status_update?.length == 0
    ) {
      console.error('full_meeting is null or no status_update');
      return ret;
    }
    for (const status_update of hacked_meeting.status_update) {
      if (status_update[status]) {
        const converted = this.getMomentFromString(status_update[status]);
        if (converted != null) {
          ret = converted;
          return ret;
        }
      }
    }
    return ret;
  }
  getMomentLastOccuranceOfMeetingStatus(status: UpdateStatus) {
    let ret: moment.Moment | null = null;
    const hacked_meeting = this.full_meeting as any;
    if (
      this.full_meeting == null ||
      hacked_meeting?.status_update?.length == 0
    ) {
      console.error('full_meeting is null or no status_update');
      return ret;
    }
    for (const status_update of hacked_meeting.status_update) {
      if (status_update[status]) {
        const converted = this.getMomentFromString(status_update[status]);
        if (converted != null) {
          ret = converted;
        }
      }
    }
    return ret;
  }

  getMomentFromString(date: string) {
    //create a moment istance date from string with format: "2023-11-23 16:05:20"
    const ret = moment(date, 'YYYY-MM-DD HH:mm:ss');
    if (!ret.isValid()) {
      console.error('date is not valid', date);
      return null;
    }
    return ret;
  }

  getLastSuspendedDate(date: moment.Moment) {
    const hacked_meeting = this.full_meeting as any;
    if (
      this.full_meeting == null ||
      hacked_meeting?.status_update?.length == 0
    ) {
      console.error(
        'getLastSuspendedDate full_meeting is null or no status_update'
      );
      throw new Error(
        'getLastSuspendedDate full_meeting is null or no status_update'
      );
    }
    const status_update = hacked_meeting.status_update as {
      [key: string]: string;
    }[];
    for (let i = 0; i < status_update.length; i++) {
      const sta_tmp = status_update[i];
      if (sta_tmp[UpdateStatus.SUSPENDED]) {
        const converted = this.getMomentFromString(
          sta_tmp[UpdateStatus.SUSPENDED]
        );
        if (converted != null) {
          if (converted.isSameOrBefore(date)) {
            return converted;
          }
        }
      }
    }
    console.error('getLastSuspendedDate no next date found ?????');
    return null;
  }

  getNextRunningDateAfterDate(date: moment.Moment) {
    const hacked_meeting = this.full_meeting as any;
    if (
      this.full_meeting == null ||
      hacked_meeting?.status_update?.length == 0
    ) {
      console.error(
        'getNextRunningDateAfterDate full_meeting is null or no status_update'
      );
      throw new Error(
        'getNextRunningDateAfterDate full_meeting is null or no status_update'
      );
    }
    const status_update = hacked_meeting.status_update as {
      [key: string]: string;
    }[];

    for (let i = 0; i < status_update.length; i++) {
      const sta_tmp = status_update[i];
      if (sta_tmp[UpdateStatus.RUNNING]) {
        const converted = this.getMomentFromString(
          sta_tmp[UpdateStatus.RUNNING]
        );
        if (converted != null) {
          if (converted.isAfter(date)) {
            return converted;
          }
        }
      }
    }
    console.error('getNextRunningDateAfterDate no next date found ?????');
    return null;
  }
  getMeetingStatusForDate(date: moment.Moment) {
    const hacked_meeting = this.full_meeting as any;
    if (
      this.full_meeting == null ||
      hacked_meeting?.status_update?.length == 0
    ) {
      console.error(
        'getMeetingStatusForDate full_meeting is null or no status_update'
      );
      throw new Error(
        'getMeetingStatusForDate full_meeting is null or no status_update'
      );
    }
    const status_update = hacked_meeting.status_update as {
      [key: string]: string;
    }[];
    let last_status_before_date: UpdateStatus | null = null;
    for (let i = 0; i < status_update.length; i++) {
      const sta_tmp = status_update[i];
      if (sta_tmp[UpdateStatus.RUNNING]) {
        const converted = this.getMomentFromString(
          sta_tmp[UpdateStatus.RUNNING]
        );
        if (converted != null) {
          if (converted.isSameOrBefore(date)) {
            last_status_before_date = UpdateStatus.RUNNING;
          }
        }
      }
      if (sta_tmp[UpdateStatus.SUSPENDED]) {
        const converted = this.getMomentFromString(
          sta_tmp[UpdateStatus.SUSPENDED]
        );
        if (converted != null) {
          if (converted.isSameOrBefore(date)) {
            last_status_before_date = UpdateStatus.SUSPENDED;
          }
        }
      }
      if (sta_tmp[UpdateStatus.DONE]) {
        const converted = this.getMomentFromString(sta_tmp[UpdateStatus.DONE]);
        if (converted != null) {
          if (converted.isSameOrBefore(date)) {
            last_status_before_date = UpdateStatus.DONE;
          }
        }
      }
    }
    if (last_status_before_date == null) {
      console.error('last_status_before_date is null');
      throw new Error('last_status_before_date is null');
    }
    return last_status_before_date;
  }

  getMockedBadgeEventsResponse() {
    return {
      badge_events: [
        {
          _id: {
            $oid: '6512c1649cbdc9f2ca718a9a',
          },
          user: 'c_voter1',
          inserted: true,
          timestamp: '2023-09-26 13:45:00',
          meeting_id: {
            $oid: '6512bf3f3c7c190ff811081a',
          },
        },
        {
          _id: {
            $oid: '6512c1829cbdc9f2ca718a9b',
          },
          user: 'c_voter2',
          inserted: true,
          timestamp: '2023-09-26 13:00:00',
          meeting_id: {
            $oid: '6512bf3f3c7c190ff811081a',
          },
        },
        {
          _id: {
            $oid: '6512c1909cbdc9f2ca718a9c',
          },
          user: 'c_voter2',
          inserted: false,
          timestamp: '2023-09-26 13:28:00',
          meeting_id: {
            $oid: '6512bf3f3c7c190ff811081a',
          },
        },
      ],
    };
  }
  getMockedMeeting() {
    return {
      _id: {
        $oid: '6512bf3f3c7c190ff811081a',
      },
      title: 'testbadges',
      description: '',
      status: 'DONE',
      agenda: [],
      calls: [],
      firstCall: {
        startDate: {
          $date: 1695727380000,
        },
        endDate: null,
      },
      secondCall: null,
      attendees: [
        {
          _id: 'c_voter1',
          name: 'Cocon Voter1',
          pec: 'cocon.verdi.test@email.eu',
          guest: false,
          label: '',
          countsForQuorum: true,
          voteWeight: 1,
          email: 'voter1@prov_varese.it',
        },
        {
          _id: 'c_voter2',
          name: 'Cocon Voter2',
          pec: 'cocon.verdi.test@email.eu',
          guest: false,
          label: '',
          countsForQuorum: true,
          voteWeight: 2,
          email: 'voter2@prov_varese.it',
        },
        {
          _id: 'c_voter3',
          name: 'Cocon Voter3',
          pec: 'cocon.verdi.test@email.eu',
          guest: false,
          label: '',
          countsForQuorum: true,
          voteWeight: 2,
          email: 'voter3@prov_varese.it',
        },
      ],
      link: '',
      notes: '',
      notifications: [],
      _etag: {
        $oid: '6512bfa93c7c190ff811081f',
      },
      status_update: [
        {
          running: '2023-09-26 13:00:00',
        },
        {
          suspended: '2023-09-26 13:20:00',
        },
        {
          running: '2023-09-26 13:30:00',
        },

        {
          done: '2023-09-26 14:00:00',
        },
      ],
    };
  }
}

export enum TypeEventCandidate {
  BADGE_ENTER,
  BADGE_LEAVE,
  MEETING_RUNNING,
  MEETING_SUSPENDED,
  MEETING_DONE
}
export interface EventOnMeetingForCadidate {
  timestamp: string;
  type_event: TypeEventCandidate;
}
export enum UpdateStatus {
  RUNNING = 'running',
  SUSPENDED = 'suspended',
  DONE = 'done',
}
export interface BadgeEvent {
  inserted: boolean;
  meeting_id: any;
  timestamp: string;
  user: string;
}
