import {
  ConfirmationMessageDialogComponent,
  errorHandlingFunction,
  MeetingActions,
  MeetingService,
  IAppState,
  ApplicativeError,
  Meeting
} from 'oa-lib';

import { NgRedux } from '@angular-redux/store';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as momentJS from 'moment';
import { of } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import CloseCallMap = ApplicativeError.CloseCallMap;

const moment = (momentJS as any).default ? (momentJS as any).default : momentJS;

@Component({
  selector: 'app-meeting-call-list',
  templateUrl: './meeting-call-list.component.html',
  styleUrls: ['./meeting-call-list.component.scss'],
})
export class MeetingCallListComponent implements OnInit {

  meetingIdFromParams: string | null;
  selectedMeeting: Meeting;
  meetingGlobalStatus: Meeting.MeetingGlobalStatus;

  meetingAttendances: { _id: string; name: string }[] = [];
  callVerifiedRepliers:
    | { userIds: string[]; _id: { $oid: string } }[]
    | null = null;

  closingCall = false;
  refreshingCalls = false;
  loading = true;

  sectionTitle = 'Dashboard Appelli';

  calls: Meeting.Call[];

  constructor(
    private meetingSvc: MeetingService,
    private meetingActions: MeetingActions,
    private redux: NgRedux<IAppState>,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.meetingIdFromParams = this.route.snapshot.params.idMeeting;

    if (this.meetingIdFromParams) {
      this.meetingSvc
        .retrieveMeeting(this.meetingIdFromParams)
        .subscribe((meeting: Meeting | null) => {
          if (meeting) {
            this.meetingActions.update(meeting);
            this.getMeetingFromStore();
            this.calls = this.selectedMeeting.calls;
            this.meetingAttendances = this.selectedMeeting.attendees;
            if (this.calls?.length) {
              const callIds = this.calls.map((c: any) => c.id.$oid);
              this.meetingSvc
                .getCallVerifiedRepliers(this.getIdsArray(callIds))
                .subscribe((verifiedRepliersReponse) => {
                  this.loading = false;
                  if (verifiedRepliersReponse.status === 200) {
                    if (verifiedRepliersReponse.body?.length) {
                      this.callVerifiedRepliers = verifiedRepliersReponse.body;
                    } else {
                      this.callVerifiedRepliers = [];
                    }
                  } else {
                    throw new Error(
                      'Cannot retrieve callVerifiedRepliers: ' +
                      JSON.stringify(verifiedRepliersReponse, null, 4)
                    );
                  }
                });
            } else {
              this.loading = false;
            }
          }
        });
    }

    this.updateStatus();
  }

  callAttendances(
    call: Meeting.Call
  ): { _id: string; name: string; verified: boolean }[] {
    const currentVerifiedRepliers = this.callVerifiedRepliers?.length
      ? this.callVerifiedRepliers
        .filter((r) => r._id.$oid === call.id.$oid)
        .map((a) => a.userIds)[0]
      : [];

    const callAttendences = call.attendees
      .map((obj) => ({
        ...obj,
        verified: currentVerifiedRepliers?.includes(obj._id) ? true : false,
      }))
      .sort((a: any, b: any) => (a.name > b.name ? 1 : -1));

    return callAttendences;
  }

  callVerifiedAttendances(
    call: Meeting.Call
  ): { _id: string; name: string; verified: boolean }[] {
    const callVerifiedAttendances = this.callAttendances(call).filter(
      (a: any) => a.verified
    );

    return callVerifiedAttendances;
  }
  callVerifiedWeigth(call: Meeting.Call) {

    let tot_weight = 0;
    const callVerifiedAttendances = this.callAttendances(call).filter(
      (a: any) => a.verified
    );
    if (callVerifiedAttendances.length<1) {return 0;}
    for (const user of callVerifiedAttendances) {
      tot_weight += this.getWeight(user._id,call);
    }
    return tot_weight;
  }
  callNotVerifiedWeigth(call: Meeting.Call) {

    let tot_weight = 0;
    const callVerifiedAttendances = this.callAttendances(call).filter(
      (a: any) => !a.verified
    );
    if (callVerifiedAttendances.length<1) {return 0;}
    for (const user of callVerifiedAttendances) {
      tot_weight += this.getWeight(user._id,call);
    }
    return tot_weight;
  }
  private getWeight(userid: string,call: Meeting.Call) {

    for (const att of call.attendees) {
      if (att._id === userid &&  att.voteWeight) {
        return att.voteWeight;
      }
    }
    return 1;

  }
  callNotVerifiedAttendances(
    call: Meeting.Call
  ): { _id: string; name: string; verified: boolean }[] {
    const callNotVerifiedAttendances = this.callAttendances(call).filter(
      (a: any) => !a.verified
    );

    return callNotVerifiedAttendances;
  }

  navigateBack(): void {
    this.router.navigate([
      'meeting',
      'management',
    ]);
  }

  getMeetingFromStore() {
    const selectedMeeting = this.redux.getState().meeting.selectedMeeting;
    if (selectedMeeting) {
      this.selectedMeeting = selectedMeeting;
    }
  }

  updateStatus(): void {
    this.meetingSvc
      .getMeetingStatus()
      .pipe(
        take(1),
        catchError((e) => {
          throw e;
          return of(null);
        })
      )
      .subscribe((res) => {
        // handle results (not gone in error)
        if (res) {
          this.meetingGlobalStatus = res;
        }
      });
  }

  getIdsArray(array: any): string {
    let result = '[';
    array.forEach((elem: any) => {
      result += '{"$oid":"';
      result += elem;
      result += '"},';
    });
    result += ']';
    return result;
  }

  oidToDate(oid: string) {
    return new Date(parseInt(oid.slice(0, 8), 16) * 1000);
  }

  getCallDate(date: string) {
    moment.locale('it');
    return moment(new Date(date)).format('LLLL');
  }

  isActiveCall(call: Meeting.Call) {
    return call.id.$oid === this.meetingGlobalStatus?.call?.id.$oid;
  }

  closeCall(): void {
    this.closingCall = true;
    this.meetingSvc
      .closeCall()
      .pipe(
        take(1),
        catchError(errorHandlingFunction(this.dialog, CloseCallMap))
      )
      .subscribe((res) => {
        // handle results (not gone in error)
        if (res) {
          if (res.status === 200) {
            this.closingCall = false;
            this.dialog.open(ConfirmationMessageDialogComponent, {
              data: JSON.stringify({
                message: 'Appello chiuso con successo',
                redirectTo: null,
              }),
            });
            this.ngOnInit();
          } else {
            throw new Error('Cannot close call: ' + JSON.stringify(res));
          }
        }
      });
  }

  refreshCalls(): void {
    if (this.selectedMeeting?._id?.$oid) {
      this.refreshingCalls = true;
      this.meetingSvc
        .retrieveMeeting(this.selectedMeeting._id.$oid)
        .subscribe((meeting: Meeting | null) => {
          if (meeting) {
            this.meetingActions.update(meeting);
            this.getMeetingFromStore();
            this.calls = this.selectedMeeting.calls;
            this.refreshingCalls = false;
            this.ngOnInit();
          }
        });
    }
  }

  printCalls(): void {
    return window.print();
  }
}
