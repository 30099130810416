import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { TENANT_NAME_PLACEHOLDER } from 'oa-lib';

const URL = `${environment.RH_BASE_URL}/${TENANT_NAME_PLACEHOLDER}/attachments.files`;

@Injectable({
  providedIn: 'root',
})
export class AttachmentsService {
  constructor(private httpClient: HttpClient) { }

  public uploadAttachment(
    file: any,
    meeting_id: string | undefined
  ): Observable<HttpResponse<any>> {
    const formData = new FormData();

    formData.append('file', file);
    formData.append(
      'properties',
      `{"filename":"${file.name}", "meetingId":"${meeting_id}", "agendaId":null}, `
    );

    return this.httpClient.post(URL, formData, {
      withCredentials: true,
      observe: 'response',
      responseType: 'json',
    });
  }

  public editAttachmentMetadata(
    meetingId: string | undefined,
    doc_id: string | undefined,
    agendaId: string | undefined,
    userId: string,
    name: string,
  ): Observable<HttpResponse<any>> {
    const url = URL + `/${doc_id}?meetingId=${meetingId}`;
    return this.httpClient.put(
      url,
      { 'metadata.agendaId': agendaId, 'metadata.meetingId': meetingId, 'metadata.user': userId, 'metadata.name': name},
      {
        withCredentials: true,
        observe: 'response',
        responseType: 'json',
      }
    );
  }
  public getAttachments(
    meetingId: string | undefined
  ): Observable<HttpResponse<any>> {
    const url = URL + `?meetingId=${meetingId}`;
    return this.httpClient.get(url, {
      withCredentials: true,
      observe: 'response',
      responseType: 'json',
    });
  }

  public deleteAttachment(
    attachmentId: string | undefined
  ): Observable<HttpResponse<any>> {
    const url = URL + `/${attachmentId}`;
    return this.httpClient.delete(url, {
      withCredentials: true,
      observe: 'response',
      responseType: 'json',
    });
  }

  public getAttachmentBinary(
    meetingId: string | undefined,
    id: string | undefined
  ): Observable<HttpResponse<any>> {
    const url = URL + `/${id}/binary?meetingId=${meetingId}`;
    return this.httpClient.get(url, {
      withCredentials: true,
      observe: 'response',
    });
  }
}
