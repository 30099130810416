import { TenantState, TenantType } from 'oa-lib';
/* eslint-disable no-underscore-dangle */
import { AuthState } from 'oa-lib';
import { Observable } from 'rxjs';
import { select } from '@angular-redux/store';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-desktop-menu',
    templateUrl: './desktop-menu.component.html',
    styleUrls: ['./desktop-menu.component.scss']
})
export class DesktopMenuComponent {
    @select('rehydrated') rehydrated: Observable<boolean>;
    @select('auth') auth: Observable<AuthState>;
    @select('tenant') tenant: Observable<TenantState>;

    @Input() isVoter: boolean;
    @Input() isManager: boolean;
    @Input() isGuest: boolean;
    @Input() isSecretary: boolean;
    @Input() isVerifying: boolean;
    @Input() isIntoUserProfile: boolean;

    @Input() isCardManager: boolean;

    @Input() isSynopticManager: boolean;

    @Input() canAccessUserProfile: boolean;
    @Input() currentRoute: string;

    @Output() logoutEvent = new EventEmitter();
    @Output() navigateToCalendarEvent = new EventEmitter();
    @Output() navigateToUserProfileEvent = new EventEmitter();
    @Output() navigateToCurrentMeetingEvent = new EventEmitter();
    @Output() navigateToManagementEvent = new EventEmitter();

    @Output() navigateToViewerEvent= new EventEmitter();
    @Output() navigateToCardsEvent = new EventEmitter();

    @Output() navigateToSynopticEvent = new EventEmitter();
    TenantType = TenantType;

    constructor() { }


    isSynopticRoute() {
        return this.currentRoute === '/synoptic';
    }
    isCardRoute() {
        return this.currentRoute === '/cards';
    }
    isCalendarRoute() {
        return this.currentRoute === '/';
    }
    isProfileRoute() {
        return this.currentRoute === '/profile';
    }
    isManagementRoute() {
        return this.currentRoute === '/meeting/management';
    }
    isCurrentMeetingRoute() {
        return this.currentRoute === '/meeting/current';
    }

    isViewverSynopticRoute() {
        return this.currentRoute ==='/synoptic-viewer';
    }
}
