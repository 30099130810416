import { Meeting, MeetingService, TenantService } from 'oa-lib';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { BadgeEvent } from '../meeting-attendance.component';

@Component({
  selector: 'meeting-attendance-delegate',
  templateUrl: './meeting-attendance-delegate.component.html',
  styleUrls: ['./meeting-attendance-delegate.component.scss'],
})
export class MeetingAttendanceDelegateComponent implements OnInit {

  showErrorInit = false;

  name = 'Nome delegato';
  labelpercentage = "";
  labelduration = "";
  inputBadgeData: any = null;

  tableData: any[] = [];
  constructor(
    private meetingSvc: MeetingService,
    private tenantService: TenantService,
    private meetingService: MeetingService,
    private route: ActivatedRoute,
    protected router: Router
  ) {}

  ngOnInit(): void {
    this.inputBadgeData = this.route.snapshot.params.inputBadgeData;
    if (!this.inputBadgeData) {
      this.router.navigate(['']);
    }
    this.init();
  }
  async init() {
    this.inputBadgeData = JSON.parse(this.inputBadgeData);
    console.log('inputBadgeData', this.inputBadgeData);
    this.name = this.inputBadgeData.name;
    this.labelduration = this.inputBadgeData.labelduration;
    this.labelpercentage = this.inputBadgeData.labelpercentage;
    this.calculateTableData();
  }

  calculateTableData() {
    this.tableData = [];
    const events = this.inputBadgeData.list_badge_events as BadgeEvent[];
    for (let i = 0; i < events.length; i++) {
      const event = events[i];
      this.tableData.push({
        eventlabel: event.inserted ? "Ingresso" : "Uscita",
        datelabel: this.getStringTimeStampIta(event.timestamp),
      }
      );
  }
  }



  getStringTimeStampIta(stringDate: string) {
    const date = moment(stringDate);
    if (!date.isValid()) {
      console.error('date is not valid', date);
      return '--';
    }
    //create a moment istance date from string with format: "2023-11-23 16:05:20"
    const ret = date.format('HH:mm:ss DD-MM-YYYY');
    return ret;
  }
  getStringRapresentationOfDuration(duration: moment.Duration) {
    if (duration == null || duration.isValid() == false) {
      return '--';
    }
    return `${duration.days()}g ${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`;
  }

}
