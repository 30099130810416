import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Mongo, TENANT_NAME_PLACEHOLDER } from 'oa-lib';
import * as moment from 'moment';

const URL = `${environment.RH_BASE_URL}/${TENANT_NAME_PLACEHOLDER}`;

@Injectable({
    providedIn: 'root',
})
export class DCernoService {


    constructor(private httpClient: HttpClient) {

    }

     assignCardToUser(username: any, cardid: any): Observable<HttpResponse<any>>{
        const endpoint = `${environment.RH_BASE_URL}/txns/nfccard/`;

        const body = {
            userId: username,
            cardId:cardid
        };

        return this.httpClient.post(
            endpoint,
          body,
          {
            withCredentials: true,
            observe: 'response',
            responseType: 'json',
          }
        );
    }



}
