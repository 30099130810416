<div>
  <div class="text-center" matDialogTitle>Imposta durata appello</div>
  <form ngNativeValidate (submit)="handleSubmit()">
    <mat-dialog-content class="mat-typography text-center">
      <mat-form-field class="ml-3" appearance="outline">
        <mat-label>Durata</mat-label>
        <input
          matInput
          required
          name="duration"
          [(ngModel)]="this.duration"
          type="text"
          [dropSpecialCharacters]="false"
          [clearIfNotMatch]="true"
          [showMaskTyped]="true"
          suffix=" minuti"
          mask="00:00"
          value="05:00"
        />
      </mat-form-field>
    </mat-dialog-content>

    <div class="row">
      <div class="col-12 col-sm-6 text-center">
        <button
          type="button"
          class="action-button-lg mx-auto"
          mat-raised-button
          color="primary"
          (click)="dialogRef.close()"
        >
          Annulla
        </button>
      </div>
      <div class="col-12 col-sm-6 mt-2 mt-sm-0 text-center">
        <button
          type="submit"
          [class.spinner-btn]="false"
          class="action-button-lg mx-auto"
          mat-raised-button
          color="primary"
        >
          Conferma
        </button>
      </div>
    </div>
  </form>
</div>
<!-- 
  <ng-template #loading>
    <div class="d-flex w-100 p-2 align-content-center justify-content-center">
      <mat-spinner diameter="40"></mat-spinner>
    </div>
    <mat-dialog-content class="w-100 m-0 text-center mat-typography">
      Chiusura spoglio in corso
    </mat-dialog-content>
  </ng-template> -->
