<div class="container">
  <app-breadcrumb [sectionTitle]="sectionTitle" (actionEvent)="navigateBack()"></app-breadcrumb>
  <div *ngIf="calls?.length && callVerifiedRepliers !== null; else noData" class="mx-auto" style="max-width: 720px">
    <div class="mt-2 call-container" *ngFor="let call of calls; index as i" style="page-break-inside: avoid">
      <div class="d-flex flex-column flex-sm-row d-sm-inline-flex w-100 mb-2">
        <div class="text-center d-inline-flex align-items-center text-sm-left w-100 pl-3" style="line-height: 44px">
          {{ i + 1 }}&deg; Appello &nbsp;
          <strong class="text-capitalize">{{
            getCallDate(call.period.o)
            }}</strong>
          <mat-spinner class="spinner-cs ml-2" *ngIf="isActiveCall(call)" matTooltip="L'appello è in corso" diameter="25">
          </mat-spinner>
        </div>
        <div *ngIf="isActiveCall(call)"
          class="d-inline-flex justify-content-center justify-content-sm-end w-100 call-action-container">
          <button type="button" [disabled]="closingCall" [class.spinner-btn]="closingCall" (click)="closeCall()"
            class="action-button mr-2" mat-raised-button color="primary">
            Interrompi
          </button>

          <button type="submit" [disabled]="refreshingCalls" [class.spinner-btn]="refreshingCalls" class="action-button"
            (click)="refreshCalls()" mat-raised-button color="primary">
            Aggiorna
          </button>
        </div>
      </div>
      <div class="d-inline-flex">
        <ul>
          <li>
            <span>
              <strong>Totale Presenti:</strong>
              {{ callVerifiedAttendances(call).length }}
            </span>
          </li>
          <li>
            <span>
              <strong>Totale Assenti:</strong>
              {{ callNotVerifiedAttendances(call).length }}
            </span>
          </li>
          <li>
            <span>
              <strong>Totale Peso Presenti:</strong>
              {{ callVerifiedWeigth(call) }}
            </span>
          </li>
          <li>
            <span>
              <strong>Totale Peso Assenti:</strong>
              {{ callNotVerifiedWeigth(call) }}
            </span>
          </li>
        </ul>
      </div>
      <div class="scrollable-ballot-table mb-5">
        <table class="table" *ngIf="callAttendances(call) as _callAttendances">
          <thead>
            <tr>
              <!-- <th scope="col">#</th> -->
              <th scope="col">Partecipanti</th>
              <th scope="col">Presente/Assente</th>
            </tr>
          </thead>
          <tbody *ngIf="_callAttendances.length; else noAttendances">
            <tr *ngFor="let a of _callAttendances">
              <th scope="row">1</th>
              <td>{{ a.name }}</td>
              <td>{{ a.verified ? "Presente" : "Assente" }}</td>
            </tr>
          </tbody>
          <ng-template #noAttendances>
            <tbody>
              <td>Nessun partecipante.</td>
              <td></td>
            </tbody>
          </ng-template>
        </table>
      </div>
    </div>
    <div class="w-100 d-inline-flex justify-content-center">
      <button (click)="printCalls()" class="pl-5 pr-5" color="primary" mat-raised-button>
        STAMPA RISULTATI
      </button>
    </div>
  </div>
  <ng-template #noData>
    <div *ngIf="!loading" class="text-center w-100 mb-4">
      <h4>Nessun risultato disponibile</h4>
    </div>
    <div *ngIf="loading" class="d-flex w-100 p-2 align-content-center justify-content-center">
      <mat-spinner diameter="40"></mat-spinner>
    </div>
  </ng-template>
</div>