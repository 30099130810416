import { tap } from 'rxjs/operators';
import {
  AuthActions,
  AuthService,
  ConfirmationMessageDialogComponent,
  MatSnackBarDirective
} from 'oa-lib';
import { MediaMatcher } from '@angular/cdk/layout';
import { OnDestroy } from '@angular/core';
/* eslint-disable no-underscore-dangle */
import { MatDialog } from '@angular/material/dialog';
import { NgRedux } from '@angular-redux/store';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, Injector, ChangeDetectorRef } from '@angular/core';
import { IAppState } from 'oa-lib';
import { catchError } from 'rxjs/internal/operators/catchError';
import { DomSanitizer } from '@angular/platform-browser';

import { of } from 'rxjs/internal/observable/of';
import { ApplicativeErrorDialogComponent } from 'oa-lib';

@Component({
  selector: 'app-google-auth-setup',
  templateUrl: './google-auth-setup.component.html',
  styleUrls: ['./google-auth-setup.component.scss']
})
export class GoogleAuthSetupComponent extends MatSnackBarDirective implements OnInit, OnDestroy {

  otpQRCode: any;
  googleAuthCode = '';
  otpLoadError = false;

  testingOtp = false;
  generatedCode: string;

  firstSetup = false;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(
    private redux: NgRedux<IAppState>,
    private router: Router,
    public route: ActivatedRoute,
    private authSvc: AuthService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private authActions: AuthActions,
    protected inj: Injector,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher
  ) {
    super(inj);
    this.mobileQuery = this.media.matchMedia('(max-width: 540px)');
    this._mobileQueryListener = () => this.changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);

    this.route.queryParams.pipe(
      tap((params) => {
        if (params.firstSetup) {
          this.firstSetup = params.firstSetup === 'true';
        }
      })
    ).subscribe();
  }

  ngOnInit(): void {

    const userState = this.redux.getState().auth;

    if (!userState.authenticated || !userState.userid) {
      console.error('User is not authenticated');
      this.router.navigate(['signin']);
      return;
    }

    if (userState.totpQRCode) {

      this.authSvc.getOtpQRCode(userState.totpQRCode)
        .pipe(catchError(e => {
          if (e.status === 404) {
            return of(null);
          }
          throw e;
        }))
        .subscribe((res: any) => {
          if (res?.size !== 0) {
            this.createImageFromBlob(res);
          } else {
            this.otpLoadError = true;
          }
        });

      if (userState.totpSeed) { this.googleAuthCode = userState.totpSeed; }

    } else { throw new Error('Missing "totpQRCode" property in User object'); }

  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onloadend = () => {
      const base64String = reader.result as string;
      if (base64String) {
        this.otpQRCode = this.sanitizer.bypassSecurityTrustUrl(base64String);
      }
    };
  }

  copyCode(): void {
    this.openSnackbar('Codice copiato', 5000);
  }

  checkGoogleAuthConfiguration(): void {

    this.testingOtp = true;
    const userState = this.redux.getState().auth;

    if (userState.authenticated && userState.userid && this.generatedCode) {
      this.authSvc.checkOtpValidity({ code: this.generatedCode })
        .pipe(catchError(e => {
          if (e.status === 403) {
            return of(null);
          }
          throw e;
        }))
        .subscribe((res: any) => {
          if (res) {
            if (userState.authToken) {
              this.authActions.update({ ...userState, gaVerified: true });
              this.dialog.open(ConfirmationMessageDialogComponent, {
                data: JSON.stringify({
                  message: 'Il codice inserito è corretto',
                  redirectTo: ''
                })
              });
            } else {
              throw new Error('Malformed user in state');
            }
          } else {
            this.dialog.open(ApplicativeErrorDialogComponent, {
              data: JSON.stringify({
                message: 'Il codice inserito non è corretto',
                redirectTo: null
              })
            });
          }
          this.testingOtp = false;
        });
    }
  }

}
