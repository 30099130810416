import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import * as CryptoJS from "crypto-js";
import { ApplicativeErrorDialogComponent, ConfirmationMessageDialogComponent, User, UsersService } from "oa-lib";
import { DCernoService } from "src/app/services/dcerno.service";


@Component({
    selector: 'card-manager',
    templateUrl: './card-manager.component.html',
    styleUrls: ['./card-manager.component.scss']
})
export class CardManager implements OnInit {

    users_tenant: User[] | null = [];
    selectedUser: any = undefined;
    nfc_reader: NDEFReader | undefined;
    card_status = 'no_card';
    constructor(private usersService: UsersService, private fb: FormBuilder, private dialog: MatDialog, private dcernoService: DCernoService ) { }

    formcard: FormGroup;
    ngOnInit(): void {
        console.log('CardManager onInit');
        this.formcard = this.fb.group({
            user: ['']
        });

        if ('NDEFReader' in window) { /* Scan and write NFC tags */


            console.log('NDEFReader present');
            this.nfc_reader = new NDEFReader();
        }


        this.init();
    }

    async init() {
        await this.refreshUsers();
    }
    async refreshUsers() {

        this.users_tenant = (await this.usersService.getTenantUsers().toPromise()).body;
        console.log(this.users_tenant);
    }


    selectChangeUser(event: any) {
        console.log(event);
        this.selectedUser = this.users_tenant?.find(u => u._id == event.value);
    }
    async prepareCard() {
        console.log('prepareCard');
        // if (!this.nfc_reader || !this.selectedUser) {
        //     return;
        // }
        const rint= Math.floor(Math.random() * 100)+1;
        let cid = '' + this.selectedUser._id + rint;
        cid = '' + cid;
        console.log(cid);
        const payload_for_card = {
            tenant: this.selectedUser.tenant,
            userId: this.selectedUser._id,
            cardId: CryptoJS.MD5(cid).toString(),
        };
        console.log("payload for card", payload_for_card);
        this.card_status = 'payload_ready';

        try {
            await this.nfc_reader?.write(JSON.stringify(payload_for_card));
            this.card_status = 'no_card';

        } catch {
            console.log("Write failed :-( try again.");
            this.card_status = 'no_card';
            this.opendialError("Eccezione nella fc_reader_write .Errore durante la scrittura della carta.Se e' la prima volta riprova");
        }
        try {
            const response = await this.dcernoService.assignCardToUser(this.selectedUser._id, payload_for_card.cardId).toPromise();
            console.log(response);
            this.openDialMsg("Carta scritta correttamente e utente aggiornato");
        }
        catch {
            this.opendialError("Errore durante l'aggiornamento dell'utente");
        }
    }

    opendialError(msg: string) {
        {
            this.dialog.open(ApplicativeErrorDialogComponent, {
                data: JSON.stringify({
                    message: msg,
                    redirectTo: null,
                }),
            });
        }
    }
    openDialMsg(msg: string) {
        {
            this.dialog.open(ConfirmationMessageDialogComponent, {
                data: JSON.stringify({
                    message: msg,
                    redirectTo: null,
                }),
            });
        }
    }
}
