
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { AttachmentsService } from 'src/app/services/attachments.service';
import { NgRedux } from '@angular-redux/store';
import {
  AuthenticatedUserDirective,
  IAppState,
  MeetingService,
  ApplicativeError,
  errorHandlingFunction,
  Meeting,
  Attachments
} from 'oa-lib';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Mixin } from 'ts-mixer';
import { catchError, map, tap } from 'rxjs/operators';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import AttachmentsMap = ApplicativeError.AttachmentsMap;

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
})
export class DocumentComponent
  extends Mixin(AuthenticatedUserDirective)
  implements OnInit {
  fileName = '';

  meeting: Observable<Meeting | null> | undefined;
  meetingId: string;

  documents: Observable<Attachments[]>;

  sectionTitle = 'Documenti';

  form: FormGroup;
  orders: any = [];

  constructor(
    private redux: NgRedux<IAppState>,
    private attachSrv: AttachmentsService,
    private route: ActivatedRoute,
    private meetingSvc: MeetingService,
    protected router: Router,
    public dialog: MatDialog,
    public errorDialog: MatDialog,
    protected inj: Injector
  ) {
    super(inj);
  }

  ngOnInit(): void {
    this.meetingId = this.route.snapshot.params.idMeeting;
    if (!this.meetingId) {
      this.router.navigate(['']);
    } else {
      this.meetingSvc
        .retrieveMeeting(this.meetingId)
        .subscribe((meeting: Meeting | null) => {
          this.meeting = of(meeting);
          this.getAttachements();
        });
    }
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      // eslint-disable-next-line max-len
      this.attachSrv
        .uploadAttachment(file, this.meetingId)
        .pipe(
          catchError(errorHandlingFunction(this.errorDialog, AttachmentsMap))
        )
        .subscribe(
          (r) => {
            this.getAttachements();
          }
        );
    }
  }
  openDialog(id: any) {
    const dialogRef = this.dialog.open(DeleteDocumentModal, {
      width: '350px',
      data: { attachmentId: id },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getAttachements();
    });
  }

  isMyDocument(fileUserId: string) {
    const userid = this.redux.getState().auth.userid;
    if (userid == null) {
      return false;
    } else {
      if (fileUserId === userid) {
        return true;
      } else {
        return false;
      }
    }
  }

  openDoc(id: any) {
    const tenantId = this.redux.getState().tenant._id;
    window
      .open(
        `${environment.RH_BASE_URL}/${tenantId}/attachments.files/${id}/binary`,
        '_blank'
      )
      ?.focus();
  }

  assign(docId: any, event: any, userId: string, name: any) {
    this.attachSrv
      .editAttachmentMetadata(this.meetingId, docId, event.value, userId, name)
      .subscribe();
  }

  getAttachements() {
    this.attachSrv.getAttachments(this.meetingId).subscribe((res: any) => {
      this.documents = of(res.body);
    });
  }

  navigateBack(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.returnTo) {
        this.router.navigate(params.returnTo.split('/'));
      } else {
        this.router.navigate(['']);
      }
    });
  }
}

@Component({
  selector: 'app-delete-document-dialog',
  templateUrl: 'deleteDocumentModal.html',
  styleUrls: ['./document.component.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class DeleteDocumentModal {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { attachmentId: string },
    public dialogRef: MatDialogRef<DeleteDocumentModal>,
    private attachSrv: AttachmentsService
  ) { }

  deleteDoc() {
    this.attachSrv.deleteAttachment(this.data.attachmentId).subscribe();
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
