<div>
  <div class="container">
    <app-breadcrumb [sectionTitle]="sectionTitle" (actionEvent)="navigateBack()"></app-breadcrumb>
    <div class="row h-100 justify-content-center align-items-center mt-2">
      <div class="row h-100 justify-content-center align-items-center mt-2">
        <div class="col-12 text-center">
          Carica i documenti e poi associali ai punti della seduta
        </div>
      </div>
    </div>
    <div class="row h-100 justify-content-center align-items-center mt-4">
      <div class="d-flex justify-content-center w-100 mb-5">
        <button (click)="fileUpload.click()" class="pl-4 pr-4" color="primary" mat-raised-button>
          + CARICA DOCUMENTI
        </button>
        <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload accept="application/pdf" />

        <div class="file-upload">
          {{ fileName }}
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="(documents | async)?.length === 0"
    class="d-flex w-100 text-center p-5 align-content-center justify-content-center">Non sono presenti documenti per
    questa seduta.
  </div>

  <div *ngIf="documents | async as _docs; else loading">
    <div *ngIf="meeting | async as _meeting; else loading">
      <div class="container">
        <div *ngFor="let doc of _docs; index as i">
          <mat-divider class="d-md-none mb-3" *ngIf="i !== 0"></mat-divider>
          <div class="row h-100 justify-content-center align-items-center mt-2">
            <div class="col-12 text-center text-md-left mb-3 mb-md-2 col-md-2">
              <div class="d-inline-flex">
                <mat-icon class="ml-2 mr-2" color="primary">
                  description
                </mat-icon>
                <span style="line-height: 25px">{{ doc.filename }}</span>
              </div>
            </div>
            <div class="col-12 text-center text-md-left mb-3 mb-md-2 col-md-2">
              <div class="d-inline-flex">
                <mat-icon class="ml-2 mr-2" color="primary">
                  access_time
                </mat-icon>
                <span style="line-height: 25px">{{ doc.uploadDate.$date | date: "d/M/yy, H:mm"}}</span>
              </div>
            </div>

            <div class="col-12 text-center text-md-left mb-3 mb-md-2 col-md-2">
              <div class="d-inline-flex">
                <mat-icon class="ml-2 mr-2" color="primary">
                  person
                </mat-icon>
                <span style="line-height: 25px">{{ doc.metadata.name}}</span>
              </div>
            </div>
            <div class="col-12 col-md-2">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Associato a</mat-label>
                <mat-select [disabled]="!isManager() && !isMyDocument(doc.metadata.user)"
                  [(value)]="doc.metadata.agendaId" (selectionChange)="
                    assign(doc?._id?.$oid, $event, doc.metadata.user, doc.metadata.name)
                  ">
                  <mat-option [value]="0">Seduta</mat-option>
                  <mat-option *ngFor="let agenda of _meeting?.agenda" [value]="agenda.item_number">
                    punto {{ agenda.item_number }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-6 col-md-2 mb-4" [class.text-center]="isVoter()" [class.text-right]="isManager()">
              <button class="pl-4 pr-4" color="primary" mat-raised-button (click)="openDoc(doc._id?.$oid)">
                SCARICA
              </button>
            </div>
            <div *ngIf="isMyDocument(doc.metadata.user) || isManager()" class="col-6 col-md-2 mb-4"
              [class.text-left]="!isManager()" [class.text-left]="isManager()">
              <button class="pl-4 pr-4" color="primary" mat-raised-button (click)="openDialog(doc._id?.$oid)">
                ELIMINA
              </button>
            </div>
            <div *ngIf="!isMyDocument(doc.metadata.user)" class="col-6 col-md-2 mb-4" [class.text-left]="!isManager()"
              [class.text-left]="isManager()"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="d-flex w-100 p-5 align-content-center justify-content-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>