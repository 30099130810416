import { OpenAgendaItemDialogComponent } from './components/open-agenda-item-dialog/open-agenda-item-dialog.component';
/* eslint-disable no-underscore-dangle */
import { OpenMeetingDialogComponent } from './components/open-meeting-dialog/open-meeting-dialog.component';

import {
  ApplicativeErrorDialogComponent,
  MeetingService,
  IAppState,
  Meeting,
  Mongo,
  errorHandlingFunction,
  ConfirmActionDialogComponent,
  UserConfirmActionType,
  ApplicativeError,
  ConfirmationMessageDialogComponent,
  TenantState,
  TenantService,
  Tenant,
} from 'oa-lib';

import { MeetingOpenCallDialogComponent } from './components/meeting-open-call-dialog/meeting-open-call-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { catchError, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgRedux } from '@angular-redux/store';
import { of, Observable, Subscription } from 'rxjs';

import OpenCallMap = ApplicativeError.OpenCallMap;
import OpenMeetingMap = ApplicativeError.OpenMeetingMap;
import SuspendMeetingMap = ApplicativeError.SuspendMeetingMap;
import RescheduleMeetingMap = ApplicativeError.RescheduleMeetingMap;
import ResumeMeetingMap = ApplicativeError.ResumeMeetingMap;
import CloseMeetingMap = ApplicativeError.CloseMeetingMap;

import OpenAgendaItemMap = ApplicativeError.OpenAgendaItemMap;
import CloseAgendaItemMap = ApplicativeError.CloseAgendaItemMap;
import CancelAgendaItemMap = ApplicativeError.CancelAgendaItemMap;

import OpenBallotMap = ApplicativeError.OpenBallotMap;
import CloseBallotMap = ApplicativeError.CloseBallotMap;

import { MeetingRescheduleDialogComponent } from './components/meeting-reschedule-dialog/meeting-reschedule-dialog.component';

@Component({
  selector: 'app-meeting-management',
  templateUrl: './meeting-management.component.html',
  styleUrls: ['./meeting-management.component.scss'],
})
export class MeetingManagementComponent implements OnInit {
  meeting: Observable<Meeting | null>;

  meetingGlobalStatus: Observable<Meeting.MeetingGlobalStatus>;
  globalStatusSub: Subscription;
  tenantName: any;
  tenant_synoptic: any;

  MeetingStatus = Meeting.Status;
  BallotStatus = Meeting.Agenda.Ballot.BallotStatusType;
  tenant: Tenant | null = null;
  synoptic_enabled = false;

  is_cocon_tenant = false;
  constructor(
    private meetingSvc: MeetingService,
    private dialog: MatDialog,
    private router: Router,
    private redux: NgRedux<IAppState>,
    private tenantService: TenantService,
  ) {}

  ngOnInit(): void {
    this.init();
    this.refreshStatus();

  }
  async init() {

    const res = await this.tenantService.getTenant().toPromise();
      this.tenant = res.body;
      console.log('tenant: ', this.tenant);
    this.synoptic_enabled = this.tenant?.synoptic_enabled || false;
    const hack_tenant: any = this.tenant;
    this.is_cocon_tenant = hack_tenant?.cocon_enabled || false;
  }
  updateStatus() {
    this.meetingGlobalStatus = this.meetingSvc.getMeetingStatus();
  }

  async editMeeting() {
    const _meeting = await this.meeting.toPromise();

    if (_meeting && _meeting._id?.$oid) {
      this.router.navigate(['meeting', 'editor', _meeting._id.$oid], {
        queryParams: {
          returnTo: 'meeting/management',
        },
      });
    } else {
      this.openMeetingNotActiveDialog();
    }
  }

  async editMeetingAddVote() {
    const _meeting = await this.meeting.toPromise();

    if (_meeting && _meeting._id?.$oid) {
      this.router.navigate(['meeting', 'editor', _meeting._id.$oid], {
        queryParams: {
          addVote: 'true',
          returnTo: 'meeting/management',
        },
      });
    } else {
      this.openMeetingNotActiveDialog();
    }
  }

  async navigateToDocuments() {
    const _meeting = await this.meeting.toPromise();
    if (_meeting && _meeting._id?.$oid) {
      this.router.navigate(['meeting', _meeting._id.$oid, 'documents'], {
        queryParams: {
          returnTo: 'meeting/management',
        },
      });
    }
  }

  async goBadgeEvents() {
    const _meeting = await this.meeting.toPromise();
    if (_meeting && _meeting._id?.$oid) {
      this.router.navigate(['meeting', _meeting._id.$oid, 'attendance'], {
        queryParams: {
          returnTo: 'meeting/management',
        },
      });
    }
  }
  openMeetingNotActiveDialog() {
    this.dialog.open(ApplicativeErrorDialogComponent, {
      data: JSON.stringify({
        message: 'Nessun meeting attivo',
        redirectTo: null,
      }),
    });
  }

  async openCall(duration: number) {
    this.meetingSvc
      .openCall(duration)
      .pipe(
        take(1),
        catchError(errorHandlingFunction(this.dialog, OpenCallMap))
      )
      .subscribe((res) => {
        // handle results (not gone in error)
        if (res) {
          if (res?.status === 200) {
            this.dialog.open(ConfirmationMessageDialogComponent, {
              data: JSON.stringify({
                message: 'Appello avviato con successo',
                redirectTo: null,
              }),
            });
          } else {
            throw new Error(
              'Error opening Call' + JSON.stringify(res, null, 4)
            );
          }
        }
        this.updateStatus();
      });
  }

  async navigateToCallsList() {
    const _meeting = await this.meeting.toPromise();

    if (_meeting && _meeting._id?.$oid) {
      this.router.navigate(['meeting', _meeting._id?.$oid, 'calls']);
    } else {
      this.openMeetingNotActiveDialog();
    }
  }

  openVideoCallTab(link: string) {
    if (link && link !== '') {
      window.open(link, '_blank');
    }
  }

  showOpenCallDialog() {
    this.dialog
      .open(MeetingOpenCallDialogComponent)
      .afterClosed()
      .subscribe((result: { duration: string }) => {
        if (result?.duration) {
          const duration = this.mmssToSeconds(result.duration);
          if (duration) {
            this.openCall(duration);
          }
        }
      });
  }

  openRescheduleMeetingDialog() {
    this.dialog
      .open(MeetingRescheduleDialogComponent)
      .afterClosed()
      .subscribe((result: { date: Mongo.Date }) => {
        if (result?.date) {
          this.rescheduleMeeting(result?.date);
        }
      });
  }

  isCallActive(status: Meeting.MeetingGlobalStatus) {
    return status.call !== null;
  }

  mmssToSeconds(mmss: string): number | null {
    if (!mmss) {
      return null;
    } else {
      const a = mmss.split(':'); // split it at the colons
      return +a[0] * 60 + +a[1];
    }
  }

  hhmmssToSeconds(hhmmss: string) {
    if (!hhmmss) {
      return null;
    } else {
      const a = hhmmss.split(':'); // split it at the colons
      return +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
    }
  }

  async openMeeting() {
    const dialogRef = this.dialog.open(OpenMeetingDialogComponent);

    dialogRef.afterClosed().subscribe((_meeting) => {
      if (_meeting) {
        if (_meeting && _meeting._id?.$oid) {
          this.meetingSvc
            .openMeeting(_meeting)
            .pipe(
              take(1),
              catchError(errorHandlingFunction(this.dialog, OpenMeetingMap))
            )
            .subscribe(async (res) => {
              // handle results (not gone in error)
              if (res) {
                this.dialog.open(ConfirmationMessageDialogComponent, {
                  data: JSON.stringify({
                    message: 'Seduta aperta con successo',
                    redirectTo: null,
                  }),
                });
              }
              await this.refreshStatus();
            });
        } else {
          this.openMeetingNotActiveDialog();
        }
      }
    });
  }

  async openAgendaItem() {
    const _meeting = await this.meeting.toPromise();

    if (_meeting?.agenda) {
      const dialogRef = this.dialog.open(OpenAgendaItemDialogComponent, {
        data: _meeting,
      });

      dialogRef.afterClosed().subscribe((agendaItem: Meeting.Agenda.Item) => {
        if (agendaItem) {
          this.meetingSvc
            .openAgendaItem(agendaItem)
            .pipe(
              take(1),
              catchError(errorHandlingFunction(this.dialog, OpenAgendaItemMap))
            )
            .subscribe(async (res) => {
              // handle results (not gone in error)
              if (res) {
                this.dialog.open(ConfirmationMessageDialogComponent, {
                  data: JSON.stringify({
                    message: 'Punto aperto con successo',
                    redirectTo: null,
                  }),
                });
              }
              await this.refreshStatus();
            });
        }
      });
    } else {
      this.dialog.open(ApplicativeErrorDialogComponent, {
        data: JSON.stringify({
          message:
            "Non è presente alcun punto dell'ordine del giorno associato al meeting",
          redirectTo: null,
        }),
      });
    }
  }

  async cancelAgendaItem() {
    const userAction = await this.promptForConfirm(
      "Vuoi davvero annullare l'apertura del punto?"
    );
    if (userAction?.action === UserConfirmActionType.DENY) {
      return;
    }

    this.meetingSvc
      .cancelAgendaItem()
      .pipe(
        take(1),
        catchError(errorHandlingFunction(this.dialog, CancelAgendaItemMap))
      )
      .subscribe(async (res) => {
        // handle results (not gone in error)
        if (res) {
          this.dialog.open(ConfirmationMessageDialogComponent, {
            data: JSON.stringify({
              message: 'Apertura punto annullata con successo',
              redirectTo: null,
            }),
          });
        }
        await this.refreshStatus();
      });
  }

  async closeAgendaItem() {
    const userAction = await this.promptForConfirm(
      'Vuoi davvero chiudere il punto?'
    );
    if (userAction?.action === UserConfirmActionType.DENY) {
      return;
    }

    this.meetingSvc
      .closeAgendaItem()
      .pipe(
        take(1),
        catchError(errorHandlingFunction(this.dialog, CloseAgendaItemMap))
      )
      .subscribe(async (res) => {
        // handle results (not gone in error)
        if (res) {
          this.dialog.open(ConfirmationMessageDialogComponent, {
            data: JSON.stringify({
              message: 'Punto chiuso con successo',
              redirectTo: null,
            }),
          });
        }
        await this.refreshStatus();
      });
  }

  async promptForConfirm(
    message: string = "Vuoi davvero confermare l'operazione?"
  ): Promise<{ action: UserConfirmActionType }> {
    return await this.dialog
      .open(ConfirmActionDialogComponent, {
        data: JSON.stringify({ message }),
        autoFocus: false,
        disableClose: true,
      })
      .afterClosed()
      .toPromise();
  }

  async openBallot() {
    const userAction = await this.promptForConfirm(
      'Vuoi davvero avviare la votazione?'
    );
    if (userAction?.action === UserConfirmActionType.DENY) {
      return;
    }

    const _meeting = await this.meeting.toPromise();
    const status = await this.meetingGlobalStatus.toPromise();

    const ballot = _meeting?.agenda.filter(
      (item) => status.agendaItem.item_number === item.item_number
    )[0].ballot;

    if (ballot) {
      this.meetingSvc
        .openBallot(ballot)
        .pipe(
          take(1),
          catchError(errorHandlingFunction(this.dialog, OpenBallotMap))
        )
        .subscribe(async (res) => {
          // handle results (not gone in error)
          if (res) {
            this.dialog.open(ConfirmationMessageDialogComponent, {
              data: JSON.stringify({
                message: 'Votazione aperta con successo',
                redirectTo: null,
              }),
            });
          }
          await this.refreshStatus();
        });
    } else {
      this.dialog.open(ApplicativeErrorDialogComponent, {
        data: JSON.stringify({
          message:
            'Attenzione, non è possibile recuperare la votazione associata al punto aperto.',
          redirectTo: null,
        }),
      });

      await this.refreshStatus();
    }
  }

  async closeBallot() {
    const userAction = await this.promptForConfirm(
      'Vuoi davvero chiudere la votazione?'
    );
    if (userAction?.action === UserConfirmActionType.DENY) {
      return;
    }

    this.meetingSvc
      .closeBallot()
      .pipe(
        take(1),
        catchError(errorHandlingFunction(this.dialog, CloseBallotMap))
      )
      .subscribe(async (res) => {
        // handle results (not gone in error)
        if (res) {
          this.dialog.open(ConfirmationMessageDialogComponent, {
            data: JSON.stringify({
              message: 'Votazione chiusa con successo',
              redirectTo: null,
            }),
          });
        }
        await this.refreshStatus();
      });
  }

  async suspendMeeting() {
    const userAction = await this.promptForConfirm(
      'Vuoi davvero sospendere la seduta?'
    );
    if (userAction?.action === UserConfirmActionType.DENY) {
      return;
    }

    const _meeting = await this.meeting.toPromise();

    if (_meeting && _meeting._id?.$oid) {
      this.meetingSvc
        .suspendMeeting()
        .pipe(
          take(1),
          catchError(errorHandlingFunction(this.dialog, SuspendMeetingMap))
        )
        .subscribe(async (res) => {
          // handle results (not gone in error)
          if (res) {
            this.dialog.open(ConfirmationMessageDialogComponent, {
              data: JSON.stringify({
                message: 'Seduta sospesa con successo',
                redirectTo: null,
              }),
            });
          }
          await this.refreshStatus();
        });
    } else {
      this.openMeetingNotActiveDialog();
    }
  }

  async closeMeeting() {
    const userAction = await this.promptForConfirm(
      'Vuoi davvero chiudere la seduta?'
    );
    if (userAction?.action === UserConfirmActionType.DENY) {
      return;
    }

    const _meeting = await this.meeting.toPromise();

    if (_meeting && _meeting._id?.$oid) {
      this.meetingSvc
        .closeCurrentMeeting()
        .pipe(
          take(1),
          catchError(errorHandlingFunction(this.dialog, CloseMeetingMap))
        )
        .subscribe(async (res) => {
          // handle results (not gone in error)
          if (res) {
            this.dialog.open(ConfirmationMessageDialogComponent, {
              data: JSON.stringify({
                message: 'Seduta chiusa con successo',
                redirectTo: null,
              }),
            });
          }
          await this.refreshStatus();
        });
    } else {
      this.openMeetingNotActiveDialog();
    }
  }

  async resumeMeeting() {
    const userAction = await this.promptForConfirm(
      'Vuoi davvero riprendere la seduta?'
    );
    if (userAction?.action === UserConfirmActionType.DENY) {
      return;
    }

    const _meeting = await this.meeting.toPromise();

    if (_meeting && _meeting._id?.$oid) {
      this.meetingSvc
        .resumeCurrentMeeting()
        .pipe(
          take(1),
          catchError(errorHandlingFunction(this.dialog, ResumeMeetingMap))
        )
        .subscribe(async (res) => {
          // handle results (not gone in error)
          if (res) {
            this.dialog.open(ConfirmationMessageDialogComponent, {
              data: JSON.stringify({
                message: 'Seduta riprensa con successo',
                redirectTo: null,
              }),
            });
          }
          await this.refreshStatus();
        });
    } else {
      this.openMeetingNotActiveDialog();
    }
  }

  async rescheduleMeeting(startDate: Mongo.Date) {
    const _meeting = await this.meeting.toPromise();

    if (_meeting && _meeting._id?.$oid) {
      this.meetingSvc
        .rescheduleCurrentMeeting(startDate)
        .pipe(
          take(1),
          catchError(errorHandlingFunction(this.dialog, RescheduleMeetingMap))
        )
        .subscribe(async (res) => {
          // handle results (not gone in error)
          if (res) {
            this.dialog.open(ConfirmationMessageDialogComponent, {
              data: JSON.stringify({
                message: 'Seduta rinviata a seconda convocazione con successo',
                redirectTo: null,
              }),
            });
          }
          await this.refreshStatus();
        });
    } else {
      this.openMeetingNotActiveDialog();
    }
  }

  async refreshStatus() {
    if (this.globalStatusSub) {
      this.globalStatusSub.unsubscribe();
    }

    this.meetingGlobalStatus = this.meetingSvc.getMeetingStatus();

    this.globalStatusSub = this.meetingGlobalStatus.subscribe(
      (statusResponse) => {
        if (statusResponse.meeting) {
          this.meetingSvc
            .retrieveMeeting(statusResponse.meeting._id.$oid)
            .subscribe((meeting: Meeting | null) => {
              this.meeting = of(meeting);
            });
        } else {
          this.meeting = of(new Meeting());
        }
      }
    );
  }

  async navigateToBallotResults() {
    const meetingGlobalStatus = await this.meetingGlobalStatus.toPromise();
    this.router.navigate(
      [
        'meeting',
        meetingGlobalStatus.meeting._id.$oid,
        'ballot',
        'result',
        meetingGlobalStatus.ballot?.id.$oid,
      ],
      {
        queryParams: { returnTo: '/meeting/management' },
      }
    );
  }
}
