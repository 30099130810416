import { Component, OnInit } from "@angular/core";


@Component({
    selector: 'dcerno-login',
    templateUrl: './dcerno-login.component.html',
    styleUrls: ['./dcerno-login.component.scss']
})
export class DcernoLoginComponent implements OnInit {

    nfread_present = false;
  last_event: any;
  cre='';
    constructor() { }

    ngOnInit(): void {
        console.log('DcernoLoginComponent onInit');
        if ('NDEFReader' in window) { /* Scan and write NFC tags */

            this.nfread_present = true;
            console.log('NDEFReader present');
        }
        const ndef = new NDEFReader();
ndef.scan().then(() => {
  console.log("Scan started successfully.");
  ndef.onreadingerror = () => {
    console.log("Cannot read data from the NFC tag. Try another one?");
  };
  ndef.onreading = event => {
      console.log("NDEF message read.");
      console.log(event);

    const recs = event.message?.records;
    if (recs && recs.length > 0) {
      const rc0 = recs[0];
      if (rc0.recordType === "text" && rc0.data) {
        const textDecoder = new TextDecoder(rc0.encoding);
        const text = textDecoder.decode(rc0.data);
        this.last_event = JSON.stringify(text);
        try {
          const objjson = JSON.parse(text);
          const userid = objjson.userId;
          const cardid = objjson.cardId;
          this.cre = userid + '  :  ' + cardid;
        } catch (error) {
            console.log(error);
        }
      }
    }
  };
}).catch(error => {
  console.log(`Error! Scan failed to start: ${error}.`);
});
    }
}
