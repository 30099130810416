import { Meeting } from 'oa-lib';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

import ElectionSpecification = Meeting.Agenda.Ballot.ElectionSpecification;


@Component({
  selector: 'app-agenda-item-election-by-list-candidates-dialog',
  templateUrl: './agenda-item-election-by-list-candidates-dialog.component.html',
  styleUrls: ['./agenda-item-election-by-list-candidates-dialog.component.scss']
})
export class AgendaItemElectionByListCandidatesDialogComponent implements OnInit {

  choices: (ElectionSpecification.Choice | null)[];

  constructor(
    private dialogRef: MatDialogRef<AgendaItemElectionByListCandidatesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public inputChoices: (ElectionSpecification.Choice | null)[]
  ) { }

  close() {
    this.dialogRef.close();
  }

  confirm() {
    this.choices.push(null);
    this.dialogRef.close(this.choices);
  }

  removeChoice(choice: ElectionSpecification.Choice) {
    this.choices.splice(
      this.choices.indexOf(choice), 1
    );
  }

  isValidChoices() {
    let isValid = true;

    if (this.choices.length === 0) {
      isValid = false;
    }

    for (const choice of this.choices) {
      if (choice !== null && choice.fullName.length === 0) {
        isValid = false;
        break;
      }
    }

    return isValid;
  }

  addChoice() {
    this.choices.push(new ElectionSpecification.Choice('', ''));
  }

  ngOnInit(): void {
    this.choices = [];

    for (const choice of this.inputChoices) {
      if (choice != null) {
        this.choices.push(Object.assign({}, choice));
      }
    }

  }

}
