<div>
  <div class="container">
    <h2 mat-dialog-title class="text-center">Confermi l&apos;eliminazione?</h2>
    <div class="row h-100 justify-content-center align-items-center mt-4">
      <div class="d-flex justify-content-center w-100">
        <button
          (click)="cancel()"
          class="pl-4 pr-4"
          color="primary"
          mat-raised-button
        >
          ANNULLA
        </button>
        <button
        class="pl-4 pr-4 mr-3"
        color="primary"
        mat-raised-button
        (click)="deleteDoc()"
        mat-dialog-close="true"
      >
        ELIMINA
      </button>
      </div>
    </div>
  </div>
</div>
