<div *ngIf="meetingGlobalStatus | async as _status; else loading;"
  class="d-flex flex-column container justify-content-center mt-3 w-100" style="max-width: 1140px">
  <div *ngIf="meeting | async as _meeting; else loading;">
    <div class="meeting-info text-center">
      <h2><strong>Gestione seduta</strong></h2>

      <!-- <p class="text-left">
      Punto 2 - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
      scelerisque egestas accumsan cursus suspendisse velit aenean ut non. Leo
      est diam elementum donec.
    </p> -->
    </div>

    <div class="mt-3 meeting-controls">
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="row">
            <div class="col-12 col-lg-6 mt-2">
              <button mat-raised-button color="primary" [disabled]="_meeting._id != null"
                style="white-space: pre-wrap !important" (click)="openMeeting()">
                APRI SEDUTA
              </button>
            </div>
            <div class="col-12 col-lg-6 mt-2">
              <button *ngIf="!_meeting._id || _meeting.status !== MeetingStatus.SUSPENDED;" [disabled]="!_meeting._id"
                mat-raised-button color="primary" style="white-space: pre-wrap !important" (click)="suspendMeeting()">
                SOSPENDI SEDUTA
              </button>
              <button *ngIf="_meeting.status === MeetingStatus.SUSPENDED;" [disabled]="!_meeting._id" mat-raised-button
                color="primary" style="white-space: pre-wrap !important" (click)="resumeMeeting()">
                RIPRENDI SEDUTA
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="row">
            <div class="col-12 col-lg-6 mt-2">
              <button mat-raised-button color="primary" style="white-space: pre-wrap !important"
                [disabled]="!_meeting._id" (click)="closeMeeting()">
                CHIUDI SEDUTA
              </button>
            </div>
            <div class="col-12 col-lg-6 mt-2">
              <button mat-raised-button color="primary" style="white-space: pre-wrap !important"
                [disabled]="!_meeting._id || _meeting.secondCall !== null" (click)="openRescheduleMeetingDialog()">
                RINVIA A SECONDA CONVOCAZIONE
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4 meeting-info text-center">
        <h3 *ngIf="_meeting._id; else noMeeting" style="font-weight: 500">
          {{ _meeting.title }}
        </h3>
        <ng-template #noMeeting>
          <h3 class="m-0" style="font-weight: 500">
            <strong>Nessuna seduta aperta</strong>
          </h3>
        </ng-template>
      </div>

      <div class="row mt-2 mt-sm-4 align-items-center">
        <div class="col-12 col-lg-3 mt-2 mt-lg-0">
          <div *ngIf="this.meetingGlobalStatus | async as status; else loadingStatus" class="col-12">
            <button mat-flat-button [disabled]="!_meeting._id || isCallActive(status)" [ngClass]="{
              'primary-darker-bg': !isCallActive(status) && _meeting._id,
              'pending-call-btn': isCallActive(status)
            }" color="primary" (click)="showOpenCallDialog()" class="button-xs"
              style="white-space: pre-wrap !important">
              {{ isCallActive(status) ? "APPELLO IN CORSO" : "VERIFICA PRESENZE" }}
            </button>
          </div>
          <ng-template #loadingStatus>
            <div class="col-12">
              <button mat-flat-button disabled color="primary" class="button-xs spinner-btn"></button>
            </div>
          </ng-template>
          <div class="col-12 mt-2">
            <button class="button-xs" [disabled]="!_meeting._id" (click)="navigateToCallsList()" mat-flat-button
              color="primary" style="white-space: pre-wrap !important">
              DASHBOARD APPELLI
            </button>
          </div>
        </div>

        <div class="col-12 col-lg-3 mt-2 mt-lg-0">
          <div class="row justify-content-center pl-3 pr-3 p-lg-0">

            <div class="col-12 ">
              <button (click)="navigateToDocuments()" mat-raised-button [disabled]="!_meeting._id" color="primary"
                style="white-space: pre-wrap !important">
                DOCUMENTI
              </button>
            </div>

          </div>
        </div>

        <div class="col-12 col-lg-3 mt-2 mt-lg-0">

          <div class="col-12 ">
            <button class="button-xs" mat-raised-button [disabled]="!_meeting._id" color="primary"
              style="white-space: pre-wrap !important" (click)="editMeetingAddVote()">
              VOTAZIONE RAPIDA
            </button>
          </div>
          <div class="col-12 mt-2">
            <button class="button-xs" mat-raised-button [disabled]="!_meeting._id" color="primary"
              style="white-space: pre-wrap !important" (click)="editMeeting()">
              MODIFICA SEDUTA
            </button>
          </div>


        </div>

        <div class="col-12 col-lg-3 mt-2 mt-lg-0">
          <div class="col-12 ">


          <span [matTooltip]="
                !_meeting.link || _meeting.link === ''
                ? 'Nessun link disponibile'
                : 'Vai alla videoconferenza'
              ">
            <button class="button-xs" mat-flat-button (click)="openVideoCallTab(_meeting.link)"
              [disabled]="!_meeting._id || !_meeting.link || _meeting.link==='' " color="primary"
              style="white-space: pre-wrap !important">
              VIDEOCONFERENZA
            </button>
          </span>
        </div>
          <div class="col-12 mt-2">
            <button [disabled]="!is_cocon_tenant"  class="button-xs" mat-flat-button (click)="goBadgeEvents()"
            color="primary"
            style="white-space: pre-wrap !important">
            Resoconto Presenze
          </button>
            </div>
        </div>

      </div>
    </div>



    <div class="mt-5 meeting-info text-center">
      <h2><strong>Gestione punti della seduta</strong></h2>
    </div>

    <div class="mt-3 meeting-controls">
      <div class="row">
        <div class="col-12 col-lg-6 mx-auto">
          <div class="row">
            <div class="col-12 col-lg-6 mt-2">
              <button *ngIf="_status.agendaItem === null || !_status.agendaItem; else cancelAgenda" mat-raised-button
                (click)="openAgendaItem()" color="primary" style="white-space: pre-wrap !important"
                [disabled]="!_meeting._id || _status.agendaItem != null">
                SELEZIONA PUNTO DA AVVIARE
              </button>
              <ng-template #cancelAgenda>
                <button mat-raised-button color="primary" style="white-space: pre-wrap !important"
                  (click)="cancelAgendaItem()">
                  ANNULLA APERTURA PUNTO
                </button>
              </ng-template>
            </div>
            <div class="col-12 col-lg-6 mt-2">
              <button mat-raised-button color="primary" (click)="closeAgendaItem()"
                style="white-space: pre-wrap !important" [disabled]="!_meeting._id || _status.agendaItem == null">
                CHIUDI PUNTO
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3 meeting-info text-center">
      <h3 *ngIf="_status.agendaItem != null; else noAgendaItem" style="font-weight: 500">
        <strong>In discussione:</strong> <br />
        Punto {{_status.agendaItem.item_number}}: {{_status.agendaItem.title}}
      </h3>

      <ng-template #noAgendaItem>
        <h3 class="m-0" style="font-weight: 500">
          <strong>Nessun punto aperto</strong>
        </h3>
      </ng-template>

    </div>

    <div class="mt-1 meeting-controls">
      <div class="row">
        <div class="col-12 col-lg-8 mx-auto">
          <div class="row">
            <div class="col-12 col-lg-4 mt-2">
              <button (click)="openBallot()" mat-raised-button color="primary" style="white-space: pre-wrap !important"
                [disabled]="!_meeting._id || _status.agendaItem == null || _status.ballot == null || _status.ballot.status !== BallotStatus.SCHEDULED">
                APRI VOTAZIONE
              </button>
            </div>
            <div class="col-12 col-lg-4 mt-2">
              <button mat-raised-button (click)="closeBallot()" color="primary" style="white-space: pre-wrap !important"
                [disabled]="!_meeting._id || _status.agendaItem == null || _status.ballot == null  || _status.ballot.status !== BallotStatus.OPEN">
                CHIUDI VOTAZIONE
              </button>
            </div>
            <div class="col-12 col-lg-4 mt-2">
              <button mat-raised-button (click)="navigateToBallotResults()" color="primary"
                style="white-space: pre-wrap !important"
                [disabled]="!_meeting._id || _status.agendaItem == null || _status.ballot == null  || _status.ballot.status === BallotStatus.SCHEDULED">
                RISULTATI VOTAZIONE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <synoptic [meeting]="meeting"></synoptic> -->

    <div class="mt-5 meeting-info w-100 text-center">
      <h3 style="font-weight: 500">
        <strong>Gestione richieste di intervento</strong>
      </h3>
    </div>

    <synoptic-view *ngIf="synoptic_enabled" mode_enabled="ballot"></synoptic-view>

    <app-meeting-speech-management></app-meeting-speech-management>

  </div>
</div>
<ng-template #loading>
  <div class="d-flex w-100 p-5 align-content-center justify-content-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>