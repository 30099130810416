import {
  ApplicativeError,
  MatSnackBarDirective,
  MeetingState,
  Meeting,
  Mongo,
  AuthState,
  errorHandlingFunction,
  BallotService,
  ChangeSelectionDialogComponent
} from 'oa-lib';


import {
  MatListOption,
  MatSelectionList,
  MatSelectionListChange,
} from '@angular/material/list';

import { MatHorizontalStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { select } from '@angular-redux/store';
import { interval, Observable, of, Subscription } from 'rxjs';
import { Component, OnInit, ViewChild, OnDestroy, Injector } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { take, catchError } from 'rxjs/operators';
import * as momentJS from 'moment';

import RequestBallotOtpMessageMap = ApplicativeError.RequestBallotOtpMessageMap;
import VoteMessageMap = ApplicativeError.VoteMessageMap;

const moment = (momentJS as any).default ? (momentJS as any).default : momentJS;

@Component({
  selector: 'app-election-ballot',
  templateUrl: './election-ballot.component.html',
  styleUrls: ['./election-ballot.component.scss'],
})
export class ElectionBallotComponent
  extends MatSnackBarDirective
  implements OnInit, OnDestroy {
  @select('meeting') meeting: Observable<MeetingState>;
  @select('auth') auth: Observable<AuthState>;
  @ViewChild('horizontalStepper', { static: false })
  stepper: MatHorizontalStepper;

  ballot: Observable<Meeting.Agenda.ElectionBallot>;
  counterSub: Subscription;
  otp = '';

  otpCounter = 0;
  counterText: string;

  DESCRIPTION_LENGTH = 200;
  descriptionCollapsed = true;

  selections: {
    spec: Meeting.Agenda.Ballot.ElectionSpecification;
    selection: (number | null)[] | null;
  }[] = [];

  constructor(
    private router: Router,
    private ballotSvc: BallotService,
    private dialog: MatDialog,
    protected inj: Injector,
  ) {
    super(inj);
  }

  ngOnInit(): void {
    this.counterSub = interval(1000).subscribe(() => {
      this.counterText = this.getWaitText(this.otpCounter);
    });
    this.selections = [];

    this.meeting.pipe(take(1)).subscribe((meetingState: MeetingState) => {
      const selectedBallot = meetingState.selectedBallot as Meeting.Agenda.ElectionBallot;

      if (selectedBallot) {
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < selectedBallot.specs.length; i++) {
          this.selections.push({
            spec: selectedBallot.specs[i],
            selection: null,
          });
        }

        this.ballot = of(selectedBallot);
      } else {
        this.router.navigate(['']);
      }
    });
  }

  timestampToLongDate(period: string) {
    moment.locale('it');
    return moment(new Date(period)).unix() * 1000;
  }

  getCurrentTimeslot(ballot: Meeting.Agenda.Ballot) {
    const periods = ballot.periods;
    const now = new Date().getTime();

    for (const period of periods) {
      const opened = this.timestampToLongDate(period.o);
      const closed = this.timestampToLongDate(period.c);

      if (now > opened && now < closed) {
        return period;
      }
    }

    return null;
  }
  ngOnDestroy() {
    this.counterSub.unsubscribe();
  }

  next(
    index: number,
    selection: SelectionModel<MatListOption>,
    spec: Meeting.Agenda.Ballot.ElectionSpecification
  ): void {
    this.selections[index].selection = selection.selected.map((option) =>
      option.value !== null ? spec.choices.indexOf(option.value) : null
    );
    this.stepper.next();
  }

  checkSelection(
    $event: MatSelectionListChange,
    spec: Meeting.Agenda.Ballot.ElectionSpecification,
    selectionList: MatSelectionList
  ) {
    const selectedValue = $event.options[0].value;
    const whiteCard = selectionList.options.filter(
      (option) => option.value === null
    )[0];

    if (selectedValue) {
      selectionList.selectedOptions.deselect(whiteCard);
    } else {
      selectionList.deselectAll();
      selectionList.selectedOptions.select(whiteCard);
    }

    const selected = selectionList.selectedOptions.selected;

    if (selected.length > spec.max || selected.length < spec.min) {
      this.dialog.open(ChangeSelectionDialogComponent, {
        data: {
          spec,
          maxLimitError: selected.length > spec.max,
          minLimitError: selected.length < spec.min,
        },
        panelClass: 'error-panel',
        disableClose: true,
      });
    }
  }

  confirmData(): void {
    // here request otp logic request.
    this.ballot.pipe(take(1))
      .subscribe(ballot => {
        if (ballot.id) {
          this.ballotSvc.requestVoteAuthorization(ballot.id)
            .pipe(
              take(1),
              catchError(
                errorHandlingFunction(this.dialog, RequestBallotOtpMessageMap)
              ))
            .subscribe(res => {
              // handle results (not gone in error)
              if (res) {
                this.resetOtpCounter();
                this.stepper.next();
              }
            });
        } else {
          this.openSnackbar('Oops! Non è stato possibile ottenere il riferimento alla votazione. Contatta l\'assistenza.');
          this.router.navigate(['']);
        }
      });
  }

  askForOtp() {
    this.ballot.pipe(take(1))
      .subscribe(ballot => {
        if (ballot.id) {
          this.ballotSvc.requestVoteAuthorization(ballot.id).pipe(take(1))
            .pipe(
              take(1),
              catchError(
                errorHandlingFunction(this.dialog, RequestBallotOtpMessageMap)
              ))
            .subscribe(res => {
              // handle results (not gone in error)
              if (res) {
                this.resetOtpCounter();
              }
            });
        } else {
          this.openSnackbar('Oops! Non è stato possibile ottenere il riferimento alla votazione. Contatta l\'assistenza.');
          this.router.navigate(['']);
        }
      });
  }

  getObjectIdFromResponseHeader(locationHeader: string): Mongo.ObjectId {
    return new Mongo.ObjectId(
      locationHeader.substr(locationHeader.lastIndexOf('/') + 1)
    );
  }

  submitVote(): void {
    const choice = this.selections.map((elem, index) => ({
      sectionIdx: index,
      choices: elem.selection,
    }));

    const vote = {
      choice,
      otp: this.otp,
    };

    this.ballot.pipe(take(1))
      .subscribe(ballot => {

        if (ballot.id) {
          this.ballotSvc.submitVote(ballot.id, vote)
            .pipe(
              take(1),
              catchError(
                errorHandlingFunction(this.dialog, VoteMessageMap)
              ))
            .subscribe(res => {
              if (res) {
                const location = res.headers.get('Location');
                if (location) {
                  const _id = this.getObjectIdFromResponseHeader(location);
                  this.router.navigate(['meeting', 'receipt', _id.$oid]);
                } else {
                  this.openSnackbar('Oops! Non è stato restituito il riferimento al voto effettuato. Contatta l\'assistenza.');
                }
              }
            });
        } else {
          this.openSnackbar('Oops! Non è stato possibile ottenere il riferimento alla votazione. Contatta l\'assistenza.');
          this.router.navigate(['']);
        }

      });
  }

  previous(): void {
    this.stepper.previous();
  }

  resetOtpCounter() {
    const now = new Date().getTime();
    this.otpCounter = now + 60000;
  }

  getWaitText(counter: number) {
    const now = new Date().getTime();
    const delta = counter - now;
    const waitTime = new Date(delta);
    const minutes = waitTime.getMinutes();
    const seconds = waitTime.getSeconds();

    let waitText = 'Devi aspettare ';
    waitText += minutes + ':';
    waitText += String(seconds).length > 1 ? seconds : '0' + seconds;
    waitText += ' prima di richiedere un nuovo codice.';

    return waitText;
  }

  canRequestOtp() {
    const now = new Date().getTime();
    return this.otpCounter < now;
  }
}
