import { AbstractControl, FormArray, ValidatorFn } from '@angular/forms';
// eslint-disable-next-line max-len
import { AgendaItemElectionByListCandidatesDialogComponent } from './agenda-item-election-by-list-candidates-dialog/agenda-item-election-by-list-candidates-dialog.component';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Meeting, TenantService } from 'oa-lib';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MatRadioChange } from '@angular/material/radio';
import { MatDialog } from '@angular/material/dialog';

import Item = Meeting.Agenda.Item;
import Agenda = Meeting.Agenda;
import AgendaItemSpeechLimit = Meeting.Agenda.AgendaItemSpeechLimit;
import { min } from 'moment';
import { PolGroup, PolGroupService } from 'src/app/services/polgroup.service';

@Component({
  selector: 'app-agenda-item-editor-dialog',
  templateUrl: './agenda-item-editor-dialog.component.html',
  styleUrls: ['./agenda-item-editor-dialog.component.scss']
})
export class AgendaItemEditorDialogComponent implements OnInit {
  BallotType = Meeting.Agenda.Ballot.TYPE;
  Majority = Meeting.Agenda.Ballot.VoteSpecification.Majority;

  itemFormGroup: FormGroup;
  hasBallotControl: FormControl;
  durationFormControl: FormControl;
  choiceRangeFromGroup: FormGroup;
  majorityFromGroup: FormGroup;

  speechLimitFG: FormGroup;

  is_political_group_enabled_for_tenant = false;
  pol_groups: PolGroup[] = [];
  item: Item;
  itemRootOptions: string[];
  ballot: Meeting.Agenda.Ballot | null;

  political_group = '';
  constructor(
    private dialogRef: MatDialogRef<AgendaItemEditorDialogComponent>,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private tenantService: TenantService,
    private polGroupService: PolGroupService,
    @Inject(MAT_DIALOG_DATA) public data: { selectedItem: Item; items: Item[]; creating: boolean },
  ) {

    this.speechLimitFG = this.fb.group({
      speechLimitArray: this.fb.array([]),
    });
  }

  get speechlimits_array(): FormArray {
    return this.speechLimitFG.get("speechLimitArray") as FormArray;
  }
  ngOnInit(): void {
    console.log('[ngOnInit]: ', this.data);

    this.itemRootOptions = this.getItemRootOptions();
    this.item = Object.assign({}, this.data.selectedItem);
    this.itemFormGroup = this.fb.group(
      {
        title: [this.item.title, Validators.required],
        description: [this.item.description],
        parent: [this.item.parent],
      }
    );

    this.initBallotFields();
    this.init_political_group();
    console.log('[ngOnInit]: ', this.item);
    console.log('[ngOnInit]: ', this.itemFormGroup);
  }

  async init_political_group() {
    const tenant = await this.tenantService.getTenant().toPromise();
    if (tenant.body?.pol_group_enabled) {
      this.is_political_group_enabled_for_tenant = true;
      console.log('[init_political_group]: true' );
    }
    else {
      return;
    }
    const polgroups = await this.polGroupService.getPolGroupsOfTenant().toPromise();
    this.pol_groups = polgroups.body;
    console.log('[init_political_group]: ', this.pol_groups);

    this.speechlimits_array.clear();
    for (const polgroup of this.pol_groups) {
      if (polgroup.hidden) {
        continue;
      };
      const limit_sec = this.getLimitSpeechInSecondsFromLoadedItemById(polgroup._id);
      let limit_str_for_control: any = null;
      if (limit_sec != null && limit_sec > 0) {
        limit_str_for_control=this.getDurationString(limit_sec);
      }

        this.speechlimits_array.push(this.fb.group({
          pgId:[polgroup._id],
          pgName: [polgroup.name],
          limit: [limit_str_for_control]
        }));
      }

    }

  getLimitSpeechInSecondsFromLoadedItemById(polgroupId: string) {
    if (polgroupId == null) {return null;} //it checks both null and undefined
    if (this.item.speech_limits == null || this.item.speech_limits.length <= 0) {return null;}
    for (const limit of this.item.speech_limits) {
      if (limit.pol_group_id == polgroupId) {
        return limit.limit_seconds;
      }
    }
    return null;
  }
  initBallotFields() {
    const hasBallot = this.item.ballot != null;

    this.hasBallotControl = new FormControl();

    this.durationFormControl = new FormControl(null, {
      updateOn: 'blur'
    });

    this.majorityFromGroup = this.fb.group(
      {
        fraction: [''],
        of: ['']
      }
    );

    this.choiceRangeFromGroup = this.fb.group(
      {
        min: [0],
        max: [0]
      }
    );

    this.hasBallotControl.setValue(hasBallot);

    if (!hasBallot) {
      this.durationFormControl.disable();
    } else {
      this.ballot = this.item.ballot;
      this.durationFormControl.setValue(this.getDurationString());
      this.durationFormControl.setValidators(Validators.required);
      this.durationFormControl.updateValueAndValidity();
      switch (this.ballot?.type) {
        case this.BallotType.OPEN_VOTING_PROCEDURE: {
          this.enableMajorifyFromGroup();
          this.majorityFromGroup.get('fraction')?.setValue(this.ballot.specs[0].majority?.fraction);
          this.majorityFromGroup.get('of')?.setValue(this.ballot.specs[0].majority?.of);
          break;
        }
        case this.BallotType.SECRET_VOTING_PROCEDURE: {
          this.enableMajorifyFromGroup();
          this.majorityFromGroup.get('fraction')?.setValue(this.ballot.specs[0].majority?.fraction);
          this.majorityFromGroup.get('of')?.setValue(this.ballot.specs[0].majority?.of);
          break;
        }
        case this.BallotType.ELECTION_BY_LIST: {
          this.enableChoiceFormGroup();
          this.choiceRangeFromGroup.get('min')?.setValue(this.ballot.specs[0].min);
          this.choiceRangeFromGroup.get('max')?.setValue(this.ballot.specs[0].max);
          break;
        }
      }
    }

  }

  getDurationString(duration_in_seconds: number | undefined = undefined): string {

    let duration = this.ballot?.specs[0].duration;

    if (duration_in_seconds != undefined) {
      duration = duration_in_seconds;
    }

    if (duration) {
      const hours = this.getDurationHours(duration);
      const minutes = this.getDurationMinutes(duration);
      const seconds = duration - ((hours * 60 * 60) + (minutes * 60));

      const dsHours = this.fillDurationStringComponent(String(hours));
      const dsMinutes = this.fillDurationStringComponent(String(minutes));
      const dsSeconds = this.fillDurationStringComponent(String(seconds));

      return dsHours + ':' + dsMinutes + ':' + dsSeconds;
    }

    return '00:01:00';
  }

  fillDurationStringComponent(component: string) {
    let result = component;

    while (result.length < 2) {
      result = '0' + result;
    }

    return result;
  }

  getDurationHours(seconds: number) {
    const hours =
      seconds - Math.floor(seconds / (60 * 60 * 24)) * (60 * 60 * 24);
    return Math.floor(hours / (60 * 60));
  }

  getDurationMinutes(seconds: number) {
    const minutes =
      seconds - Math.floor(seconds / (60 * 60)) * (60 * 60);
    return Math.floor(minutes / (60));

  }

  getDuration(value: string|undefined=undefined) {

    let durationSpliced: string[] = [];
    if (value) {
      durationSpliced = value.split(':');
    }
    else {

      durationSpliced = this.durationFormControl.value.split(':');
    }

    let duration = 0;

    duration = duration + Number(durationSpliced[0]) * 60 * 60;
    duration = duration + Number(durationSpliced[1]) * 60;
    duration = duration + Number(durationSpliced[2]);

    return duration;
  }

  hasBallotChanged($event: MatRadioChange) {
    if ($event.value) {
      this.durationFormControl.enable();
      this.durationFormControl.setValidators(Validators.required);
      this.durationFormControl.updateValueAndValidity();
    } else {
      this.ballot = null;
      this.durationFormControl.reset();
      this.durationFormControl.disable();
      this.durationFormControl.clearValidators();
      this.durationFormControl.updateValueAndValidity();
    }

    this.cd.detectChanges();
  }

  ballotTypeChanged($event: MatSelectChange) {
    const ballotType = $event.value;

    this.clearControls();

    switch (ballotType) {
      case null: {
        this.ballot = null;
        break;
      }
      case this.BallotType.OPEN_VOTING_PROCEDURE: {
        this.ballot = new Agenda.OpenVotingBallot();
        this.enableMajorifyFromGroup();
        break;
      }
      case this.BallotType.SECRET_VOTING_PROCEDURE: {
        this.ballot = new Agenda.SecretVotingBallot();
        this.enableMajorifyFromGroup();
        break;
      }
      case this.BallotType.ELECTION_BY_COMPILATION: {
        this.ballot = new Agenda.ElectionByCompilationBallot();
        break;
      }
      case this.BallotType.ELECTION_BY_LIST: {
        this.ballot = new Agenda.ElectionByListBallot();
        this.enableChoiceFormGroup();
        break;
      }
    }
  }

  clearControls(): void {
    const choiceKeys = Object.keys(this.choiceRangeFromGroup.getRawValue());

    this.choiceRangeFromGroup.clearValidators();

    for (const key of choiceKeys) {
      this.choiceRangeFromGroup.get(key)?.clearValidators();
      this.choiceRangeFromGroup.get(key)?.setValue(0);
      this.choiceRangeFromGroup.get(key)?.disable();
    }

    this.choiceRangeFromGroup.updateValueAndValidity();

    const majorityKeys = Object.keys(this.majorityFromGroup.getRawValue());

    for (const key of majorityKeys) {
      this.majorityFromGroup.get(key)?.clearValidators();
      this.majorityFromGroup.get(key)?.setValue('');
      this.majorityFromGroup.get(key)?.disable();
    }

    this.majorityFromGroup.updateValueAndValidity();
  }

  enableMajorifyFromGroup() {
    const majorityKeys = Object.keys(this.majorityFromGroup.getRawValue());

    for (const key of majorityKeys) {
      this.majorityFromGroup.get(key)?.enable();
      this.majorityFromGroup.get(key)?.setValidators(Validators.required);
    }

    this.majorityFromGroup.updateValueAndValidity();
    this.cd.detectChanges();
  }

  enableChoiceFormGroup() {
    this.choiceRangeFromGroup.setValidators(
      electionByListChoiceRangeValidator(
        this.ballot as Agenda.ElectionByListBallot
      ));

    const choiceKeys = Object.keys(this.choiceRangeFromGroup.getRawValue());

    for (const key of choiceKeys) {
      this.choiceRangeFromGroup.get(key)?.enable();
      this.choiceRangeFromGroup.get(key)?.setValidators(Validators.required);
    }

    this.choiceRangeFromGroup.updateValueAndValidity();
  }

  defineElectionByListChoices() {
    const dialogRef = this.dialog.open(AgendaItemElectionByListCandidatesDialogComponent, {
      data: (this.ballot as Agenda.ElectionByListBallot).specs[0].choices
    });

    dialogRef.afterClosed().subscribe(
      choices => {
        if (choices) {
          (this.ballot as Agenda.ElectionByListBallot).specs[0].choices = choices;
        }
      }
    );
  }

  getItemRootOptions() {
    return this.data.items
      .map(item => item.item_number)
      .filter(itemNumber => itemNumber !== this.data.selectedItem.item_number)
      .filter(itemNumber => {
        if (itemNumber.length > this.data.selectedItem.item_number.length) {
          return itemNumber.substr(0, this.data.selectedItem.item_number.length)
            !== this.data.selectedItem.item_number;
        } else {
          return true;
        }
      });
  }

  confirm() {
    this.dialogRef.close(
      this.getItem()
    );
  }

  getItem(): Item {
    const item = Object.assign(this.item, this.itemFormGroup.getRawValue()) as Item;
    if (this.ballot) {
      this.ballot.specs[0].duration = this.getDuration();
      switch (this.ballot.type) {
        case this.BallotType.OPEN_VOTING_PROCEDURE:
        case this.BallotType.SECRET_VOTING_PROCEDURE: {
          this.ballot.specs[0].majority
            = this.majorityFromGroup.getRawValue() as Agenda.Ballot.VoteSpecification.Majority;
          break;
        }
        case this.BallotType.ELECTION_BY_LIST: {
          Object.assign(this.ballot.specs[0],
            this.choiceRangeFromGroup.getRawValue());
          break;
        }
      }

      item.ballot = this.ballot;
      console.log('[getItem()]: ', item);
    } else {
      item.ballot = null;
    }
    //set all the speech limit in the item.
    const array_speech_limit: AgendaItemSpeechLimit[] = [];
    for (let i = 0; i < this.speechlimits_array.length; i++) {
      const formgroup = this.speechlimits_array.at(i) as FormGroup;
      const pgId = formgroup.get('pgId')?.value;
      const limit_str = formgroup.get('limit')?.value;

      if(pgId == null || pgId == undefined || pgId == ""){
        console.error("political gorup error pgId is null or undefined or empty");
        continue;
      }
      if(limit_str == null || limit_str == undefined || limit_str == "" || limit_str == "00:00:00"){
        continue;
      }
      const limit_seconds=this.getDuration(limit_str);
      if (limit_seconds == null || limit_seconds <= 0) {
        continue;
      }
      array_speech_limit.push({pol_group_id:pgId, limit_seconds: limit_seconds});

    }
    item.speech_limits = array_speech_limit;
    return item;
  }
  close() {
    this.dialogRef.close();
  }
}

export const electionByListChoiceRangeValidator = (ballot: Agenda.ElectionByListBallot): ValidatorFn =>
  (formGroup: AbstractControl): { [key: string]: any } | null => {
    const errors: any = {};
    const minControl = formGroup.get('min');
    const maxControl = formGroup.get('max');

    if (minControl && maxControl) {
      minControl.setErrors(null);
      maxControl.setErrors(null);
      const minVal = Number(minControl.value);
      const maxVal = Number(maxControl.value);

      if (maxVal === 0) {
        errors.maxNull = true;
      }

      if (minVal < 0) {
        errors.minNegative = true;
      }

      if (maxVal < 0) {
        errors.maxNegative = true;
      }

      if (maxVal > ballot.specs[0].choices.length) {
        errors.maxRangeExcedeed = true;
      }

      if (minVal > maxVal) {
        errors.minRangeExcedeed = true;
      }

      const errorKeys = Object.keys(errors);
      if (errorKeys.length > 0) {

        const minErrors: any = {};
        const maxErrors: any = {};

        for (const key of errorKeys) {
          if (key.indexOf('min') > -1) {
            minErrors[key] = errors[key];
          }

          if (key.indexOf('max') > -1) {
            maxErrors[key] = errors[key];
          }
        }

        minControl.setErrors(Object.keys(minErrors).length > 0 ? minErrors : null);
        maxControl.setErrors(Object.keys(maxErrors).length > 0 ? maxErrors : null);
        return errors;
      }
    } else {
      errors.rangeValidatorMisconfiguration = true;
      return errors;
    }

    return null;
  };

