import { interval, Subscription } from 'rxjs';
import { OnInit, OnDestroy } from '@angular/core';
/* eslint-disable no-underscore-dangle */
import { Component } from '@angular/core';

declare let document: any;
@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
    checkSnackbarSub: Subscription;

    constructor() { }
    ngOnInit() {
        this.checkSnackbarSub = interval(100).subscribe(
            () => {
                this.checkNotificationSnackbar();
            }
        );
    }

    checkNotificationSnackbar() {

        const snackbarElem = document.getElementsByClassName('notifier-snackbar');
        if (snackbarElem.length > 0) {
            const elem = snackbarElem[0] as HTMLElement;
            document.getElementById('snackbar-offset').style.height = elem.offsetHeight * 1.5 + 'px';
            document.getElementById('snackbar-offset').style.width = elem.offsetWidth * 1.5 + 'px';

        } else {
            document.getElementById('snackbar-offset').style.height = '0px';
        }

    }

    ngOnDestroy() {
        this.checkSnackbarSub?.unsubscribe();
    }
}
