<!-- Mobile menu -->
<app-mobile-menu
  *ngIf="mobileQuery.matches && !printQuery.matches; else desktopMenu"
  [isVerifying]="isVerifying()"
  [isVoter]="isVoter()"
  [isManager]="isManager()"
  [isGuest]="isGuest()"
  [isSecretary]="isSecretary()"
  [currentRoute]="currentRoute"
  (logoutEvent)="logout()"
  (navigateToCalendarEvent)="navigateToCalendar()"
  (navigateToManagementEvent)="navigateToManagement()"
  (navigateToUserProfileEvent)="navigateToUserProfile()"
  (navigateToCurrentMeetingEvent)="navigateToCurrentMeeting()"
  (navigateToCardsEvent)="navigateToCards()"
>
  <router-outlet></router-outlet>
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
   <!-- Select mobile only content -->
   <ng-content select=[mobile]></ng-content>
</app-mobile-menu>

<!-- Desktop menu -->
<ng-template #desktopMenu>
  <app-desktop-menu
    [isIntoUserProfile]="isIntoUserProfile()"
    [isVoter]="isVoter()"
    [isManager]="isManager()"
    [isGuest]="isGuest()"
    [isSecretary]="isSecretary()"
    [isSynopticManager]="isSynopticManager()"
    [currentRoute]="currentRoute"
    [isCardManager]="isCardManager()"
    [canAccessUserProfile]="canAccessUserProfile()"
    (logoutEvent)="logout()"
    (navigateToCalendarEvent)="navigateToCalendar()"
    (navigateToManagementEvent)="navigateToManagement()"
    (navigateToUserProfileEvent)="navigateToUserProfile()"
    (navigateToCurrentMeetingEvent)="navigateToCurrentMeeting()"
    (navigateToCardsEvent)="navigateToCards()"
    (navigateToSynopticEvent)="navigateToSynopticManager()"
    (navigateToViewerEvent)="navigateToViewer()"
  >
    <router-outlet></router-outlet>
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
    <!-- Select desktop only content -->
    <ng-content select=[desktop]></ng-content>
  </app-desktop-menu>
</ng-template>

<ng-template #contentTpl><ng-content></ng-content></ng-template>
