import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-meeting-open-call-dialog',
  templateUrl: './meeting-open-call-dialog.component.html',
  styleUrls: ['./meeting-open-call-dialog.component.scss'],
})
export class MeetingOpenCallDialogComponent implements OnInit {
  duration: any = '05:00';

  constructor(public dialogRef: MatDialogRef<MeetingOpenCallDialogComponent>) {}

  ngOnInit(): void {}

  handleSubmit() {
    if (this.duration) {
      this.dialogRef.close({ duration: this.duration });
    }
  }
}
