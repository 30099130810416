<div
  class="d-flex flex-column container justify-content-center mt-3 w-100"
  style="max-width: 1140px"
>
  <div *ngIf="showErrorInit" class="mt-4 meeting-info text-center w-100">
    <h3 class="m-0" style="font-weight: 500">
      <strong>Errore inizializzazione del calcolo presenze</strong>
    </h3>
  </div>

  <ng-container *ngIf="!showErrorInit">
    <div class="mt-4 meeting-info text-center w-100">
      <h3 class="m-0" style="font-weight: 500">
        <strong>Resoconto presenze per meeting</strong>
      </h3>
    </div>
    <div class="mt-4 mb-4 meeting-info text-center w-100">
      <h2 class="m-0" style="font-weight: 500">
        <strong>{{ title }}</strong>
      </h2>
    </div>
    <div class="row w-100">
      <div class="col-12 col-lg-6 mt-2">
        <h4>Inizio seduta :</h4>
      </div>
      <div class="row w-50">
        <div class="col-12 col-lg-6 mt-2">
          <p>{{ label_inizio }}</p>
        </div>
      </div>
    </div>
    <div class="row w-100">
      <div class="col-12 col-lg-6 mt-2">
        <h4>Fine seduta :</h4>
      </div>
      <div class="row w-50">
        <div class="col-12 col-lg-6 mt-2">
          <p>{{ label_fine }}</p>
        </div>
      </div>
    </div>
    <div class="row w-100">
      <div class="col-12 col-lg-6 mt-2">
        <h4>Durata totale seduta (incluse sospensioni) :</h4>
      </div>
      <div class="row w-50">
        <div class="col-12 col-lg-6 mt-2">
          <p>{{ label_duration_incluso_sospensioni }}</p>
        </div>
      </div>
    </div>
    <div class="row w-100">
      <div class="col-12 col-lg-6 mt-2">
        <h4>Durata totale sospensioni :</h4>
      </div>
      <div class="row w-50">
        <div class="col-12 col-lg-6 mt-2">
          <p>{{ label_duration_sospensioni }}</p>
        </div>
      </div>
    </div>
    <div class="row w-100">
      <div class="col-12 col-lg-6 mt-2">
        <h4>Durata effettiva :</h4>
      </div>
      <div class="row w-50">
        <div class="col-12 col-lg-6 mt-2">
          <p>{{ label_duration_effettiva }}</p>
        </div>
      </div>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Nome</th>
          <th scope="col">Partceipazione effettiva</th>
          <th scope="col">Percentuale</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let part of tableData; index as i">
          <th scope="row">{{ i + 1 }}</th>
          <td><p class="badge-dele-name" (click)="gotoDelegate(part)">{{ part.name }}</p></td>
          <td>{{ part.labelduration }}</td>
          <td>{{ part.labelpercentage }}</td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</div>
