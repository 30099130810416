<div class="pr-5 pl-5" matDialogContent>

    <div class="w-100 text-center justify-content-center">
        <h3><strong>Definizione candidati per elezione da lista</strong></h3>
    </div>

    <div class="d-flex justify-content-center w-100 pb-4">
        <button (click)="addChoice()" color="primary" mat-raised-button>NUOVO CANDIDATO</button>
    </div>


    <span *ngFor="let choice of choices">
        <div *ngIf="choice != null" class="row">
            <div class="col-11">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Candidato</mat-label>
                    <input [(ngModel)]="choice.fullName" matInput required>
                    <mat-error *ngIf="choice.fullName.length === 0">Campo obbligatorio</mat-error>
                </mat-form-field>
            </div>

            <div class="col-1">
                <button (click)="removeChoice(choice)" color="primary" mat-icon-button>
                    <mat-icon>delete</mat-icon>
                </button>
            </div>

            <div class="offset-1 col-10">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Descrizione</mat-label>
                    <input [(ngModel)]="choice.desc" matInput>
                </mat-form-field>
            </div>
        </div>
    </span>

    <div class="col-1"></div>

    <div class="d-flex w-100 mt-2 justify-content-center">
        <button type="button" mat-button color="warn" (click)="close()">Annulla</button>
        <button type="button" [disabled]="!isValidChoices()" mat-button color="warn" (click)="confirm()">Conferma</button>
    </div>
</div>