<span *ngIf="init; else loading">
    <div class="text-center w-100 mt-4 mb-3">
        <h3><strong>{{creating ? 'CREAZIONE NUOVA SEDUTA' : 'MODIFICA SEDUTA'}}</strong></h3>
        <h4>Informazioni generali</h4>
    </div>

    <form class="w-100" [formGroup]="generalInfosFormGroup">
        <div class="w-100 d-flex justify-content-center">
            <div class="form-container w-100">

                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Titolo della seduta</mat-label>
                            <input matInput formControlName="title" required>
                        </mat-form-field>
                    </div>

                    <div class="col-12">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Ordine del giorno</mat-label>
                            <textarea matInput formControlName="description"></textarea>
                        </mat-form-field>
                    </div>

                    <div class="col-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Data e ora seduta {{isSecondCall ? '(2a)': '(1a)'}}</mat-label>
                            <input matInput type="text" [dropSpecialCharacters]="false" [clearIfNotMatch]="true"
                                [showMaskTyped]="true" mask="00/00/0000 - 00:00" formControlName="startDate">
                            <mat-error *ngIf="generalInfosFormGroup.get('startDate')?.invalid">
                                {{getErrorMessage(generalInfosFormGroup.get('startDate')) }}</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Link alla videoconferenza</mat-label>
                            <input matInput formControlName="link">
                        </mat-form-field>
                    </div>
                </div>


            </div>
        </div>

        <div class="text-center w-100 mt-4 mb-3">
            <h4>Punti all'OdG</h4>
        </div>

        <div class="d-flex justify-content-center w-100">
            <button (click)="addNewEntry()" matTooltip="Crea nuovo punto dell'ordine giorno" class="pl-4 pr-4"
                mat-raised-button color="primary">+</button>
        </div>

        <div *ngIf="meeting | async as _meeting" class="w-100 d-flex justify-content-center">
            <div *ngIf="items | async as _items" class="form-container w-100">
                <app-agenda-item class="mb-2" (editItem)="editItem($event)" (deleteItem)="deleteItem($event)"
                    [secondCall]="_meeting.secondCall !== null" [item]="item" *ngFor="let item of _items"></app-agenda-item>
            </div>
        </div>

        <div class="text-center w-100 mt-4 mb-3">
            <h4 class="m-0">Partecipanti</h4>
            <h5 class="m-0">Seleziona il consiglio comunale o la/le commissioni che dovranno partecipare all’evento.
            </h5>
            <h5>Deseleziona i consiglieri congedati e riporta sotto i loro nomi.</h5>
        </div>

        <div class="w-100 d-flex justify-content-center">

            <div *ngIf="groups" class="row">
                <mat-form-field appearance="outline">
                    <mat-select #groupSelect [value]="groupsSelected" (selectionChange)="groupSelected(groupSelect)"
                        multiple defaultTabIndex=1>
                        <mat-option *ngFor="let group of groups" [value]="group" selected>
                            {{group.name | titlecase}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>


        </div>

        <div *ngIf="attendees | async as _attendees" class="d-flex justify-content-center w-100">
            <div class="form-container w-100">
                <div *ngIf="availableAttendees | async as _availableAttendees" class="row">
                    <div class="col-6 col-lg-3 p-3" *ngFor="let attendee of _availableAttendees">
                        <mat-checkbox (change)="attendeeChecked($event, attendee)"
                            [checked]="_attendees.includes(attendee)">
                            {{attendee.name | titlecase}}</mat-checkbox>
                    </div>
                </div>
            </div>

        </div>

        <div *ngIf="attendees | async as _attendees" class="row">
            <div class="w-100">
                <div class="text-center">
                    <h4>Totale invitati alla seduta: <strong class="accent-color">{{_attendees.length}}</strong></h4>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-center w-100">
            <div class="form-container pb-0 pt-0 w-100">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Note</mat-label>
                    <textarea
                        placeholder="Inserisci qui alcune informazioni sui partecipanti (es. congedi) che verranno riportate nei risultati delle votazioni"
                        matInput formControlName="notes"></textarea>
                </mat-form-field>
            </div>
        </div>



        <div class="text-center w-100 mt-4 mb-3">
            <h4 class="m-0">Notifiche</h4>
        </div>

        <div class="d-flex justify-content-center w-100 pb-3">
            <h5 style="max-width: 522px;" class="m-0">Imposta un giorno e un'ora in cui notificare i partecipanti della
                seduta attraverso l'invio di una PEC.</h5>
        </div>

        <div class="d-flex pb-5 justify-content-center w-100">
            <button (click)="addNewNotification()" class="pl-4 pr-4" mat-raised-button color="primary">+</button>
        </div>

        <div class="w-100 d-flex justify-content-center">
            <div *ngIf="notifications | async as _notifications" class="form-container w-100">
                <div class="mb-2" *ngFor="let _notification of _notifications">
                    <div class="col-12 offset-md-3 col-md-6">
                        <div class="d-flex">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Data e ora notifica</mat-label>
                                <input matInput type="text" [dropSpecialCharacters]="false" [clearIfNotMatch]="true"
                                    [showMaskTyped]="true" mask="00/00/0000 - 00:00" [formControl]="_notification.formControl">
                                <mat-error *ngIf="_notification.formControl?.invalid">
                                    {{getErrorMessage(_notification.formControl) }}</mat-error>
                            </mat-form-field>
                            <button [disabled]="_notification.notification.processed"
                                (click)="deleteNotification(_notification)" mat-icon-button>
                                <mat-icon [color]="_notification.notification.processed ? null : 'primary'"
                                    [matTooltip]="_notification.notification.processed ? 'La notifica e\' stata processata': 'Elimina notifica'">
                                    delete</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="notifications | async as _notifications" class="d-flex justify-content-center w-100">
            <button (click)="navigateBack()" style="min-width: 115px;" class="ml-1 mr-1" mat-raised-button
                color="primary">ANNULLA</button>
            <button [disabled]="generalInfosFormGroup.invalid || processingSubmit || invalidNotifications(_notifications)" (click)="submit()"
                style="min-width: 115px;" class="ml-1 mr-1" mat-raised-button color="primary">
                <div class="d-flex align-items-center justify-content-center">SALVA <mat-spinner
                        *ngIf="processingSubmit" class="ml-1" [diameter]="20"></mat-spinner>
                </div>
            </button>
        </div>

        <div *ngIf="!creating" class="text-center w-100 mt-5">
            <h3>Vuoi eliminare la seduta? <span class="clickable" (click)="deleteMeeting()">Elimina seduta</span></h3>
        </div>

    </form>

    <div class="mb-5">

    </div>
</span>

<ng-template #loading>
    <div class="d-flex w-100 p-5 align-content-center justify-content-center">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>