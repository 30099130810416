import { NgRedux } from '@angular-redux/store';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { ApplicativeErrorDialogComponent, AuthService, ConfirmationMessageDialogComponent, IAppState, Meeting, MeetingService, Speech, SpeechStatusType, Tenant, UserRoles } from 'oa-lib';
import { Observable, Subscription, timer } from 'rxjs';
import { HW_MIC_STATE, SynopticLayout, SynopticService, SynopticUpdate } from 'src/app/services/synoptic.service';

@Component({
  selector: 'synoptic',
  templateUrl: './synoptic.component.html',
  styleUrls: ['./synoptic.component.scss'],
})
export class SynopticComponent implements OnInit, OnDestroy,OnChanges {

  @Input() speeches: Speech[] = [];
  @Input() tenant: Tenant | null = null;
  @Input() meeting: Observable<Meeting | null> | undefined;

  @Input() meeting_oid: string | null = null;
  @Output() close_speech: EventEmitter<any> = new EventEmitter();
  @Output() enable_speech: EventEmitter<any> = new EventEmitter();

  @Output() forceRefreshSpeeches: EventEmitter<any> = new EventEmitter();
  synoptic_layout: SynopticLayout | null = null;

  synoptic_last_update: SynopticUpdate | null = null;

  synoptic_html_data: any[] = [];

  meetingObj: Meeting | null;
  need_update_synoptic=true;
  speechDuration: string;
  full_meeting: Meeting | null = null;
  timerIntervalSubscription: Subscription;
  list_cerano: any[] = [];
  constructor(
    private synopticService: SynopticService,
    private redux: NgRedux<IAppState>,
    private authService: AuthService,
    private dialog: MatDialog,
    private meetingService: MeetingService,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.speeches) {
      this.need_update_synoptic = true;
    }
  }
  ngOnInit(): void {

    this.init();

    this.timerIntervalSubscription?.unsubscribe();
    //start after 1 second to let the layout load
    this.timerIntervalSubscription = timer(1000, 1000).subscribe(() => {

      this.updateSynoptic();
     });
    // setTimeout(() => {
    //   this.updateSynoptic();
    // },1000);

  }

  async init() {
    const res = await this.synopticService.getSynopticLayout().toPromise();
    this.synoptic_layout = res.body[0] || null;
    console.log('this.synoptic_layout', this.synoptic_layout);
  }
  ngOnDestroy(): void {
    this.timerIntervalSubscription?.unsubscribe();
  }

  getSeatCoords(seat_number: number) {

    for (const seat of this.synoptic_layout?.seats_coord || []) {
      if (seat.s_num == seat_number) {
        return {x:seat.x,y:seat.y};
      }
    }
    return {x:0,y:150};
  }
  //good if synoptic buttons are controlled by hardware directly
  getIconBasedOnMicStatus(mic_status: HW_MIC_STATE) {

    switch (mic_status) {
      case HW_MIC_STATE.ON:
        return 'mic';
      case HW_MIC_STATE.OFF:
        return 'person';
      case HW_MIC_STATE.REQ:
        return 'mic';
      default:
        return 'person';

    }
  }

  //good if synoptic buttons are controlled by hardware directly
  getButtonClass(mic_status: HW_MIC_STATE) {
    switch (mic_status) {
      case HW_MIC_STATE.ON:
        return 'button-delegate syn-btn-on';
      case HW_MIC_STATE.OFF:
        return 'button-delegate syn-btn-off';
      case HW_MIC_STATE.REQ:
        return 'button-delegate syn-btn-req';
      default:
        return 'button-delegate syn-btn-off';
    }
  }

  //this function creates a "virtual mic" based on the speeches for icons
  //and return an object with {mic_state:mic_state,speech_oid:speech_oid}
  getVirtualMicStateAndSpeechId(username: string|null) {

    if (username == null) {
      return { mic_state: HW_MIC_STATE.UNKNOWN, speech_oid: null };
    }
    let mic_state = HW_MIC_STATE.OFF;
    let speech_oid: string | null = null;
    for (const speech of this.speeches) {
      if (speech.user == username) {
        if (this.isSpeechCompleted(speech)) {
          continue;
        }
        if (this.isSpeechRunning(speech)) {
          mic_state = HW_MIC_STATE.ON;
          speech_oid = speech._id?.$oid || null;
          break;
        }
        if(speech.status==SpeechStatusType.BOOKED){
          mic_state = HW_MIC_STATE.REQ;
          speech_oid = speech._id?.$oid || null;
          break;
        }

      }

    }
    return {  mic_state, speech_oid };
  }
  async cycle_mic(username: string, mic_state: HW_MIC_STATE, speech_oid: string | null) {

    console.log('cycle_mic ', username, mic_state, speech_oid);
    if (mic_state == HW_MIC_STATE.UNKNOWN) {
      this.showErrorMsg('Stato del microfono sconosciuto. Impossibile cambiare lo stato del microfono. Se avviene ripetutamente contattare l\'assistenza');
      return;
    }
    if (username == null || username.length < 2) {
      this.showErrorMsg('Impossibile cambiare lo stato del microfono. Utente non valido');
      return;
    }
    if (mic_state == HW_MIC_STATE.OFF) {
      console.log("mic state off, book a speech");
      const res = await this.synopticService.bookSpeechforUser(username).toPromise();
      console.log('bookSpeechforUser res', res);
      if (res.status < 220) {
        //this.showOkMsg('Richiesta di intervento inviata');
        this.disableButtonPerUser(username);
        this.forceRefreshSpeeches.emit();
        return;
       }
      else {
        this.showErrorMsg('Errore durante la richiesta di intervento');
        this.need_update_synoptic = true;
        return;
      }
      return;
    }
    if (mic_state == HW_MIC_STATE.REQ) {
      console.log("mic state req, enable a speech");
      if (!speech_oid) {
        this.showErrorMsg('Impossibile cambiare lo stato del microfono. Speech non valido');
        return;
      }
      this.enable_speech.emit(speech_oid);
      this.disableButtonPerUser(username);
      return;
    }
    if (mic_state == HW_MIC_STATE.ON) {
      console.log("mic state on, close a speech");
      if (!speech_oid) {
        this.showErrorMsg('Impossibile cambiare lo stato del microfono. Speech non valido');
        return;
      }
      this.close_speech.emit(speech_oid);
      this.disableButtonPerUser(username);
      return;
    }
    console.error('This should not happen at all. mic_state not handled', mic_state);
  }
  async updateSynoptic() {
    //console.log('meeting_oid', this.meeting_oid);
    try {
      await this.refrshMeeting();
      const res = await this.synopticService.getLastHwUpdateSynoptic().toPromise();

      if (!res.body) {
        this.synoptic_last_update = null;
        this.synoptic_html_data = [];
        return;
      }
      if (!this.need_update_synoptic && JSON.stringify(this.synoptic_last_update) == JSON.stringify(res.body)) {
        //console.log('updateSynoptic same data');
        return;
      }
      console.log('updateSynoptic new data, update everything');
      this.synoptic_last_update = res.body || null;
      //console.log('this.synoptic_last_update', this.synoptic_last_update);
      if (!this.synoptic_last_update) {
        this.synoptic_html_data = [];
        return;
      }
      const temp_html_data = [];
      if (this.synoptic_layout == null || this.synoptic_layout?.seats_coord == null) {
        console.error('this.synoptic_layout?.seats_coord == null');
        return;
      }
      for (const seat of this.synoptic_layout?.seats_coord) {

        //+const coords = this.getSeatCoords(dele.seat_number);
        const dele=this.find_delegate_by_seat_number(seat.s_num);
        const { mic_state, speech_oid } = this.getVirtualMicStateAndSpeechId(dele != null ? dele.oa_username : null);
        const full_name = dele == null ? '' : this.getNameFromUserId(dele.oa_username);
        //console.log('full_name', full_name);
        const first_letters = dele == null ?'':this.getFirstLetters(full_name);
        //console.log('first_letters', first_letters);
        const temp_obj = {
          x: seat.x,
          s_num: seat.s_num,
          y: seat.y,
          username: dele == null ? null : dele.oa_username,
          label: dele == null ? seat.s_num :first_letters,
          class: this.getButtonClass(mic_state),
          icon: this.getIconBasedOnMicStatus(mic_state),
          speech_oid: speech_oid,
          mic_state: mic_state,
          disabled_button: dele==null?true:false,

        };
        temp_html_data.push(temp_obj);
      }
      this.synoptic_html_data = temp_html_data;
      this.need_update_synoptic = false;

    } catch (err) {
      console.error('updateSynoptic error', err);
    }
  }

  find_delegate_by_seat_number(seat_number: number) {

    if (this.synoptic_last_update == null) {
      return null;
    }
    for (const dele of this.synoptic_last_update.delegates) {
      if (dele.seat_number == seat_number) {
        return dele;
      }
    }
    return null;

  }

  //this is necessary to let the UI update
  disableButtonPerUser(username: string) {
    for (const syn of this.synoptic_html_data) {
      if (syn.username == username) {
        syn.disabled_button = true;
        console.log('disabled_button for user', username);
      }
    }

  }

  async refrshMeeting() {

    if (this.meeting_oid == null || this.meeting_oid == undefined || this.meeting_oid == '') {
      console.error('meeting_oid is null, cant refresh');
      return;
    }
    this.full_meeting = await this.meetingService.retrieveMeeting(this.meeting_oid).toPromise();

    if (this.full_meeting == null) {
      console.error('full_meeting is null');
    }
  }




  getNameFromUserId(userId: string | undefined) {


    if (this.full_meeting && userId) {
          console.log('this.full_meeting.attendees', this.full_meeting.attendees);
          const name = this.full_meeting.attendees.filter(a => a._id === userId).map(a => a.name);
          return name?.length ? name[0] : '--';
        }
       return '--';
      }



  async enableSpeech(speech: any) {

  }
  stopSpeech(speech: any) {


  }

  getFirstLetters(tokenize: string) {
    let firstLetters = tokenize
      .split(' ')
      .map(word => word.charAt(0))
      .join('');
    firstLetters=firstLetters+"   ";
    return firstLetters;
  }

  getUsernameBySeatNumber(seat_number: number) {

    if (seat_number == null) {
        return null;
    }
    if (this.synoptic_last_update == null) {
        return null;
    }
    const delegate = this.synoptic_last_update.delegates.find(d => d.seat_number == seat_number);

    return delegate?.oa_username || null;

}
    isSpeechRunning(speech: Speech) {
      return (
        speech.status === SpeechStatusType.ENABLED && this.untilInFuture(speech)
      );
    }
    untilInFuture(speech: Speech) {
      return speech.until?.$date
        ? moment.now() < moment(speech.until?.$date).valueOf()
        : false;
    }
    isSpeechCompleted(speech: Speech) {
      return speech.status === SpeechStatusType.ENABLED && this.untilInPast(speech);
    }
    untilInPast(speech: Speech) {
      return speech.until?.$date ?
        moment.now() > moment(speech.until?.$date).valueOf()
        : false;
    }


    showOkMsg(msg: string) {
      this.dialog.open(ConfirmationMessageDialogComponent, {
        data: JSON.stringify({
          message: msg,
          redirectTo: null,
        })
      });
    }
    showErrorMsg(msg: string) {

      this.dialog.open(ApplicativeErrorDialogComponent, {
        data: JSON.stringify({
          message:
            msg,
          redirectTo: null,
        }),
      });

    }
}
