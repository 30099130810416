<mat-toolbar class="desktop-menu mb-0 menu" color="primary">
  <div class="container" *ngIf="rehydrated | async as _rehydrated">
    <div *ngIf="auth | async as _auth">
      <div *ngIf="tenant | async as _tenant">
        <div *ngIf="_auth.authenticated; else noAuthHeader" [ngClass]="{
          'justify-content-between': !this.isVerifying,
          'justify-content-end': this.isVerifying
        }" class="w-100 d-flex align-content-center toolbar-content">
          <div class="d-flex align-self-center text-center">
            <img src="/assets/img/logo-openassembly-white.png" width="150px" id="oa-logo" alt="oa-logo" />
            <img src="/assets/img/logo-openassembly-blue.png" width="150px" id="oa-logo-print" alt="oa-logo-print" />
          </div>
          <div class="w-100 d-flex align-items-end nav-items-container p-1">
            <div *ngIf="isSynopticManager" [class.current-navigation-item]="isSynopticRoute()" class="d-flex flex-column align-items-center nav-item" (click)="this.navigateToSynopticEvent.emit()">
              <mat-icon>chair</mat-icon>
              <span class="full-width label">Synoptic M.</span>
            </div>

            <div *ngIf="isCardManager" [class.current-navigation-item]="isCardRoute()" class="d-flex flex-column align-items-center nav-item" (click)="this.navigateToCardsEvent.emit()">
              <mat-icon>assignment_ind</mat-icon>
              <span class="full-width label">Dcerno Cards</span>
            </div>
            <div [class.current-navigation-item]="isCalendarRoute()" class="d-flex flex-column align-items-center nav-item" (click)="this.navigateToCalendarEvent.emit()">
              <mat-icon>event</mat-icon>
              <span class="full-width label">Calendario</span>
            </div>
            <div [class.current-navigation-item]="isCurrentMeetingRoute()" class="d-flex flex-column align-items-center nav-item" *ngIf="(isVoter||isGuest || isSecretary) && (_tenant.type === TenantType.FULL || _tenant.type === TenantType.DCERNO )"
              (click)="this.navigateToCurrentMeetingEvent.emit()">
              <mat-icon>refresh</mat-icon>
              <span class="full-width label">Seduta in corso</span>
            </div>
            <div [class.current-navigation-item]="isManagementRoute()" class="d-flex flex-column align-items-center nav-item" *ngIf="isManager && (_tenant.type === TenantType.FULL || _tenant.type === TenantType.DCERNO )"
              (click)="this.navigateToManagementEvent.emit()">
              <mat-icon>tune</mat-icon>
              <span class="full-width label">Gestisci seduta</span>
            </div>
            <div [class.current-navigation-item]="isProfileRoute()" class="d-flex flex-column align-items-center nav-item"
              (click)="this.navigateToUserProfileEvent.emit()">
              <mat-icon>portrait</mat-icon>
              <span class="full-width label">Profilo</span>
            </div>
            <div *ngIf="isSynopticManager" [class.current-navigation-item]="isViewverSynopticRoute()" class="d-flex flex-column align-items-center nav-item" (click)="this.navigateToViewerEvent.emit()">
              <mat-icon>tv</mat-icon>
              <span class="full-width label">Viewer</span>
            </div>
          </div>

          <div class="d-flex align-items-end nav-button-container">
            <button color="secondary" mat-flat-button (click)="this.logoutEvent.emit()">
              ESCI
            </button>
          </div>
        </div>
        <ng-template #noAuthHeader>
          <div class="w-100 d-flex align-content-center">
            <div class="p-1 mx-auto text-center">
              <img src="/assets/img/logo-openassembly-white.png" width="150px" alt="oa-logo" />
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</mat-toolbar>

<ng-content></ng-content>