<mat-card
  [ngClass]="{
    'active-meeting': isMeetingOpen(meeting)
  }"
  class="p-4 mb-5 meetingCard"
>
  <div class="d-flex justify-content-between">
    <h3 style="max-width: 80%;">
      <strong>{{ meeting.title }}</strong>
    </h3>
    <div class="labelContainer">
      <div *ngIf="isMeetingOpen(meeting)" class="active-badge">
        <span  class="meetingStatus">IN CORSO</span>
        <mat-icon class="iconStatus">{{ getMeetingIcon(meeting) }}</mat-icon>
      </div>
      <div *ngIf="isSuspended(meeting)" class="suspended-badge">
        <span  class="meetingStatus">SOSPESA</span>
        <mat-icon class="iconStatus">{{ getMeetingIcon(meeting) }}</mat-icon>
      </div>
      <div *ngIf="isClosed(meeting)" class="closed-badge">
        <span  class="meetingStatus">CONCLUSA</span>
        <mat-icon class="iconStatus">{{ getMeetingIcon(meeting) }}</mat-icon>
      </div>

      <div *ngIf="isScheduled(meeting)" class="scheduled-badge">
        <span  class="meetingStatus">PROGRAMMATA</span>
        <mat-icon class="iconStatus">{{ getMeetingIcon(meeting) }}</mat-icon>
      </div>

    </div>
    <ng-template #spinner>
      <mat-spinner [diameter]="30"></mat-spinner>
    </ng-template>
  </div>

  <div *ngIf="tenantType == 1">
    <mat-card-content>
      <div class="mb-2">
        Apertura votazioni:
        {{
          meeting?.startDate && meeting?.startDate?.$date
            ? (meeting?.startDate?.$date | date: "d/M/yy, H:mm")
            : "non specificato"
        }}
      </div>
      <div>
        Chiusura votazioni:
        {{
          meeting.secondCall
            ? meeting?.endDate && meeting?.endDate?.$date
              ? (meeting?.endDate?.$date | date: "d/M/yy,H:mm")
              : "non specificato"
            : meeting?.endDate && meeting?.endDate?.$date
            ? (meeting?.endDate?.$date | date: "d/M/yy,H:mm")
            : "non specificato"
        }}
      </div>
    </mat-card-content>
  </div>
  <div *ngIf="tenantType == 0">
    <mat-card-content>
      <div class="mb-2">
        Prima convocazione:
        {{
          meeting?.firstCall?.startDate && meeting?.firstCall?.startDate?.$date
            ? (meeting.firstCall.startDate?.$date | date: "d/M/yy, H:mm")
            : "non specificato"
        }}
      </div>
      <div *ngIf="meeting.secondCall != null">
        Seconda convocazione:
        {{
          meeting.secondCall
            ? meeting.secondCall?.startDate &&
              meeting.secondCall?.startDate?.$date
              ? (meeting.secondCall?.startDate?.$date | date: "d/M/yy,H:mm")
              : "non specificato"
            : meeting.firstCall?.startDate &&
              meeting.firstCall?.startDate?.$date
            ? (meeting.firstCall?.startDate?.$date | date: "d/M/yy,H:mm")
            : "non specificato"
        }}
      </div>
    </mat-card-content>
  </div>
  <mat-card-actions>
    <div class="d-flex justify-content-center pb-2">
      <button
        class="pl-4 pr-4 pt-1 pb-1"
        (click)="goToMeetingDetail(meeting)"
        mat-raised-button
        color="primary"
      >
        DETTAGLI
    </button>
         <button *ngIf="tenant_cocon && isClosed(meeting)"
      class="pl-4 pr-4 pt-1 pb-1"
      (click)="gotoBadgeEvents(meeting)"
      mat-raised-button
      color="primary"
    >
      Presenze Badges
    </button>
    </div>
  </mat-card-actions>
</mat-card>
