import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SynopticLayout, SynopticService } from 'src/app/services/synoptic.service';
import { ConfirmationMessageDialogComponent,ApplicativeErrorDialogComponent } from 'oa-lib';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-synoptic-manager',
  templateUrl: './synoptic-manager.component.html',
  styleUrls: ['./synoptic-manager.component.scss']
})
export class SynopticManagerComponent implements OnInit {

  form_layout: FormGroup;
  layout: SynopticLayout | undefined;

  constructor(private synopticService: SynopticService,
    private fb: FormBuilder,
    private dialog: MatDialog
  ) {

    this.form_layout = this.fb.group({
      seat_to_add: this.fb.control(null),
      seat_selected: this.fb.control(null)
    });
  }

  addSeat() {

    let seat_number = this.form_layout.get('seat_to_add')?.value;

    if (this.layout == null || seat_number ==null) {
      return;
    }
    if (typeof seat_number == 'string') {
      seat_number = parseInt(seat_number,10);
    }
    if (seat_number <= 0) { return; }

    const alreadyPresent = this.layout.seats_coord.find(s => s.s_num == seat_number);

    if (alreadyPresent != null) {
      this.showErrorMsg(`Seat ${seat_number} already present`);
      return;
    }
    this.layout.seats_coord.push({
      s_num: seat_number,
      x: 0,
      y: 0
    });

  }
  removeSeat() {
    let seat_number = this.form_layout.get('seat_to_add')?.value;

    if (this.layout == null || seat_number == null) {
      return;
    }
    if (typeof seat_number == 'string') {
      seat_number = parseInt(seat_number,10);
    }
    if (seat_number <= 0) { return; }

    const seat = this.layout.seats_coord.find(s => s.s_num == seat_number);
    if (seat == null) {
      this.showErrorMsg(`Seat ${seat_number} not present`);
      return;
    }
    const index = this.layout.seats_coord.indexOf(seat);
    this.layout.seats_coord.splice(index, 1);
    this.showOkMsg(`Seat ${seat_number} removed`);

  }

  showOkMsg(msg: string) {
    this.dialog.open(ConfirmationMessageDialogComponent, {
      data: JSON.stringify({
        message: msg,
        redirectTo: null,
      })
    });
  }
  showErrorMsg(msg: string) {

    this.dialog.open(ApplicativeErrorDialogComponent, {
      data: JSON.stringify({
        message:
          msg,
        redirectTo: null,
      }),
    });

  }

  ngOnInit(): void {
    this.init();
  }

  async init() {
    console.log('init');
    const res = await this.synopticService.getSynopticLayout().toPromise();
    this.layout = res.body[0];
    console.log(this.layout);
  }

  async save_layout() {

    console.log('save layout');
    if (this.layout == null) {
      console.error('layout null');
      return;
    }
    const res = await this.synopticService.updateSynopticLayout(this.layout).toPromise();
    console.log(res);
    this.showOkMsg('Layout updated');
  }
  change_coord_seat(axis: string, quantity: number) {

    console.log(axis, quantity);
    let selected_seat = this.form_layout.get('seat_selected')?.value;
    if (selected_seat == null) {
      console.error('seat not selected');
      return;
    }
    if (typeof selected_seat == 'string') {
      selected_seat = parseInt(selected_seat,10);
    }
    if (selected_seat <= 0) {
      console.error('invalid seat number selected after int cast');
      return;
    }

    const seat_in_layout = this.layout?.seats_coord.find(s => s.s_num == selected_seat);
    if (seat_in_layout == null) {
      console.error('seat not found in layout ?!');
      return;
    }
    if (axis == 'x') {
      seat_in_layout.x += quantity;
    }
    else if (axis == 'y') {
      seat_in_layout.y += quantity;
    }
    else {
      console.error('invalid axis');
      return;
    }
    const index_element = this.layout?.seats_coord.indexOf(seat_in_layout);
    if (index_element == null) {
      console.error('seat not found in layout ?!');
      return;
    }
    this.layout?.seats_coord.splice(index_element, 1, seat_in_layout);

    console.log(this.layout);
  }
}
