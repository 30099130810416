import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Mongo, TENANT_NAME_PLACEHOLDER } from 'oa-lib';
import * as moment from 'moment';

const URL = `${environment.RH_BASE_URL}/${TENANT_NAME_PLACEHOLDER}`;

@Injectable({
    providedIn: 'root',
})
export class PolGroupService {

    constructor(private httpClient: HttpClient) {

    }

    getPolGroupsOfTenant(): Observable<HttpResponse<any>> {

        const url = `${URL}/pol_groups?page=1&pagesize=1000`;
        return this.httpClient.get(url, {
            withCredentials: true,
            observe: 'response',
            responseType: 'json',
        });
    }
}
export class PolGroup {
    _id: string;
    name: string;
    hidden: boolean|undefined=false;
    constructor(_id: string, name: string,hidden?: boolean) {
        this._id = _id;
        this.name = name;
        this.hidden=hidden;
    }
}
