<div
  class="d-flex flex-column container justify-content-center mt-3 w-100"
  style="max-width: 1140px"
>
  <div *ngIf="showErrorInit" class="mt-4 meeting-info text-center w-100">
    <h3 class="m-0" style="font-weight: 500">
      <strong>Errore Resoconto eventi badge per delegato</strong>
    </h3>
  </div>

  <ng-container *ngIf="!showErrorInit">
    <div class="mt-4 meeting-info text-center w-100">
      <h3 class="m-0" style="font-weight: 500">
        <strong>Resoconto eventi badge per delegato</strong>
      </h3>
    </div>
    <div class="mt-4 mb-4 meeting-info text-center w-100">
      <h2 class="m-0" style="font-weight: 500">
        <strong>{{ name }}</strong>
      </h2>
    </div>
    <div class="row w-100">
      <div class="col-12 col-lg-6 mt-2">
        <h4>Durate effettiva della presenza:</h4>
      </div>
      <div class="row w-50">
        <div class="col-12 col-lg-6 mt-2">
          <p>{{ labelduration }}</p>
        </div>
      </div>
    </div>
    <div class="row w-100">
      <div class="col-12 col-lg-6 mt-2">
        <h4>Percentuale effettiva della presenza:</h4>
      </div>
      <div class="row w-50">
        <div class="col-12 col-lg-6 mt-2">
          <p>{{ labelpercentage }}</p>
        </div>
      </div>
    </div>

    <table class="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Evento</th>
          <th scope="col">Orario</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let part of tableData; index as i">
          <th scope="row">{{ i + 1 }}</th>
          <td>{{ part.eventlabel }}</td>
          <td>{{ part.datelabel }}</td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</div>
