import { ApplicativeErrorDialogComponent } from 'oa-lib';
import { Router } from '@angular/router';
import { MatSelectChange } from '@angular/material/select';
import { Meeting } from 'oa-lib';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

import BallotType = Meeting.Agenda.Ballot.TYPE;

@Component({
  selector: 'app-open-agenda-item-dialog',
  templateUrl: './open-agenda-item-dialog.component.html',
  styleUrls: ['./open-agenda-item-dialog.component.scss']
})
export class OpenAgendaItemDialogComponent implements OnInit {

  selectedItem: Meeting.Agenda.Item;
  secondCall: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public meeting: Meeting,
    public dialogRef: MatDialogRef<OpenAgendaItemDialogComponent>,
    public router: Router,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.secondCall = this.meeting.secondCall != null;
  }

  forbiddenAgendaItem(item: Meeting.Agenda.Item): boolean {
    if (!this.secondCall) {
      return item.status && item.status !== Meeting.Agenda.Item.STATUS.SCHEDULED;
    } else {
      return item.status && (
        item.status !== Meeting.Agenda.Item.STATUS.SCHEDULED &&
        item.status !== Meeting.Agenda.Item.STATUS.NO_QUORUM &&
        item.status !== Meeting.Agenda.Item.STATUS.NOT_VOTED
      );
    }
  }

  openMeetingDetail() {
    if (this.meeting._id) {
      const url = this.router.createUrlTree(['/meeting', this.meeting._id?.$oid]);
      window.open(url.toString(), '_blank');
    } else {
      this.dialogRef.close();
      this.dialog.open(ApplicativeErrorDialogComponent, {
        data: JSON.stringify({
          message: 'Non è stato possibile ottenere il riferimento al meeting',
          redirectTo: null
        }),
      });
    }
  }

  onAgendaItemSelection($event: MatSelectChange) {
    this.selectedItem = $event.value as Meeting.Agenda.Item;
  }

  handleSubmit() {
    this.dialogRef.close(this.selectedItem);
  }
}
