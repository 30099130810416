<ng-container *ngIf="synoptic_layout; else faillayout">
<div class="d-flex w-100 legends-container">

  <div class="syn-legend-off"><span></span>Microfono disattivo</div>
  <div class="syn-legend-req"><span></span>Richiesta intervento</div>
  <div class="syn-legend-on"><span></span>Microfono attivo</div>
  <p><strong>Click sinistro</strong> = successiva modalita'</p>
</div>
  <div class="synoptic-container">
    <img class="synoptic-bg-image" [src]="synoptic_layout?.bg_url">
    <div *ngFor="let s of synoptic_html_data" class="synoptic-delegate" [ngStyle]="{'margin-left.px':s.x,'margin-top.px':s.y}">
      <span class="syn-delegate-label"> {{s.label}}&nbsp; &nbsp; </span>
      <button [disabled]="s.disabled_button" [class]="s.class" (click)="cycle_mic(s.username,s.mic_state,s.speech_oid)" >
          <mat-icon>{{s.icon}}</mat-icon>
         </button>
      </div>
  </div>

</ng-container>
<ng-template #faillayout>
  <div class="d-flex w-100 p-5 align-content-center justify-content-center">
    <h2>Sinottica assente</h2>
  </div>
</ng-template>

<!--
<div *ngIf="background_img!==null" id="synoptic-container" >

    <img id="bg-image" [src]="background_img">
<div *ngFor="let s of tenant_synoptic" class="delegate" [ngStyle]="{'margin-left.px':s.x,'margin-top.px':s.y}">
<button *ngIf="s.seat_number>0" class="button-delegate" (click)="delegate_click(s.seat_number)" [ngClass]="{'delegate-speaking':s.is_speaking==true,'delegate-requesting':s.is_requesting==true}">
    <mat-icon>person</mat-icon>
   </button>
</div>

</div>
<ul *ngIf="tenant_synoptic" id="lista-in-postazione">
    <p>Delegati in postazione</p>
    <li *ngFor="let s of get_delegati_seduti()">
        {{s.username + ' - ' + s.seat_number}}
    </li>
</ul>
<ul *ngIf="tenant_synoptic" id="lista-no-postazione">
    <p>Delegati non in postazione</p>
    <li *ngFor="let s of list_cerano">
        {{s.username }}
    </li>
</ul>
<div id="speeches-televic-container">
<table class="table">
    <thead>
        <tr>
            <th scope="col">Delegato</th>

        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let s of speeches_televic">
            <td>{{getNameFromUserId(s.username)}}</td>

        </tr>
    </tbody>
    </table> -->
    <!-- <div class="mt-5 meeting-info w-100 text-center">
        <h3 style="font-weight: 500">
          <strong>Gestione richieste di intervento in Aula</strong>
        </h3>
      </div> -->
      <!-- <div class="row">
        <div class="col-12 mx-auto">
          <div class="d-flex flex-column flex-md-row justify-content-center align-items-center">

            <label class="pl-3 pl-md-0" style="min-width: 125px !important">Tempo di parola:</label>

            <mat-radio-group (change)="speechDurationRadioChange($event)" aria-label="Votazione segreta">
              <mat-radio-button class="ml-3" [value]="SpeechDurationType.UNLIMITED">Illimitato</mat-radio-button>
              <mat-radio-button checked class="ml-3" [value]="SpeechDurationType.LIMITED">Countdown</mat-radio-button>
            </mat-radio-group>

            <mat-form-field class="ml-3" appearance="outline">
              <mat-label>Durata interventi</mat-label>
              <input matInput [disabled]="speechDurationType!==SpeechDurationType.LIMITED" type="text" name="duration"
                [dropSpecialCharacters]="false" [clearIfNotMatch]="true" [showMaskTyped]="true" suffix=" minuti"
                mask="00:00" value="05:00" [(ngModel)]="speechDuration" />
            </mat-form-field>

          </div>
        </div>
      </div> -->
    <!-- <div *ngFor="let speech of speeches_televic" class="speeches-televic">
        <div class="d-inline-flex justify-content-between w-100 mt-md-2 flex-row flex-md-row" [class.flex-column]="isManager">
            <div class="d-flex align-items-center" [class.mx-auto]="isManager" [class.mx-md-0]="isManager">
              <span *ngIf="isManager">{{getTimeFromDate(speech.timestamp?.$date)}}</span>
              <mat-icon class="ml-2 mr-2" [color]="isSpeechRunning(speech)? 'accent' : 'primary'">
                {{getSpeechIcon(speech)}}
              </mat-icon>
              {{getNameFromUserId(speech.username)}}
            </div>
            <div class="speech-buttons-container d-inline-flex mx-md-0 mx-md-none mt-2 mt-md-0"
              [ngClass]="{'speech-manager-buttons mx-auto': isManager}">
              <div class="text-md-center d-flex flex-column d-lg-block"> -->
                <!-- <button *ngIf="isSpeechRunning(speech)" mat-stroked-button color="accent"
                  class="flat-rounded-button timer  mr-0 mb-2 mb-lg-0 mr-lg-2"
                  [ngClass]="{'mr-2': isManager, 'spinner-btn': false}" style="pointer-events: none!important;">
                  {{ currentTime }}
                </button> -->
<!--
                <button *ngIf="isManager" (click)="enableSpeech(speech)" mat-raised-button color="primary"
                  [disabled]="isSpeechRunning(speech) || isSpeechCompleted(speech)" class="flat-rounded-button mr-2">
                  ABILITA
                </button>
              </div>

              <div *ngIf="isManager" class="text-md-center d-flex flex-column d-lg-block">
                <button [disabled]="!isSpeechRunning(speech)" (click)="stopSpeech(speech)" mat-raised-button color="primary"
                  class="flat-rounded-button mr-0 mb-2 mb-lg-0 mr-lg-2">
                  TERMINA
                </button> -->

                <!-- <button *ngIf="isManager" (click)="deleteSpeech()" [disabled]="isSpeechRunning()" disabled mat-raised-button
                  color="primary" class="flat-rounded-button m-0">
                  CANCELLA
                </button>
              </div> -->

              <!-- <div *ngIf="isSpeechDeletable()" (click)="deleteSpeech()" class="text-md-center">
                <button mat-raised-button color="primary" class="flat-rounded-button mr-0 mb-2 mb-lg-0 mr-lg-2">
                  ANNULLA PRENOTAZIONE
                </button>
              </div> -->
            <!-- </div>
          </div>
        </div>

</div> -->