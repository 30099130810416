import { environment } from '../../environments/environment';
import { Mongo, TENANT_NAME_PLACEHOLDER } from 'oa-lib';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

const URL = `${environment.RH_BASE_URL}`;

@Injectable({
    providedIn: 'root',
})
export class BadgesService {

    constructor(private httpClient: HttpClient) {

    }
    getAllBadgeEventsForMeetingId(meetingId: any): Observable<HttpResponse<any>> {

        // const url = `${URL}/pol_groups?page=1&pagesize=1000`;
        // const url = `${URL}/synoptic?filter={"_id":{"$eq":"layout"}}`;
        //const url = `${URL}/pol_groups?page=1&pagesize=1000&filter={"meeting_id":{"$eq":"${meetingId}"}}`;
        const url=`${URL}/txns/coconbadge/${meetingId}`;
        console.log('getAllBadgeEventsForMeetingId url: ', url);
        return this.httpClient.get(url, {
            withCredentials: true,
            observe: 'response',
            responseType: 'json',
        });

    }
}
