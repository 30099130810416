/* eslint-disable @typescript-eslint/naming-convention */
import { TenantActions, TenantService, VoterNotifierService } from 'oa-lib';
import { Component, OnInit } from '@angular/core';
import { persistStore, persistReducer } from 'redux-persist';
import { NgRedux } from '@angular-redux/store';
import { createStore, applyMiddleware, compose } from 'redux';
import { rootReducer, IAppState } from 'oa-lib';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel1 from 'redux-persist/es/stateReconciler/autoMergeLevel1';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: typeof compose;
  }
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'oa-web-app';

  constructor(
    private ngRedux: NgRedux<IAppState>,
    private tenantSvc: TenantService,
    private tenantActions: TenantActions,
    private voterNotifierSvc: VoterNotifierService
  ) {
    const persistConfig = {
      key: 'root',
      storage,
      stateReconciler: autoMergeLevel1
    };

    const persistedReducer = persistReducer<IAppState>(persistConfig, rootReducer);

    let store;

    if (window.__REDUX_DEVTOOLS_EXTENSION__) {
      store = createStore(persistedReducer, compose(
        // https://github.com/zalmoxisus/redux-devtools-extension
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
        applyMiddleware()
      ));
    } else {
      store = createStore(persistedReducer, compose(
        applyMiddleware()
      ));
    }
    persistStore(store).subscribe(() => this.voterNotifierSvc.initService());

    // WORKAROUD TO CATCH 'Store already configured!' redux error

    try {
      ngRedux.provideStore(store);
    } catch (e) {
      console.log(e);
    }

  }
  ngOnInit(): void { }
}
