<div *ngIf="itemFormGroup && durationFormControl" style="max-width: 600px;" matDialogContent>

    <div class="w-100 text-center justify-content-center">
        <h3><strong>Creazione punto all’OdG</strong></h3>
    </div>

    <div>
        <form class="w-100" [formGroup]="itemFormGroup">
            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Titolo punto</mat-label>
                        <input matInput formControlName="title" required>
                    </mat-form-field>
                </div>

                <div class="col-12">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Descrizione</mat-label>
                        <textarea matInput placeholder="Descrizione dettagliata del punto"
                            formControlName="description"></textarea>
                    </mat-form-field>
                </div>

                <!-- <div class="col-6">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Categoria punto</mat-label>
                        <mat-select value="default" defaultTabIndex=1>
                            <mat-option selected value="default">
                                dei votanti
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->
                <div class="col-6">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Radice punto</mat-label>
                        <mat-select formControlName="parent" defaultTabIndex=1>
                            <mat-option [value]="null">
                                Nessuna
                            </mat-option>
                            <mat-option *ngFor="let item of itemRootOptions" [value]="item">
                                {{item}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="hasBallotControl" class="col-6 pt-3 mb-2">
                    <label style="min-width: 125px!important;">Punto da votare:</label>
                    <mat-radio-group (change)="hasBallotChanged($event)" [formControl]="hasBallotControl"
                        aria-label="Punto da votare">
                        <mat-radio-button class="ml-3" [value]="true">Si</mat-radio-button>
                        <mat-radio-button class="ml-3" [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="row">
                <div *ngIf="hasBallotControl" class="col-6">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Durata della votazione (hh: mm: ss)</mat-label>
                        <input [disabled]="hasBallotControl.value" matInput type="text" [dropSpecialCharacters]="false"
                            [clearIfNotMatch]="true" [showMaskTyped]="true" mask="Hh:m0:s0" [leadZeroDateTime]="true"
                            [formControl]="durationFormControl">
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div *ngIf="hasBallotControl" class="col-6">
                    <mat-form-field appearance="outline" class="w-100 p-0">
                        <mat-label>Tipologia votazione</mat-label>
                        <mat-select [value]="ballot ? ballot.type : null" [disabled]="!hasBallotControl.value"
                            (selectionChange)="ballotTypeChanged($event)" value="default" defaultTabIndex=1>
                            <mat-option selected [value]="null">
                                Nessuna
                            </mat-option>
                            <mat-option selected [value]="BallotType.OPEN_VOTING_PROCEDURE">
                                Votazione palese
                            </mat-option>
                            <mat-option selected [value]="BallotType.SECRET_VOTING_PROCEDURE">
                                Votazione segreta
                            </mat-option>
                            <mat-option selected [value]="BallotType.ELECTION_BY_LIST">
                                Elezione da lista
                            </mat-option>
                            <mat-option selected [value]="BallotType.ELECTION_BY_COMPILATION">
                                Elezione per compilazione
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div style="padding-bottom: 1.34375em;" class="col-6 my-auto">
                    <button *ngIf="ballot && ballot.type === BallotType.ELECTION_BY_LIST" color="primary"
                        (click)="defineElectionByListChoices()" mat-raised-button>DEFINISCI CANDIDATI</button>
                </div>

                <div style="padding-bottom: 1.34375em;" class="col-6 my-auto">

                    <form [formGroup]="majorityFromGroup">
                        <mat-form-field
                            *ngIf="ballot && (ballot.type === BallotType.OPEN_VOTING_PROCEDURE || ballot.type === BallotType.SECRET_VOTING_PROCEDURE)"
                            appearance="outline" class="w-100">
                            <mat-label>Maggioranza</mat-label>
                            <mat-select [value]="ballot ? ballot.specs[0].majority?.fraction : null"
                                formControlName="fraction" defaultTabIndex=1>
                                <mat-option selected [value]="Majority.FRACTION.HALF">
                                    {{Majority.FRACTION.HALF}}
                                </mat-option>
                                <mat-option selected [value]="Majority.FRACTION.TWO_THIRDS">
                                    {{Majority.FRACTION.TWO_THIRDS}}
                                </mat-option>
                                <mat-option selected [value]="Majority.FRACTION.QUALIFIED_MAJORITY">
                                    {{Majority.FRACTION.QUALIFIED_MAJORITY}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="majorityFromGroup.get('fraction')?.errors?.required">Il campo è
                                obbligatorio</mat-error>
                        </mat-form-field>
                    </form>

                    <form [formGroup]="choiceRangeFromGroup">
                        <mat-form-field *ngIf="ballot && ballot.type === BallotType.ELECTION_BY_LIST"
                            appearance="outline" class="w-100">
                            <mat-label>Numero minimo di candidati votabili</mat-label>
                            <input type="number" formControlName="min" matInput>
                            <mat-error *ngIf="choiceRangeFromGroup.errors?.minRangeExcedeed">Non può eccedere il numero
                                massimo</mat-error>
                            <mat-error *ngIf="choiceRangeFromGroup.errors?.minNegative">Non può essere negativo
                            </mat-error>
                        </mat-form-field>
                    </form>

                </div>

                <div style="padding-bottom: 1.34375em;" class="col-6 my-auto">

                    <form [formGroup]="majorityFromGroup">
                        <mat-form-field
                            *ngIf="ballot && (ballot.type === BallotType.OPEN_VOTING_PROCEDURE || ballot.type === BallotType.SECRET_VOTING_PROCEDURE)"
                            appearance="outline" class="w-100">
                            <mat-label>Calcolo maggioranza su</mat-label>
                            <mat-select formControlName="of" [value]="ballot ? ballot.specs[0].majority?.of : null"
                                defaultTabIndex=1>
                                <mat-option selected [value]="Majority.OF.ATTENDEES">
                                    Aventi diritto al voto
                                </mat-option>
                                <mat-option selected [value]="Majority.OF.VOTERS">
                                    Votanti
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>

                    <form [formGroup]="choiceRangeFromGroup">
                        <mat-form-field *ngIf="ballot && ballot.type === BallotType.ELECTION_BY_LIST"
                            appearance="outline" class="w-100">
                            <mat-label>Numero massimo di candidati votabili</mat-label>
                            <input type="number" formControlName="max" matInput>
                            <mat-error
                                *ngIf="choiceRangeFromGroup.errors && choiceRangeFromGroup.errors.maxRangeExcedeed && !choiceRangeFromGroup.errors.maxNegative">
                                Non può eccedere il numero
                                di candidati complessivi</mat-error>
                            <mat-error *ngIf="choiceRangeFromGroup.errors && choiceRangeFromGroup.errors.maxNull">Deve
                                essere almeno 1</mat-error>
                            <mat-error *ngIf="choiceRangeFromGroup.errors && choiceRangeFromGroup.errors.maxNegative">
                                Non può essere negativo
                            </mat-error>
                        </mat-form-field>
                    </form>


                </div>


            </div>
        </form>
        <div *ngIf="is_political_group_enabled_for_tenant" class="row">
            <h4 class="w-100 text-center">Contingentamento interventi</h4>
            <form [formGroup]="speechLimitFG">
                <ng-container formArrayName="speechLimitArray">
                    <ng-container *ngFor="let it of speechlimits_array.controls; let i = index">
                        <ng-container [formGroupName]="i">
                        <div class="speech-limit d-flex">
                            <input type="hidden" formControlName="pgId">
                            <div class="label-group">{{it.get('pgName')?.value}}</div>
                            <mat-form-field appearance="outline" class="">
                                <mat-label>Contingentamento (hh: mm: ss)</mat-label>
                                <input matInput type="text" [dropSpecialCharacters]="false"
                                    [clearIfNotMatch]="true" [showMaskTyped]="true" mask="Hh:m0:s0" [leadZeroDateTime]="true"
                                    formControlName="limit">
                            </mat-form-field>
                        </div>
                    </ng-container>
                    </ng-container>
                </ng-container>
            </form>
        </div>
    </div>

    <div class="d-flex w-100 mt-2 justify-content-center">
        <button type="button" mat-button color="warn" (click)="close()">Annulla</button>
        <button type="button" [disabled]="this.itemFormGroup.invalid ||
         this.durationFormControl.invalid ||
         this.majorityFromGroup.invalid ||
         this.choiceRangeFromGroup.invalid" mat-button color="warn" (click)="confirm()">{{data.creating ? 'Aggiungi' :
            'Aggiorna'}}</button>
    </div>
</div>