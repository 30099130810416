<!-- Dynamic space gap -->
<div id="snackbar-offset"></div>
<div class="free-space"></div>
<!-- Footer -->
<footer
  style="border-top: 1px solid #d3d3d3; background: white"
  class="d-flex w-100 pt-2 pb-2 pr-3 pl-3"
>
  <div class="p-1 text-md-left">
    <span class="m-0 footer-section-title">Assistenza</span>

    <u class="footer-links">
      <li>
        <span>Numero verde: <a href="tel:3291240166">+39 3291240166</a></span>
      </li>
      <li>
        Email:
        <a href="mailto:assistenza@openassembly.eu">assistenza@openassembly.eu</a>
      </li>
    </u>
  </div>
  <div class="d-flex p-1 mt-2 mt-md-0 ml-auto text-center">
    <span class="m-0 footer-section-title">OpenAssembly v2.1</span>
  </div>
  <div
    class="d-flex p-1 mt-2 mt-md-0 ml-auto text-center footer-logo"
    style="place-items: center"
  >
    <img
      src="/assets/img/logo-openassembly-blue.png"
      width="150px"
      height="35.86px"
      alt="oa-logo"
    />
  </div>
</footer>
