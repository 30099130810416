import { MeetingService, TenantState, TenantType } from 'oa-lib';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState } from 'oa-lib';
import { MatSelectChange } from '@angular/material/select';
import { map } from 'rxjs/operators';
import { Meeting } from 'oa-lib';
import { Observable } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

import BallotType = Meeting.Agenda.Ballot.TYPE;

@Component({
  selector: 'app-open-meeting-dialog',
  templateUrl: './open-meeting-dialog.component.html',
  styleUrls: ['./open-meeting-dialog.component.scss']
})
export class OpenMeetingDialogComponent implements OnInit {

  @select('tenant') tenant: Observable<TenantState>;

  scheduledMeetings: Observable<Meeting[]>;
  selectedMeeting: Meeting;

  constructor(
    public dialogRef: MatDialogRef<OpenMeetingDialogComponent>,
    private meetingSvc: MeetingService,
    private redux: NgRedux<IAppState>,
  ) { }

  ngOnInit(): void {
    const tenantId = this.redux.getState().tenant._id;
    this.scheduledMeetings = this.meetingSvc.getScheduledMeetings().pipe(
      map(res => {
        if (res.body) {
          return res.body;
        } else {
          return [];
        }
      })
    );
  }

  forbiddenMeeting(tenant: TenantState, meeting: Meeting): boolean {
    const tenantType = tenant.type;

    if (tenantType || tenantType === 0) {
      switch (tenantType) {
        // TenantType.FULL = 0 can't be used for comparisons!
        case 0: {
          return this.hasElectionBallot(meeting);
        }
        case TenantType.DCERNO: {
          return this.hasElectionBallot(meeting);

         }
        case TenantType.SMALL: {
          return !this.hasOnlyElectionBallots(meeting);
        }
      }
      return true;
    } else {
      return true;
    }

  }

  hasElectionBallot(meeting: Meeting): boolean {
    let hasElectionBallot = false;

    for (const agendaItem of meeting.agenda) {
      if (agendaItem.ballot?.type === BallotType.ELECTION) {
        hasElectionBallot = true;
        break;
      }
    }

    return hasElectionBallot;
  }

  hasOnlyElectionBallots(meeting: Meeting): boolean {
    let hasOnlyElections = true;

    for (const agendaItem of meeting.agenda) {
      if (agendaItem.ballot?.type !== BallotType.ELECTION) {
        hasOnlyElections = false;
        break;
      }
    }

    return hasOnlyElections;
  }

  onMeetingSelection($event: MatSelectChange) {
    this.selectedMeeting = $event.value as Meeting;
  }

  handleSubmit() {
    this.dialogRef.close(this.selectedMeeting);
  }
}
